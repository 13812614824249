export const getCompeltePreRegistrationStartups = (store) =>
  store.startupApproval.completePreRegistrationStartups;
export const getPendingRegistrationStartups = (store) =>
  store.startupApproval.pendingRegistrationStartups;
export const getCompleteRegistrationStartups = (store) =>
  store.startupApproval.completeRegistrationStartups;
export const getApprovedStartups = (store) =>
  store.startupApproval.approvedStartups;
export const getSignedContractStartups = (store) =>
  store.startupApproval.signedContractStartups;
export const getRemovedStartups = (store) =>
  store.startupApproval.removedStartups;
export const getLoading = (store) => store.startupApproval.loading;

const startupApprovalSelectors = {
  getCompeltePreRegistrationStartups,
  getPendingRegistrationStartups,
  getCompleteRegistrationStartups,
  getApprovedStartups,
  getSignedContractStartups,
  getRemovedStartups,
  getLoading,
};

export default startupApprovalSelectors;
