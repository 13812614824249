import styled from 'styled-components';

import { Box, Stack } from '@mui/material';

const SubHeaderBreadchrumbs = styled.div`
  padding: 24px 42px;
  background: #fafafa;
  border-left: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
`;

const TitlePage = styled.h1`
  color: #1a1a24;
  font-size: 20px;
  font-weight: 700;
`;

const Container = styled(Stack)`
  display: flex;
  flex-direction: column;
  padding: 32px 40px;
`;

const BoxContent = styled(Box)`
  background: #ffffff;
  box-shadow: 0px 1px 3px 0px #0000001f;
  border-radius: 8px;
`;

export { SubHeaderBreadchrumbs, TitlePage, Container, BoxContent };
