/* eslint-disable react/prop-types */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import FormatMoneyBRL from '@src/helpers/FormatMoneyBRL';

export default function Table({ balance }) {
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        flexDirection: 'row',
        padding: '0 23px 0 23px',
      }}
    >
      <Box>
        <Box
          style={{
            marginBottom: 30,
          }}
        >
          <Typography sx={{ fontSize: 14, fontWeight: 800, color: '#515162' }}>
            Caixa
          </Typography>
          <Typography sx={{ fontSize: 14, fontWeight: 400, color: '#515162' }}>
            {`R$ ${FormatMoneyBRL(balance?.cash)}`}
          </Typography>
        </Box>
        <Box
          style={{
            marginBottom: 30,
          }}
        >
          <Typography sx={{ fontSize: 14, fontWeight: 800, color: '#515162' }}>
            Cotas a receber
          </Typography>
          <Typography sx={{ fontSize: 14, fontWeight: 400, color: '#515162' }}>
            {`R$ ${FormatMoneyBRL(balance?.bills_receive)}`}
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ fontSize: 14, fontWeight: 800, color: '#515162' }}>
            Captal Social
          </Typography>
          <Typography sx={{ fontSize: 14, fontWeight: 400, color: '#515162' }}>
            {`R$ ${FormatMoneyBRL(balance?.share_capital)}`}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Box
          style={{
            marginBottom: 30,
          }}
        >
          <Typography sx={{ fontSize: 14, fontWeight: 800, color: '#515162' }}>
            Ativos Imobilizados
          </Typography>
          <Typography sx={{ fontSize: 14, fontWeight: 400, color: '#515162' }}>
            {`R$ ${FormatMoneyBRL(balance?.fixed_assets)}`}
          </Typography>
        </Box>
        <Box
          style={{
            marginBottom: 30,
          }}
        >
          <Typography sx={{ fontSize: 14, fontWeight: 800, color: '#515162' }}>
            Contas a pagar
          </Typography>
          <Typography sx={{ fontSize: 14, fontWeight: 400, color: '#515162' }}>
            {`R$ ${FormatMoneyBRL(balance?.bills_pay)}`}
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ fontSize: 14, fontWeight: 800, color: '#515162' }}>
            Lucros Acumulados
          </Typography>
          <Typography sx={{ fontSize: 14, fontWeight: 400, color: '#515162' }}>
            {`R$ ${FormatMoneyBRL(balance?.accumulated_profit)}`}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Box
          style={{
            marginBottom: 30,
          }}
        >
          <Typography sx={{ fontSize: 14, fontWeight: 800, color: '#515162' }}>
            Financiamentos
          </Typography>
          <Typography
            sx={{ fontSize: '14px', fontWeight: 400, color: '#515162' }}
          >
            {`R$ ${FormatMoneyBRL(balance?.financing)}`}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
