export const getCompeltePreRegistration = (store) =>
  store.investorApproval.completePreRegistration;
export const getApproved = (store) => store.investorApproval.approved;
export const getRemoved = (store) => store.investorApproval.removed;
export const getLoading = (store) => store.investorApproval.loading;

const investorApprovalSelectors = {
  getCompeltePreRegistration,
  getApproved,
  getRemoved,
  getLoading,
};

export default investorApprovalSelectors;
