export function numberFormat(x) {
  return x?.toLocaleString('pt-br', { minimumFractionDigits: 2 });
}

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export function unMask(text) {
  const value = text.match(/\d/g)?.join('');
  return value || '';
}
export function formatReal(e) {
  if (e && e !== undefined) {
    return parseFloat(e)
      .toFixed(2)
      .replace('.', ',')
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  }
  return e;
}

export function formatCnpj(e) {
  if (e && e !== undefined) {
    return e
      .toString()
      .replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  }
  return e;
}
