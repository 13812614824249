import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Wrapper = styled.div`
  align-items: center;
  border-style: dashed;
  border-color: ${({ borderColor }) => borderColor};
  border-radius: 10px;
  border-width: 1px;
  justify-content: center;
  margin: 0;
  min-height: ${({ minHeight }) => minHeight || '100px'};
  min-width: ${({ minWidth }) => minWidth || '180px'};
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

export const DropzoneContainer = styled.div`
  cursor: pointer;
  min-height: ${({ minHeight }) => minHeight || '100px'};
  min-width: ${({ minWidth }) => minWidth || '180px'};
  padding: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const FileContainer = styled.div`
  padding: 8px 0px;
`;
