import HttpClient from './httpClient';

class Api extends HttpClient {
  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super();
  }

  updateStartupCompletRegistration = (data) =>
    this.instance.put('startups/complete-registration', data);

  signUpShareHolder = (data) =>
    this.instance.post('startups/shareholder', data);

  signUpBalance = (data) => this.instance.post('startups/balance', data);

  signUpDRE = (data) => this.instance.post('startups/dre', data);

  updateDRE = (data) => this.instance.put('startups/dre', data);

  fetchUpDRE = (startup_id) =>
    this.instance.get('startups/dre', {
      params: {
        startup_id,
      },
    });

  fetchStartup = (id) =>
    this.instance.get('startups', {
      params: {
        id,
      },
    });

  fetchStartupGeneralData = (id) =>
    this.instance.get('startups/startup-general-data', {
      params: {
        id,
      },
    });

  signUpUser = (data) => this.instance.post('users', data);

  fetchUser = (id) =>
    this.instance.get('users', {
      params: {
        id,
      },
    });

  verifyEmailConfirmation = (cod, email) =>
    this.instance.get('users/email-confirmation', {
      params: {
        cod,
        email,
      },
    });

  fetchStartupCapitedProposalValues = ({
    startup_id,
    initial_date,
    final_date,
  }) =>
    this.instance.get('startups/proposals/capited-values/total', {
      params: {
        startup_id,
        initial_date,
        final_date,
      },
    });

  fetchStartupViewsTotal = ({ startup_id, initial_date, final_date }) =>
    this.instance.get('startups/views/total', {
      params: {
        startup_id,
        initial_date,
        final_date,
      },
    });

  fetchProposalsQuantity = ({ startup_id, initial_date, final_date }) =>
    this.instance.get('startups/proposals/quantity-by-status', {
      params: {
        startup_id,
        initial_date,
        final_date,
      },
    });

  fetchStartupInfos = (params) =>
    this.instance.get('proposals/startup-infos', {
      params,
    });

  fetchProposals = (params) =>
    this.instance.get('proposals/startup-proposals', {
      params,
    });

  fetchProposalsInvestor = (params) =>
    this.instance.get('proposals/transactions', {
      params,
    });

  approveOrRejectProposal = (body) =>
    this.instance.put('proposals/approve-proposal', body);

  completeProposal = (body) =>
    this.instance.put('proposals/complete-proposal', body);

  fetchProposalsStartupDetails = (params) =>
    this.instance.get('proposals/startup-details', {
      params,
    });

  fetchLastViews = ({ startup_id }) =>
    this.instance.get('startups/last-views', {
      params: {
        id: startup_id,
      },
    });

  signUpStartup = (data) => this.instance.post('startups', data);

  updateStartup = (data) => this.instance.put('startups', data);

  signUpStartupCompany = (data) =>
    this.instance.put('startups/company-data', data);

  signUpInvestorCompany = (data) => this.instance.post('investors', data);

  updateInvestorCompany = (data) => this.instance.put('investors', data);

  signUpPartner = (data) => this.instance.post('startups/partner', data);

  signUpInvestorPartner = (data) =>
    this.instance.post('investors/partner', data);

  contactForm = (data) => this.instance.post('users/send-contact-form', data);

  verifyUserStatus = (token) =>
    this.instance.get('user/status', {
      params: {
        token,
      },
    });

  upload = ({ data, userType, id }) =>
    this.instance.post('uploads', data, {
      params: {
        userType,
        id,
      },
    });

  signIn = ({ email, password, user_type_id }) =>
    this.instance.post('users/sessions', {
      email,
      password,
      user_type_id,
    });

  // Consultor
  getStartups = () => this.instance.get('startups');

  deleteRemoveStartups = (id) =>
    this.instance.post('startups/remove-pre-registration', {
      id,
    });

  getInvestors = () => this.instance.get('investors');

  getInvestorLastViews = ({ userId }) =>
    this.instance.get('investors/last-views', {
      params: {
        id: userId,
        rows: 4,
      },
    });

  getStartupDetail = (id) =>
    this.instance.get('startups/pre-registration', {
      params: {
        id,
      },
    });

  getStartupDetailComplete = ({ startupId }) =>
    this.instance.get(`startups/startup-general-data?id=${startupId}`);

  getInvestorDetail = (id) =>
    this.instance.get('investors/pre-registration', {
      params: {
        id,
      },
    });

  postInvestorSaveHistoryStartupLastSeen = ({ investorId, startupId }) =>
    this.instance.post(
      `startups/views?investor_id=${investorId}&startup_id=${startupId}`
    );

  postStartupProposalGenerateUrlNameFile = ({ startupId, dataFile }) =>
    this.instance.post(`uploads/?id=${startupId}&userType=startup`, dataFile, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

  postStartupDetailsInvestorRegisterProposal = ({
    investorId,
    startupId,
    quotas,
    fileName,
  }) =>
    this.instance.post('proposals', {
      investor_id: investorId,
      startup_id: startupId,
      quotas,
      file: fileName,
    });

  putConsultorApprovalStartup = ({
    id,
    user_consultant_id,
    status_id,
    email,
  }) =>
    this.instance.put('consultants/startup-approval', {
      id,
      user_consultant_id,
      status_id,
      email,
    });

  putConsultorApprovalInvestor = ({ id, user_consultant_id, status_id }) =>
    this.instance.put('consultants/investor-approval', {
      id,
      user_consultant_id,
      status_id,
    });

  getTransactionsProposals = ({ investorId }) =>
    this.instance.get(
      `https://beyondlabs-startupshowcase.azurewebsites.net/api/proposals/transactions?investor_id=${investorId}`
    );
}

const ApiClient = new Api();

export default ApiClient;
