/* eslint-disable react/prop-types */
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Divider, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DropFile from '@src/components/DropFile';

import Select from '@src/components/common/Select/select-quotas';
import FormatMoneyBRL from '@src/helpers/FormatMoneyBRL';
import toast from 'react-hot-toast';

import StartupProposalActions from './actions';

export default function BasicModal({ modalOpen, handleClose }) {
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [valueSelect, setValueSelect] = React.useState('');
  const [total, setTotal] = React.useState(0);

  React.useEffect(() => {
    setOpen(modalOpen);
  }, [modalOpen]);

  const { startupDetails, startupId } = useSelector(
    (stateSelector) => stateSelector.startupDetails
  );

  const { user } = useSelector((stateSelector) => stateSelector.auth);

  const onRemoveFile = (index) => {
    setFiles(files.filter((_, key) => key !== index));
  };

  const handleChange = (event) => {
    setValueSelect(event.target.value);
    setTotal(event.target.value * startupDetails.quota_value);
  };

  const handleSendProposal = () => {
    if (valueSelect > 0 && files.length > 0) {
      if (files.length === 1) {
        dispatch({
          type: StartupProposalActions.asyncRegisterProposal,
          investorId: user.investor_id,
          startupId,
          quotas: valueSelect,
          file: files[0],
        });
      } else {
        toast.error('Só é permitido anexar um Termsheet!');
      }
    } else {
      toast.error('Selecione o número de cotas e anexe o Termsheet!');
    }
    setFiles([]);
    setValueSelect('');
    setTotal(0);
    handleClose();
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 550,
            bgcolor: '#fff',
            p: 4,
            borderRadius: 1,
          }}
        >
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <Typography variant="h6" component="h2">
              Fazer proposta
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon sx={{ backgruound: '#575762' }} />
            </IconButton>
          </Box>
          <Divider sx={{ margin: '20px 0' }} />
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              flexDirection: 'column',
            }}
          >
            <Typography style={{ marginBottom: 5 }}>
              Selecione o número de cotas
            </Typography>

            <Select
              value={valueSelect}
              quotas={startupDetails.quantities_of_quotas}
              handleChange={handleChange}
            />

            <Typography style={{ marginBottom: 5, marginTop: '5%' }}>
              Anexe o Termsheet
            </Typography>
            <DropFile
              files={files}
              onDelete={(index) => onRemoveFile(index)}
              title="Adicionar Termsheet"
              callback={(newElement) =>
                setFiles((oldArray) => [...oldArray, newElement])
              }
              backgroundColor="#fff"
              subtitle="Arraste e solte arquivos aqui"
              multiple
              accept={{}}
            />
            <Typography style={{ marginTop: '10%' }}>Total:</Typography>
            <Typography style={{ fontSize: 20, fontWeight: 'bold' }}>
              {`R$ ${FormatMoneyBRL(total)}`}
            </Typography>
            <Box
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                flexDirection: 'row',
                marginTop: '5%',
              }}
            >
              <Button
                sx={{
                  borderRadius: 28,
                  background: '#FFFFFF',
                  '&:hover': {
                    backgroundColor: '#FFFFFF',
                    opacity: [0.9, 0.8, 0.7],
                  },
                  borderColor: '#542EA6;',
                  border: 1,
                  color: '#542EA6;',
                  width: 200,
                  height: '36px',
                  fontWeight: '400',
                  textTransform: 'uppercase',
                  fontSize: '14px',
                }}
                onClick={handleClose}
              >
                Cancelar
              </Button>
              <Button
                sx={{
                  borderRadius: 28,
                  background: '#542EA6',
                  '&:hover': {
                    backgroundColor: '#542EA6',
                    opacity: [0.9, 0.8, 0.7],
                  },
                  borderColor: '#542EA6;',
                  border: 1,
                  color: '#FFFFFF;',
                  width: 200,
                  height: '36px',
                  fontWeight: '400',
                  paddingRight: '20px',
                  textTransform: 'uppercase',
                }}
                onClick={handleSendProposal}
              >
                Enviar proposta
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
