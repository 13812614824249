import { RoutesMap } from '@src/routes/maps';

export const ROLES = {
  1: 'STARTUP',
  2: 'INVESTOR',
  3: 'CONSULTANT',
};
export const ROUTES_ROLES = {
  1: RoutesMap.HOME_STARTUP,
  2: RoutesMap.HOME_INVESTOR,
  3: RoutesMap.HOME_CONSULTOR,
};
