export function setStep(step) {
  return {
    type: '@startup/SET_STEP',
    payload: step,
  };
}
export function setStartup(data) {
  return {
    type: '@startup/SET_STEP',
    payload: data,
  };
}
