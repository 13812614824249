/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Header from '@src/components/Header';
import Sidebar from '@src/components/Sidebar';
import CompanyProfile from '@src/components/CompanyProfile';
import Proposal from '@src/components/Proposal';
import ModalConfirm from '@src/components/Modal/ModalConfirm';
import ProposalStatusEnum from '@src/constants/enums/proposal-status.enum';
import ApiClient from '@src/services/api/api';
import ModalConclude from './components/ModalConclude';

import { SubHeaderBreadchrumbs, TitlePage, Container } from './styles';

function PropostasAceitas() {
  const { id, investor } = useParams();
  const profile = useSelector((state) => state.user.profile);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState(null);
  const [openConclude, setOpenConclude] = useState(false);
  const [openRefuse, setOpenRefuse] = useState(false);
  const handleOpenConclude = () => setOpenConclude(true);
  const handleCloseConclude = () => setOpenConclude(false);
  const handleOpenRefuse = () => setOpenRefuse(true);
  const handleCloseRefuse = () => setOpenRefuse(false);

  const handleBack = () => {
    navigate('/propostas');
  };

  const fetchProposal = useCallback(async () => {
    setLoading(true);
    ApiClient.fetchProposals({
      startup_id: profile.startup_id,
      id,
      investor_id: investor,
    })
      .then((response) => {
        if (
          response.data?.proposals.length &&
          response.data?.proposals.length === 2
        ) {
          setData({
            ...response.data?.proposals[0],
            ...response.data?.proposals[1],
          });
        } else {
          toast.error(`Falha: id inválido`);
        }
        setLoading(false);
      })
      .catch((error) => {
        toast.error(`Falha: ${error.response.data.message}`);
        setLoading(false);
      });
  }, [profile.startup_id]);

  const onChangeStatus = async () => {
    setLoadingSubmit(true);

    ApiClient.approveOrRejectProposal({
      status_id: ProposalStatusEnum.RECUSADA,
      id,
    })
      .then((response) => {
        if (response.data) {
          setSuccess(true);
        }
        setLoadingSubmit(false);
      })
      .catch((error) => {
        toast.error(`Falha: ${error.response.data.message}`);
        setLoadingSubmit(false);
      });
  };

  const onComplete = async (files) => {
    setLoadingSubmit(true);

    const requests = files.map((item) => {
      const formData = new FormData();
      formData.append('file', item, item.name);
      return ApiClient.upload({
        data: formData,
        userType: 'startup',
        id: profile.startup_id,
      });
    });

    const sendFiles = await Promise.all(requests)
      .then((responses) => responses.map((response) => response.data.file_name))
      .catch(() => {
        toast.error(`Falha: ao enviar arquivos`);
      });

    if (sendFiles.length === 2) {
      ApiClient.completeProposal({
        id,
        startup_id: profile.startup_id,
        quotas: data.quotas,
        purchase_sale_draft: sendFiles[0],
        shareholders_agreement: sendFiles[1],
      })
        .then((response) => {
          if (response.data.success) {
            setSuccess(true);
          } else {
            toast.error(`Falha: ${response.data.message}`);
          }
          setLoadingSubmit(false);
          // fechar modal
          handleCloseConclude();
        })
        .catch((error) => {
          toast.error(`Falha: ${error.response.data.message}`);
          setLoadingSubmit(false);
        });
    } else {
      setLoadingSubmit(false);
    }
  };

  useEffect(() => {
    fetchProposal();
  }, [fetchProposal]);

  return (
    <>
      <Header />
      <Sidebar isStartup />

      <Box
        sx={{
          width: 'calc(100% - 60px)',
          position: 'relative',
          top: '60px',
          left: '60px',
        }}
      >
        <SubHeaderBreadchrumbs>
          <Breadcrumbs
            aria-label="breadcrumb"
            sx={{ color: '#9999AB', fontSize: 12, mb: 1 }}
          >
            <Link underline="hover" color="inherit" href="/">
              Home
            </Link>
            <Link underline="hover" color="inherit" href="/propostas">
              Propostas
            </Link>
            <Link
              underline="hover"
              color="inherit"
              href="/propostas/recebidas"
              aria-current="page"
              sx={{ pointerEvents: 'none' }}
            >
              Proposta Aceita
            </Link>
          </Breadcrumbs>

          <TitlePage>Proposta Aceita</TitlePage>
        </SubHeaderBreadchrumbs>

        <Container>
          {loading ? (
            <Box justifyContent="center" display="flex" p={3}>
              <CircularProgress size={30} />
            </Box>
          ) : (
            <div>
              {data ? (
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={7}>
                    <CompanyProfile data={data} />
                  </Grid>

                  <Grid item xs={12} sm={12} md={5}>
                    <Proposal
                      data={data}
                      isAcceptable
                      onClick={handleOpenConclude}
                      onClickRefuse={handleOpenRefuse}
                    />
                  </Grid>
                </Grid>
              ) : null}
            </div>
          )}
        </Container>
      </Box>
      <ModalConclude
        open={openConclude}
        handleClose={handleCloseConclude}
        onSubmit={(files) => onComplete(files)}
        actionSuccess={handleBack}
        success={success}
        loading={loadingSubmit}
        termsLink={data ? data.url_docs + data.termsheet : ''}
      />
      <ModalConfirm
        open={openRefuse}
        handleClose={handleCloseRefuse}
        onSubmit={() => onChangeStatus()}
        message="Tem certeza que deseja recusar a proposta?"
        txtBtnSuccess="VER TODAS AS PROPOSTAS"
        msgSuccess="Proposta recusada com sucesso!"
        actionSuccess={handleBack}
        success={success}
        loading={loadingSubmit}
      />
    </>
  );
}

export default PropostasAceitas;
