import Box from '@mui/material/Box';

import Header from '@src/components/LandingPage/Header';
import Hero from '@src/components/LandingPage/Hero';
import ComoFunciona from '@src/components/LandingPage/ComoFunciona';
import NossoPapel from '@src/components/LandingPage/NossoPapel';
import Contato from '@src/components/LandingPage/Contato';
import Footer from '@src/components/LandingPage/Footer';

import HeroBg from '@src/assets/images/hero-bg.svg';

export default function Home() {
  return (
    <>
      <Header />
      <Box
        sx={{
          background: `url(${HeroBg}) no-repeat top center`,
          backgroundPositionX: { xs: `70%`, lg: `center` },
          backgroundSize: { xs: '250vw', sm: 'auto' },
          bgcolor: '#fff',
        }}
      >
        <Hero />
        <ComoFunciona />
        <NossoPapel />
        <Contato />
        <Footer />
      </Box>
    </>
  );
}
