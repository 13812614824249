/* eslint-disable class-methods-use-this */
import axios from 'axios';
import config from '../../config/environment';

class HttpClient {
    constructor() {
        this.instance = axios.create({
            baseURL: config.serverUrl,
        });
        this.token = null;
        this.initializeResponseInterceptor();
    }

    initializeResponseInterceptor = () => {
        this.instance.interceptors.request.use((configFromParams) => {
            const axiosConfig = configFromParams;
            axiosConfig.headers['Content-Type'] = 'text';
            axiosConfig.headers.Authorization = `Bearer ${this.token}`;
            return axiosConfig;
        });
    };

    setToken = (token) => {
        this.token = token;
    };

    handleResponse = ({ data }) => data;

    handleError = (error) => Promise.reject(error);
}

export default HttpClient;