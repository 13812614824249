/* eslint-disable default-param-last */
import produce from 'immer';
import * as enterpriseDetailTypes from './types';

const INITIAL_STATE = {
  loading: false,
  loadingApproval: false,
  success: false,
  enterprise: {},
};
export default function enterpriseDetailReducer(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case enterpriseDetailTypes.enterpriseDetailRequest: {
        draft.loading = true;
        break;
      }
      case enterpriseDetailTypes.enterpriseDetailSuccess: {
        draft.enterprise = action.payload.data;
        draft.loading = false;
        break;
      }
      case enterpriseDetailTypes.enterpriseDetailFailure: {
        draft.enterprise = false;
        break;
      }
      case enterpriseDetailTypes.enterpriseApprovalRequest: {
        draft.loadingApproval = true;
        break;
      }
      case enterpriseDetailTypes.enterpriseApprovalSuccess: {
        draft.loadingApproval = false;
        draft.success = true;
        break;
      }
      case enterpriseDetailTypes.enterpriseApprovalFailure: {
        draft.loadingApproval = false;
        break;
      }
      case enterpriseDetailTypes.enterpriseRemoveRequest: {
        draft.loadingApproval = true;
        break;
      }
      case enterpriseDetailTypes.enterpriseRemoveSuccess: {
        draft.loadingApproval = false;
        draft.success = true;
        break;
      }
      case enterpriseDetailTypes.enterpriseRemoveFailure: {
        draft.loadingApproval = false;
        break;
      }
      case enterpriseDetailTypes.enterpriseReset: {
        draft.loading = false;
        draft.loadingApproval = false;
        draft.enterprise = {};
        draft.success = false;
        break;
      }
      case '@auth/SIGN_OUT': {
        draft.loading = false;
        draft.loadingApproval = false;
        draft.enterprise = {};
        draft.success = false;
        break;
      }
      default:
    }
  });
}
