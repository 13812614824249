/* eslint-disable no-console */
import { takeLatest, call, put } from 'redux-saga/effects';

import api from '@src/services/api/api';
import * as startupApprovalTypes from './types';
import * as startupApprovalActions from './actions';

export function* startupApprovalRequest() {
  try {
    const response = yield call(api.getStartups);
    yield put(startupApprovalActions.startupApprovalSuccess(response.data));
  } catch (err) {
    yield put(startupApprovalActions.startupApprovalFailure());
  }
}

export default function* watchConsultorStartupApproval() {
  yield takeLatest(
    startupApprovalTypes.startupApprovalRequest,
    startupApprovalRequest
  );
}
