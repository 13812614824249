import { Box } from '@mui/material';
import icon from '@src/assets/images/eye-off.svg';

export default function EyeIcon() {
  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          left: '0%',
          top: '0%',
          zIndex: 101,
          width: '100%',
          height: '100%',
          filter: 'blur(1px)',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          left: '0%',
          top: '0%',
          zIndex: 101,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img src={icon} alt="Eye icon" />
      </Box>
    </>
  );
}
