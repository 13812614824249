import { useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import Button from '@mui/material/Button';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import DropFile from '@src/components/DropFile';
import { store } from '@src/store';
import ApiClient from '@src/services/api/api';

function TeamForm() {
  const { user } = store.getState().auth;

  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const onRemoveFile = (index) => {
    setFiles(files.filter((_, key) => key !== index));
  };

  const handleAddFile = (file) => {
    if (file.type !== 'application/pdf') return;
    setFiles((oldArray) => [...oldArray, file]);
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      const { length } = files;
      const documents = [];
      if (length > 0) {
        for (let i = 0; i <= length - 1; i += 1) {
          const formData = new FormData();
          formData.append('file', files[i], files[i].name);
          // eslint-disable-next-line no-await-in-loop
          const response = await ApiClient.upload({
            data: formData,
            userType: 'startup',
            id: user.startup_id,
          });
          documents.push(response.data.file_name);
        }
        const data = { documents, id: user.startup_id };
        const response = await ApiClient.updateStartupCompletRegistration(data);
        if (response.data.success === false) {
          alert(`Falha: ${response.response.data.message}`);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(`Falha: ${error.response.data.message}`);
    }
  };

  return (
    <Box sx={{ mt: 8, px: 4 }}>
      <Box>
        <Stack direction="row" spacing={3} sx={{ mt: 2 }}>
          <Typography
            id="transition-modal-title"
            variant="h6"
            component="h2"
            sx={{
              fontSize: '16px',
              fontWeight: '400',
              color: '#575762',
              position: 'relative',
              bottom: '40px',
            }}
          >
            Insira os documentos referentes ao DREs e Balanços informados nos 
            campos anteriores
          </Typography>
        </Stack>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={8} md={4}>
            <DropFile
              files={files}
              onDelete={(index) => onRemoveFile(index)}
              title="Solte os documentos aqui"
              callback={handleAddFile}
              styleTitle={{ fontSize: '20px' }}
              backgroundColor="#F1F1F3"
              minHeight="250px"
              subtitle="Apenas arquivos PDF"
              multiple
              accept={{ 'text/*': ['.pdf'] }}
            />
          </Grid>
        </Grid>
      </Box>

      <Button
        variant="contained"
        color="primary"
        sx={{
          m: 'auto',
          mt: 10,
          position: 'realtive',
          bottom: '20px',
          right: '40px',
          py: '15px',
          px: '26px',
          display: 'flex',
        }}
        disabled={loading}
        type="button"
        onClick={onSubmit}
      >
        {loading ? 'Carregando' : 'Enviar'}
      </Button>
    </Box>
  );
}

export default TeamForm;
