import { useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

import DropFile from '@src/components/DropFile';
import ApiClient from '@src/services/api/api';
import { store } from '@src/store';
import StartupStatusEnum from '@src/constants/enums/startup-status.enum';
import config from '@src/config/environment';

function TeamForm() {
  const { user } = store.getState().auth;
  const navigate = useNavigate();

  const [file, setFile] = useState({});
  const [loading, setLoading] = useState(false);

  const handleAddFile = (fileData) => {
    if (fileData.type !== 'application/pdf') return;
    setFile(fileData);
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('file', file, file.name);
      const {data: {file_name}} = await ApiClient.upload({
        data: formData,
        userType: 'startup',
        id: user.startup_id,
      });
      const data = {
        contract_file_1: file_name,
        id: user.startup_id,
        startup_status_id: StartupStatusEnum.CONTRATO_ASSINADO,
      };
      const response = await ApiClient.updateStartupCompletRegistration(data);
      if (response.data.success === false) {
        alert(`Falha: ${response.response.data.message}`);
      }
      navigate('/complete-registration');
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(`Falha: ${error.response.data.message}`);
    }
  };

  const handleDownloadContract = () => window.open(config.downloadContractUrl, '_blank')

  return (
    <Box sx={{ mt: 8, px: 4 }}>
      <Box>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ mt: 2 }}
        >
          <Typography
            id="transition-modal-title"
            variant="h6"
            component="h2"
            sx={{
              fontSize: '16px',
              fontWeight: '400',
              color: '#575762',

              position: 'relative',
              bottom: '40px',
              width: '800px',
              textAlign: 'center',
            }}
          >
            Para estar visível aos investidores e receber os investimentos, é
            necessário ler atentamente, seguir os passos, assinar e nos enviar o
            documento com a aceitação dos seguintes termos:
          </Typography>

          <Button
            onClick={handleDownloadContract}
            variant="outlined"
            color="primary"
            sx={{
              m: 'auto',
              position: 'realtive',
              fontWeight: 400,
              fontSize: '14px',
              py: '15px',
              px: '26px',
              display: 'flex',
            }}
          >
            Baixar modelo contrato startup showcase
          </Button>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={12} sm={8} md={4}>
            <DropFile
              files={file}
              onDelete={() => setFile({})}
              minHeight="191px"
              title="Arraste e solte o PDF"
              callback={handleAddFile}
              accept={{ 'text/*': ['.pdf'] }}
            />
            </Grid>
          </Grid>
        </Stack>
      </Box>

      <Button
        variant="contained"
        color="primary"
        typw="button"
        sx={{
          m: 'auto',
          position: 'realtive',
          top: '150px',
          py: '15px',
          px: '26px',
          display: 'flex',
        }}
        disabled={loading || !file.path}
        onClick={onSubmit}
      >
        {loading ? 'Carregando' : 'Enviar'}
      </Button>
    </Box>
  );
}

export default TeamForm;
