import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { Typography } from '@mui/material';

import { store } from '@src/store';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#569F59',
  },
}));

const steps = {'0': 0, '1': 33, '2': 66, '3': 100}

export default function StartupDynamicCardProgressBar() {
  const { step } = store.getState().startup;

  return (
    <Box sx={{}}>
      <br />
      <BorderLinearProgress variant="determinate" value={steps[step]} />
      <Typography
        sx={{
          fontSize: '12px',
          color: '#5B5B5B',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {`${step}/3 etapas completas`}
      </Typography>
    </Box>
  );
}