/* eslint-disable no-console */
import { takeLatest, call, put } from 'redux-saga/effects';
import toast from 'react-hot-toast';

import history from '@src/services/history';
import api from '@src/services/api/api';

// import { ROUTES_ROLES } from '@src/libs/models/roles';
import { ROLES } from '@src/libs/models/roles';
import { signInSuccess, signFailure, setUserRole } from './actions';


export function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    api.setToken(token);
  }
}

export function* signIn({ payload }) {
  try {
    const { email, password, user_type_id } = payload;

    const response = yield call(api.signIn, {
      email,
      password,
      user_type_id
    });

    if (response.data.user) {
      const { token, user } = response.data;
      yield put(setUserRole(ROLES[user.user_type_id]));
      yield put(signInSuccess(token, user));
      setToken({payload: {auth: {token}}})
    } else {
      toast.error(response.data.message);
      yield put(signFailure());
    }
  } catch (err) {
    toast.error('Falha: Usuário ou senha incorreta!');
    yield put(signFailure());
  }
}

export function signOut() {
  history.push('/');
}

export default function* watchAuth() {
  yield takeLatest('persist/REHYDRATE', setToken);
  yield takeLatest('@auth/SIGN_IN_REQUEST', signIn);
  yield takeLatest('@auth/SIGN_OUT', signOut);
}
