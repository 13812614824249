/* eslint-disable react/prop-types */

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import FormatMoneyBRL from '@src/helpers/FormatMoneyBRL';

export default function Table({ dataDRE }) {
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        flexDirection: 'row',
      }}
    >
      <Box>
        <Box
          style={{
            marginBottom: 30,
          }}
        >
          <Typography sx={{ fontSize: 14, fontWeight: 800, color: '#515162' }}>
            Faturamento
          </Typography>
          <Typography sx={{ fontSize: 14, fontWeight: 400, color: '#515162' }}>
            {`R$ ${FormatMoneyBRL(dataDRE.invoice)}`}
          </Typography>
        </Box>
        <Box
          style={{
            marginBottom: 30,
          }}
        >
          <Typography sx={{ fontSize: 14, fontWeight: 800, color: '#515162' }}>
            Custos e Despesas
          </Typography>
          <Typography sx={{ fontSize: 14, fontWeight: 400, color: '#515162' }}>
            {`R$ ${FormatMoneyBRL(dataDRE.costs_expenses_ebitide)}`}
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ fontSize: 14, fontWeight: 800, color: '#515162' }}>
            Lucro Líquido
          </Typography>
          <Typography sx={{ fontSize: 14, fontWeight: 400, color: '#515162' }}>
            {`R$ ${FormatMoneyBRL(dataDRE.liquid_profit)}`}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Box
          style={{
            marginBottom: 30,
          }}
        >
          <Typography sx={{ fontSize: 14, fontWeight: 800, color: '#515162' }}>
            Regime Tributário
          </Typography>
          <Typography sx={{ fontSize: 14, fontWeight: 400, color: '#515162' }}>
            {dataDRE.tax_regime}
          </Typography>
        </Box>
        <Box
          style={{
            marginBottom: 30,
          }}
        >
          <Typography sx={{ fontSize: 14, fontWeight: 800, color: '#515162' }}>
            EBITDA
          </Typography>
          <Typography sx={{ fontSize: 14, fontWeight: 400, color: '#515162' }}>
            {`R$ ${FormatMoneyBRL(dataDRE.costs_expenses_ebitide)}`}
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ fontSize: 14, fontWeight: 800, color: '#515162' }}>
            Despesas e Receitas Financeiras
          </Typography>
          <Typography sx={{ fontSize: 14, fontWeight: 400, color: '#515162' }}>
            {`R$ ${FormatMoneyBRL(dataDRE.financial_expenses)}`}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Box
          style={{
            marginBottom: 30,
          }}
        >
          <Typography sx={{ fontSize: 14, fontWeight: 800, color: '#515162' }}>
            Impostos Diretos e Indiretos
          </Typography>
          <Typography
            sx={{ fontSize: '14px', fontWeight: 400, color: '#515162' }}
          >
            {`Consolidado: R$ ${FormatMoneyBRL(dataDRE.indirect_direct_taxes)}`}
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ fontSize: 14, fontWeight: 800, color: '#515162' }}>
            Depreciação
          </Typography>
          <Typography
            sx={{ fontSize: '14px', fontWeight: 400, color: '#515162' }}
          >
            {`R$ ${FormatMoneyBRL(dataDRE.depreciation)}`}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
