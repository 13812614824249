/* eslint-disable no-console */
import api from '@src/services/api/api';
import TransactionsProposalsActions from './actions';

const { call, put, takeLatest } = require('redux-saga/effects');

function* FindTransactionsPendingProposals({ investorId }) {
  try {
    const responseDataTransactionsPendingProposals = yield call(
      api.getTransactionsProposals,
      {
        investorId,
      }
    );

    if (responseDataTransactionsPendingProposals) {
      const { data } = responseDataTransactionsPendingProposals;

      yield put({
        type: TransactionsProposalsActions.findPedingProposals,
        pendingProposals: data,
      });
    } else {
      yield put({
        type: TransactionsProposalsActions.findPedingProposals,
        pendingProposals: [],
      });
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: TransactionsProposalsActions.findPedingProposals,
      pendingProposals: [],
    });
  }
}

export default [
  takeLatest(
    TransactionsProposalsActions.asyncfindPedingProposals,
    FindTransactionsPendingProposals
  ),
];
