/* eslint-disable react/no-array-index-key */
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Header from '@src/components/Header';
import Sidebar from '@src/components/Sidebar';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { useEffect, useState } from 'react';
import ApiClient from '@src/services/api/api';
import CardInvestor from '@src/components/common/Investor';
import ProposalStatusEnum from '@src/constants/enums/proposal-status.enum';
import { SubHeaderBreadchrumbs, TitlePage } from './styles';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Container = styled(Stack)`
  display: flex;
  flex-direction: column;
  padding: 32px 40px;
`;

export default function Transactions() {
  const { investor_id } = useSelector((store) => store.auth.user);
  const [value, setValue] = useState(0);
  const [, setLoading] = useState(false);
  const [totalInvested, setTotalInvested] = useState(0)
  const [accepteds, setAccepteds] = useState([])
  const [receiveds, setReceiveds] = useState([])
  const [concludeds, setConcludeds] = useState([])
  const [refuseds, setRefuseds] = useState([])

  useEffect(() => {
    setLoading(true);
    ApiClient.fetchProposalsInvestor({investor_id}).then(res => {
      setAccepteds(res.data.investor.transactions.filter(t => t.proposal_status_id === ProposalStatusEnum.ACEITA));
      setReceiveds(res.data.investor.transactions.filter(t => t.proposal_status_id === ProposalStatusEnum.RECEBIDA));
      setConcludeds(res.data.investor.transactions.filter(t => t.proposal_status_id === ProposalStatusEnum.INVESTIMENTO_CONCLUIDO));
      setRefuseds(res.data.investor.transactions.filter(t => t.proposal_status_id === ProposalStatusEnum.RECUSADA));
      setTotalInvested(res.data.investor.total_invested);
    }).catch(error => {
      console.log('ERROR', error);
      setLoading(false);
    }).finally(() => {
      setLoading(false);
    });
  }, [investor_id])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <>
      <Header />

      <Sidebar isInvestor />

      <Box
        sx={{
          width: 'calc(100% - 60px)',
          position: 'relative',
          top: '60px',
          left: '60px',
        }}
      >
        <SubHeaderBreadchrumbs>
          <Box>
            <Breadcrumbs
              aria-label="breadcrumb"
              sx={{ color: '#9999AB', fontSize: 12, mb: 1 }}
            >
              <Link underline="hover" color="inherit" href="/">
                Home
              </Link>
              <Link underline="hover" color="inherit" href="/startup">
                All Set
              </Link>
              <Link
                underline="hover"
                color="inherit"
                href="/transactions"
                aria-current="page"
                sx={{ pointerEvents: 'none' }}
              >
                Transações
              </Link>
            </Breadcrumbs>

            <TitlePage>Transações</TitlePage>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: '.875rem',
                textAlign: 'right',
                color: 'text.secondary',
              }}
            >
              total investido:
              <br />
              R$ 
              <span style={{ fontSize: '1.250rem' }}>{totalInvested}</span>
            </Typography>
          </Box>
        </SubHeaderBreadchrumbs>

        <Container spacing={5}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="Transações">
              <Tab
                label="Pendentes"
                {...a11yProps(0)}
                sx={{ color: 'text.secondary' }}
              />
              <Tab label="Aceitas" {...a11yProps(1)} />
              <Tab label="Investimento Concluído" {...a11yProps(2)} />
              <Tab label="Recusadas" {...a11yProps(3)} />
            </Tabs>
          </Box>
          <Grid container spacing={{ xs: '20px' }}>
           {value === 0 && receiveds?.length ? receiveds?.map((startup) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={startup.id}>
                <CardInvestor dataInvestor={startup} />
              </Grid>)
           ) : null}
           {value === 1 && accepteds?.length ? accepteds?.map((startup) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={startup.id}>
                <CardInvestor dataInvestor={startup} />
              </Grid>)
           ) : null}
           {value === 2 && concludeds?.length ? concludeds?.map((startup) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={startup.id}>
                <CardInvestor dataInvestor={startup} />
              </Grid>)
           ) : null}
           {value === 3 && refuseds?.length ? refuseds?.map((startup) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={startup.id}>
                <CardInvestor dataInvestor={startup} />
              </Grid>)
           ) : null}
          </Grid>
        </Container>
      </Box>
    </>
  );
}
