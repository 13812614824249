/* eslint-disable no-console */
import React from 'react';

import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  CssBaseline,
  CircularProgress,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

import Link from '@mui/material/Link';

import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import authSelectors from '@src/store/modules/auth/selectors';

import { signInRequest } from '@src/store/modules/auth/actions';
import LoginStartupImg from '@src/assets/images/LoginStartupImg.png';
import LogoImg from '@src/assets/images/Logo.svg';

import UserTypesEnum from '@src/constants/enums/user-types.enum';
import { Logo } from './styles';

const schema = yup
  .object({
    email: yup.string().email().required(),
    password: yup.string().required(),
  })
  .required();

function SignIn() {
  const loading = useSelector(authSelectors.getLoading);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleRegister = () => {
    navigate('/pre-onboarding-startup');
  };

  const [remember, setRemember] = React.useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = ({ email, password }) => {
    dispatch(signInRequest(email, password, UserTypesEnum.STARTUP));
  };

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={6}
        sx={{
          backgroundImage: `url(${LoginStartupImg})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'left',
        }}
      />
      <CssBaseline />
      <Grid item xs={12} sm={8} md={6} elevation={6} square position="relative">
        <Box
          sx={{
            marginTop: 18,
            px: 8,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: '-25px',
            }}
          >
            <Logo src={LogoImg} alt="Logo Startup-showcase" loading="lazy" />
          </Box>
          <Box
            sx={{
              marginTop: 10,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              component="p"
              sx={{
                width: '300px',
                fontSize: '24px',
                marginBottom: '40px',
                fontWeight: '400',
                color: '#2E2E38',
              }}
            >
              Login in to your account
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="email"
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin="normal"
                    fullWidth
                    label="User"
                    autoFocus
                    color="primary"
                    error={!!errors.email}
                    helperText={errors?.email?.message}
                  />
                )}
              />
              <Controller
                name="password"
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin="normal"
                    fullWidth
                    label="Password"
                    type="password"
                    color="primary"
                    error={!!errors.password}
                    helperText={errors?.password?.message}
                  />
                )}
              />
              <Grid
                container
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <FormControlLabel
                  control={<Checkbox color="primary" />}
                  value={remember}
                  onChange={() => setRemember(!remember)}
                  label="Remember"
                  sx={{
                    color: 'text.secondary',
                    fontWeight: '400',
                  }}
                />
                <Link
                  href="/"
                  variant="body2"
                  sx={{
                    lineHeight: '48px',
                    color: '#1A1A24',
                    fontWeight: '300',
                  }}
                >
                  Forgot password?
                </Link>
              </Grid>
              <Grid
                container
                flexDirection="revert"
                sx={{
                  flexDirection: {
                    xs: 'column-reverse',
                    sm: 'row',
                    md: 'row',
                  },
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  display="flex"
                  sx={{
                    justifyContent: {
                      xs: 'center',
                      sm: 'left',
                      md: 'left',
                    },
                  }}
                >
                  <Button
                    type="button"
                    variant="contained"
                    onClick={handleRegister}
                    sx={{
                      mt: 3,
                      mb: 2,
                      borderRadius: 28,
                      background: 'transparent',
                      '&:hover': {
                        backgroundColor: 'transparent',
                        opacity: [0.9, 0.8, 0.7],
                      },
                      borderColor: '#542EA6;',
                      border: 1,
                      color: '#542EA6;',
                      height: '56px',
                      fontWeight: '400',
                    }}
                  >
                    Cadastre-se
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  display="flex"
                  sx={{
                    justifyContent: {
                      xs: 'center',
                      sm: 'right',
                      md: 'right',
                    },
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={() => handleSubmit(onSubmit)}
                    sx={{
                      mt: 3,
                      mb: 2,
                      borderRadius: 28,
                      boxShadow:
                        ' 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
                      background: '#542EA6;',
                      '&:hover': {
                        backgroundColor: '#542EA6',
                        opacity: [0.9, 0.8, 0.7],
                      },
                      height: '56px',
                      fontWeight: '400',
                      width: '100px',
                    }}
                  >
                    {loading ? (
                      <CircularProgress
                        size={20}
                        sx={{
                          color: '#fff',
                        }}
                      />
                    ) : (
                      'Entrar'
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default SignIn;
