/* eslint-disable react/prop-types */
import { useEffect, useState, useCallback } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Button } from '@mui/material';
import InputMask from "react-input-mask";
import * as yup from 'yup';
import axios from 'axios';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import ApiClient from '@src/services/api/api';
import UserTypesEnum from '@src/constants/enums/user-types.enum';
import { unMask } from '@src/utils/functions';
import ModalEmailConfirmation from '@src/components/Modal/mail-confirmation';

const schema = yup
  .object({
    full_name: yup.string().min(10,"Nome muito curto").required("Campo obrigatório"),
    email: yup.string().email("Email com formato inválido").required("Campo obrigatório"),
    phone: yup.string().required("Campo obrigatório"),
    telephone: yup.string().required("Campo obrigatório"),
    country: yup.string().required("Campo obrigatório"),
    state: yup.string().required("Campo obrigatório"),
    city: yup.string().required("Campo obrigatório"),
    password: yup.string().min(8, 'Mînimo de 8 caracteres').required("Campo obrigatório"),
    confirm_password: yup.string()
    .oneOf([yup.ref('password'), null], 'Senhas diferentes')
  })
  .required();
  
function CadastroBasico() {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('Brasil');
  const [selectedState, setSelectedState] = useState();
  const [showModalMailConfirmation, setModalMailConfirmation] = useState(false);
  const [modalTitle, setModalTitle] = useState();

  const serializedOptions = (label, value) => ({
      value,
      label,
    })

  const handleSetCities = useCallback(async () => {
    if(!selectedState) return;
    axios.get(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedState}/municipios`)
        .then(response => {
          setCities(response.data.map(res => 
            serializedOptions(res.nome, res.nome)
          ));
          setLoading(false)
        });
  },[selectedState])

  useEffect(() => {
    if(selectedCountry !== 'Brasil') {
      reset({ state: "", city: "" })
    }
    setLoading(true)
    axios.get(
      'https://servicodados.ibge.gov.br/api/v1/localidades/paises/')
        .then(response => {
          setCountries(response.data.map(res => 
            serializedOptions(res.nome, res.nome)
          ));
          setLoading(false)
        });
    if(selectedCountry === 'Brasil') {
      axios.get(
        'https://servicodados.ibge.gov.br/api/v1/localidades/estados/')
          .then(response => {
            setStates(response.data.map(res => 
              serializedOptions(res.nome, res.sigla)
            ));
            setLoading(false)
          });
      handleSetCities()
    }    
  },[selectedCountry, handleSetCities, reset])

  const onSubmit = async (data) => {
    delete data.confirm_password;
    try {
      setLoading(true);
      Object.assign(data, {
        user_type_id: UserTypesEnum.STARTUP,
        phone: unMask(data.phone),
        telephone: unMask(data.telephone),
      })
      const response = await ApiClient.signUpUser(data);
      setLoading(false);
      if(response.data.success === false) {
        if(response.data.user?.user_status_id === 2) {
          setModalMailConfirmation(true);
          setModalTitle("Continue seu cadastro")
        } else {
          alert(`Falha: ${response.data.message}`)
        }
      } else {
        setModalMailConfirmation(true);
      }
    } catch (error) {
      setLoading(false);
      alert(`Falha: ${error.response.data.message}`)
    }
  }

  return (
    <Box sx={{ mt: 8, px: 4 }}>

      {showModalMailConfirmation && <ModalEmailConfirmation isOpen disableBackdropClick title={modalTitle} />}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Typography variant="h5" sx={{ mb: 6, fontSize: 20, fontWeight: 400 }}>
            Cadastro Básico
          </Typography>

          <Stack direction="row" spacing={3} sx={{ mt: 2 }}>
            <Controller
              name="full_name"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin="none"
                  fullWidth
                  label="Nome Completo"
                  autoFocus
                  color="primary"
                  error={!!errors.full_name}
                  helperText={errors?.full_name?.message}
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin="none"
                  fullWidth
                  label="E-mail"
                  autoFocus
                  color="primary"
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                />
              )}
            />
          </Stack>

          <Stack direction="row" spacing={3} sx={{ mt: 2 }}>
            <Controller
              name="phone"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <InputMask
                  mask="(99) 99999-9999"
                  value={field.value}
                  onChange={field.onChange}
                  ref={field.ref}
                >
                  <TextField
                    margin="none"
                    fullWidth
                    label="Celular"
                    autoFocus
                    color="primary"
                    error={!!errors.phone}
                    helperText={errors?.phone?.message}
                  />
                </InputMask>
              )}
            />
            <Controller
              name="telephone"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <InputMask
                  mask="(99) 9999-9999"
                  value={field.value}
                  onChange={field.onChange}
                >
                <TextField
                  ref={field.ref}
                  margin="none"
                  fullWidth
                  label="Telefone"
                  autoFocus
                  color="primary"
                  error={!!errors.telephone}
                  helperText={errors?.telephone?.message}
                  />
                </InputMask>
              )}
            />
          </Stack>
        </Box>

        <Box sx={{ mt: 6 }}>
          <Typography variant="h5" sx={{ mb: 6, fontSize: 20, fontWeight: 400 }}>
            Informe sua localidade
          </Typography>

          <Stack direction="row" spacing={3} sx={{ mt: 2 }}>
            <Controller
              name="country"
              defaultValue="Brasil"
              control={control}
              rules={{ required: true }}
              render={({ field: {onChange, ...field} }) => (
                <TextField
                  {...field}
                  margin="none"
                  fullWidth
                  onChange={e => {
                    setSelectedCountry(e.target.value);
                    onChange(e.target.value)
                  }}
                  label="País"
                  select
                  autoFocus
                  value={selectedCountry}
                  color="primary"
                  error={!!errors.country}
                  helperText={errors?.country?.message}
                  disabled={loading}
                >
                  {countries.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <Controller
              name="state"
              defaultValue=""
              control={control}
              rules={{ required: true }}
              render={({ field: {onChange, ...field} }) => (
                <TextField
                  {...field}
                  onChange={e => {
                    setSelectedState(e.target.value);
                    onChange(e.target.value)
                  }}
                  margin="none"
                  fullWidth
                  label="Estado"
                  select={selectedCountry === "Brasil"}
                  autoFocus
                  color="primary"
                  error={!!errors.state}
                  helperText={errors?.state?.message}
                >
                  {selectedCountry === 'Brasil' && (
                    states.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))
                  )}
                </TextField>
              )}
            />
            <Controller
              name="city"
              defaultValue=""
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin="none"
                  fullWidth
                  label="Cidade"
                  select={selectedCountry === "Brasil"}
                  autoFocus
                  color="primary"
                  error={!!errors.city}
                  helperText={errors?.city?.message}
                  disabled={loading || !selectedState}
                >
                  {selectedCountry === 'Brasil' && (
                    cities.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))
                  )}
                </TextField>
              )}
            />
          </Stack>
        </Box>

        <Box sx={{ mt: 6 }}>
          <Typography variant="h5" sx={{ mb: 6, fontSize: 20, fontWeight: 400 }}>
            Crie uma nova senha
          </Typography>

          <Stack direction="row" spacing={3} sx={{ mt: 2 }}>
            <Controller
              name="password"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin="none"
                  fullWidth
                  label="Senha"
                  type="password"
                  color="primary"
                  error={!!errors.password}
                  helperText={errors?.password?.message}
                />
              )}
            />
            <Controller
             name="confirm_password"
             control={control}
             rules={{ required: true }}
             render={({ field }) => (
               <TextField
                 {...field}
                 margin="none"
                 fullWidth
                 label="Confirmar senha"
                 type="password"
                 color="primary"
                 error={!!errors.confirm_password}
                 helperText={errors?.confirm_password?.message}
               />
              )}
            />
          </Stack>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            mt: 7,
            mb: 12,
          }}
        >
          <Button variant="contained" color="primary" type='submit' disabled={loading}>
            {loading ? 'Carregando' : 'Próximo'}
          </Button>
        </Box>
      </form>
    </Box>
  );
}

export default CadastroBasico;
