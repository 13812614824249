export function signInRequest(email, password, user_type_id) {
  return {
    type: '@auth/SIGN_IN_REQUEST',
    payload: { email, password, user_type_id },
  };
}
export function setUserRole(role) {
  return {
    type: '@auth/USER_ROLE',
    payload: { role },
  };
}

export function signInSuccess(token, user) {
  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: { token, user },
  };
}

export function signFailure() {
  return {
    type: '@auth/SIGN_FAILURE',
  };
}

export function signOut() {
  return {
    type: '@auth/SIGN_OUT',
  };
}

const authActions = {
  signInRequest,
  setUserRole,
  signInSuccess,
  signFailure,
  signOut,
};

export default authActions;
