export const startupApprovalRequest = '@consultor/STARTUP_APPROVAL_REQUEST';
export const startupApprovalSuccess = '@consultor/STARTUP_APPROVAL_SUCCESS';
export const startupApprovalFailure = '@consultor/STARTUP_APPROVAL_FAILURE';
export const startupApprovalReset = '@consultor/STARTUP_APPROVAL_RESET';

const consultorStartupApprovalTypes = {
  startupApprovalRequest,
  startupApprovalSuccess,
  startupApprovalFailure,
  startupApprovalReset,
};

export default consultorStartupApprovalTypes;
