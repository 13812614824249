import styled from 'styled-components';

const CardInfo = styled.div`
  height: 180px;
  background: #ffffff;
  border: 1px solid #d6d6d6;
  box-shadow: 0px 2px 2px 0px #0000000d;
  border-radius: 8px;
  padding: 18px;
`;

export { CardInfo };
