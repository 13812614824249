import styled from 'styled-components';

const CardInfoRow = styled.div`
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #d6d6d6;
  box-shadow: 0px 2px 2px 0px #0000000d;
  border-radius: 8px;
  padding: 12px;
  margin-top: 16px;
`;

export { CardInfoRow };
