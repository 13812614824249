/* eslint-disable no-console */
import { useRef } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

import Label from '@src/components/common/Label';
import ThumbCompany from '@src/components/common/ThumbCompany';
import { ApprovalStartupsTypes, JourneyTypes } from '@src/libs/models/types';
import PreRegistrationModal from '@src/components/PreRegistrationModal';
import ventureAirSvg from '@src/assets/images/venture-air.svg';
import { RoutesMap } from '@src/routes/maps';
import { generatePath, useNavigate } from 'react-router-dom';

function CardEnterprise({ enterprise, type, journey }) {
  const viewMoreRef = useRef();
  const navigate = useNavigate();

  function handleOnClick() {
    if (type === ApprovalStartupsTypes[1] || journey === JourneyTypes[2]) {
      viewMoreRef.current?.open();
    }
    if (
      type !== ApprovalStartupsTypes[1] &&
      type !== ApprovalStartupsTypes[2] &&
      journey === JourneyTypes[1]
    ) {
      const path = generatePath(RoutesMap.CONSULTOR_STARTUP, {
        id: enterprise.id,
      });
      navigate(path, {
        state: {
          type,
          enterprise,
        },
      });
    }
  }

  function handleTalkWithClient() {
    if (
      ApprovalStartupsTypes[5] === type ||
      (ApprovalStartupsTypes[4] === type && journey === JourneyTypes[2])
    ) {
      const route =
        journey === JourneyTypes[1]
          ? RoutesMap.CONSULTOR_STARTUP_PROPOSALS
          : RoutesMap.CONSULTOR_INVESTOR_PROPOSALS;
      const path = generatePath(route, {
        id: enterprise.id,
      });

      navigate(path);
    } else {
      window.location.href = `mailto:${enterprise.email}`;
    }
  }

  function renderButtons() {
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {type !== ApprovalStartupsTypes[2] && (
          <Button
            variant="outlined"
            size="small"
            color="primary"
            onClick={() => handleOnClick()}
            sx={{
              maxWidth: '232px',
              height: '30px',
              width: '100%',
              fontWeight: 700,
            }}
          >
            Ver mais
          </Button>
        )}

        {(ApprovalStartupsTypes[2] === type ||
          ApprovalStartupsTypes[5] === type ||
          (ApprovalStartupsTypes[4] === type &&
            journey === JourneyTypes[2])) && (
          <Button
            variant="outlined"
            size="small"
            color="primary"
            sx={{
              maxWidth: '232px',
              height: '30px',
              width: '100%',
              fontWeight: 700,
              fontSize: 12,
              marginTop: 1.75,
            }}
            onClick={() => handleTalkWithClient()}
          >
            {ApprovalStartupsTypes[5] === type ||
            (ApprovalStartupsTypes[4] === type && journey === JourneyTypes[2])
              ? 'Acompanhar propostas'
              : 'Falar com a empresa'}
          </Button>
        )}
      </div>
    );
  }

  return (
    <Paper
      sx={{
        padding: '18px 23px 23px 26px',
        minWidth: 'auto',
        minHeight: 220,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '74px auto',
          gap: '15px 10px',
          gridTemplateAreas: `
          "thumb title"
          "thumb label"`,
          justifyItems: 'flex-start',
          alignItems: 'flex-start',
        }}
      >
        <ThumbCompany src={enterprise.logo || ventureAirSvg} />

        <Typography
          sx={{
            fontSize: '1.250rem',
            lineHeight: '1.2em',
            color: 'text.secondary',
            gridArea: 'title',
            ml: 1,
          }}
        >
          {enterprise?.fantasy_name}
        </Typography>

        <Label text={enterprise?.sector || 'Desconhecido'} />
      </Box>
      {renderButtons(type)}
      <PreRegistrationModal
        id={enterprise.id}
        ref={viewMoreRef}
        journey={journey}
        type={type}
      />
    </Paper>
  );
}

CardEnterprise.propTypes = {
  enterprise: PropTypes.oneOfType([PropTypes.object]).isRequired,
  type: PropTypes.string.isRequired,
  journey: PropTypes.string.isRequired,
};

export default CardEnterprise;
