import { useState } from 'react';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import ptBr from 'date-fns/locale/pt-BR';
import 'react-datepicker/dist/react-datepicker.css';

registerLocale('pt-Br', ptBr);
setDefaultLocale('pt-Br');

export default function BasicDatePicker({ onChange }) {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  return (
    <DatePicker
      locale="pt-Br"
      selectsRange
      startDate={startDate}
      endDate={endDate}
      onChange={(update) => {
        setDateRange(update);
        onChange(update);
      }}
      isClearable
      customInput={
        <TextField
          label="Filtrar por período"
          sx={{
            width: '100%',
            color: '#000000',
          }}
        />
      }
    />
  );
}

BasicDatePicker.propTypes = {
  onChange: PropTypes.func,
};

BasicDatePicker.defaultProps = {
  onChange: () => {},
};
