import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';

const Img = styled('img')({
  display: 'block',
  maxWidth: '100%',
  height: '100%',
});

function ThumbCompany({ src }) {
  return (
    <ButtonBase
      sx={{
        width: '74px',
        height: '74px',
        gridArea: 'thumb',
      }}
    >
      <Img alt="logo" src={src} />
    </ButtonBase>
  );
}

ThumbCompany.propTypes = {
  src: PropTypes.string.isRequired,
};

export default ThumbCompany;
