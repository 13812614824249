import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { BoxFuncionaBg } from '@src/constants/imgs';

const BoxStep = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 222px;
  height: 205px;
  background: url(${BoxFuncionaBg}) no-repeat top center;
  @media (max-width: 900px) {
    margin-bottom: 32px;
  }
  @media (max-width: 600px) {
    margin-bottom: 0px;
  }
`;

const StepNumber = styled.span(
  ({ theme }) => `
  color: ${theme.palette.primary.contrastText};
  background: ${theme.palette.primary.main};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  position: absolute;
  top: 20px;
  left: 0;
`
);

const StepText = styled(Typography)(
  ({ theme }) => `
  color: ${theme.palette.primary.main};
  text-align:center;  
`
);

const StepArrow = styled(ArrowForwardIcon)(
  ({ theme }) => `
  color: ${theme.palette.primary.main};
  @media (max-width: 600px) {
    transform: rotate(90deg);
    margin: 16px 0;
  }
`
);

const StepArrowMiddle = styled(StepArrow)`
  @media (max-width: 900px) {
    position: absolute;
    transform: rotate(135deg);
  }
  @media (max-width: 600px) {
    position: relative;
    transform: rotate(90deg);
  }
`;

export { StepNumber, BoxStep, StepText, StepArrow, StepArrowMiddle };
