/* eslint-disable react/no-array-index-key */
import { useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import NumberFormat from 'react-number-format';
import * as yup from 'yup';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

import { store } from '@src/store';
import { yupResolver } from '@hookform/resolvers/yup';
import ApiClient from '@src/services/api/api';

const schema = yup.object().shape({
  balances: yup.array().of(
    yup.object().shape({
      year: yup.string()
        .required('Campo obrigatório'),
      cash: yup.string()
        .required('Campo obrigatório'),
      fixed_assets: yup.string()
        .required('Campo obrigatório'),
      share_capital: yup.string()
        .required('Campo obrigatório'),
      financing: yup.string()
        .required('Campo obrigatório'),
      bills_receive: yup.string()
        .required('Campo obrigatório'),
      bills_pay: yup.string()
        .required('Campo obrigatório'),
      accumulated_profit: yup.string()
        .required('Campo obrigatório'),
    })
  ),
});

function BalanceForm() {
  const { user } = store.getState().auth;
  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      shareHolders: [
        {
          year: '',
          cash: '',
          fixed_assets: '',
          share_capital: '',
          financing: '',
          bills_receive: '',
          bills_pay: '',
          accumulated_profit: '',
          id: undefined,
        },
      ],
    },
  });
  const {
    fields: balances,
    append,
    remove,
  } = useFieldArray({ name: 'balances', control });
  const [loading, setLoading] = useState(false);

  const handleAddMoreInputs = () => {
    append({
      year: '',
      cash: '',
      fixed_assets: '',
      share_capital: '',
      financing: '',
      bills_receive: '',
      bills_pay: '',
      accumulated_profit: '',
      id: undefined,
    });
  };

  const handleRemoveInputs = () => {
    remove(balances.length - 1);
  };

  const replaceToFloatNumber = (string) => {
    const [first, second] = string.split('R$');
    if(second) {
      return parseFloat(second.replace('.', '').replace(',', '.'));
    } 
    return parseFloat(first);
  }

  const onSubmit = async (data) => {
    if(!data?.balances?.length) return
    setLoading(true);
    try {
      const {length} = data.balances;
      let signUpShareHolderError = false;
      for (let i = 0; i <= length - 1; i += 1) {
        const balanceData = {
          startup_id: user.startup_id,
          year: data.balances[i].year,
          cash: replaceToFloatNumber(data.balances[i].cash),
          fixed_assets: replaceToFloatNumber(data.balances[i].fixed_assets),
          share_capital: replaceToFloatNumber(data.balances[i].share_capital),
          financing: replaceToFloatNumber(data.balances[i].financing),
          bills_receive: replaceToFloatNumber(data.balances[i].bills_receive),
          bills_pay: replaceToFloatNumber(data.balances[i].bills_pay),
          accumulated_profit: replaceToFloatNumber(data.balances[i].accumulated_profit),
        }
        // eslint-disable-next-line no-await-in-loop
        const response = await ApiClient.signUpBalance(balanceData);
        if (response.data.success === false) {
          signUpShareHolderError = true;
        }
      }
      if (signUpShareHolderError) {
        alert(
          `Um ou mais balances podem não ter sido cadastrados, tente novamente`
        );
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      alert(`Falha ${err}`);
    }
  };

  return (
    <Box
      sx={{ mt: 8, mb: 3, px: 4 }}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Typography
        sx={{
          fontSize: '16px',
          width: '43vw',
          color: '#575762',
          fontWeight: 400,
          position: 'relative',
          bottom: '40px',
        }}
      >
        Insira as informações de balanço dos últimos 3 anos de atuação da sua
        empresa. Caso a sua empresa seja mais jovem do que o período indicado,
        insira as informações dos últimos anos de operação da sua empresa.
      </Typography>
      <Box>
        {balances.map((balance, index) => (
          <Box key={index}>
            <Stack direction="row" spacing={3} sx={{ mt: 2 }}>
              <Controller
                name={`balances.${index}.year`}
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    key={balance.id}
                    {...field}
                    {...register(`balances.${index}.year`)}
                    margin="none"
                    sx={{ mb: 5 }}
                    fullWidth
                    label="Ano"
                    autoFocus
                    color="primary"
                    type="number"
                    error={!!errors.balances?.[index]?.year}
                    helperText={errors.balances?.[index]?.year?.message}
                  />
                )}
              />
            </Stack>

            <Stack direction="row" spacing={3} sx={{ mt: 2 }}>
              <Controller
                name={`balances.${index}.cash`}
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, name, value, ...field } }) => (
                  <NumberFormat
                    customInput={TextField}
                    decimalScale={2}
                    decimalSeparator=","
                    thousandSeparator="."
                    fixedDecimalScale
                    fullWidth
                    label="Caixa"
                    prefix="R$"
                    name={name}
                    value={value}
                    onChange={onChange}
                    error={!!errors.balances?.[index]?.cash}
                    helperText={errors.balances?.[index]?.cash?.message}
                  >
                    <TextField
                      key={balance.id}
                      {...field}
                      {...register(`balances.${index}.cash`)}
                      margin="none"
                      sx={{ mb: 5 }}
                      fullWidth
                      label="Caixa"
                      autoFocus
                      color="primary"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocalAtmIcon
                              sx={{
                                color: '#cccccc',
                                width: '40px',
                                height: '40px',
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                    </NumberFormat>
                )}
              />
              <Controller
                name={`balances.${index}.fixed_assets`}
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, name, value, ...field } }) => (
                  <NumberFormat
                    customInput={TextField}
                    decimalScale={2}
                    decimalSeparator=","
                    thousandSeparator="."
                    fixedDecimalScale
                    fullWidth
                    label="Ativos Imobilizados"
                    prefix="R$"
                    name={name}
                    value={value}
                    onChange={onChange}
                    error={!!errors.balances?.[index]?.fixed_assets}
                    helperText={errors.balances?.[index]?.fixed_assets?.message}
                  >
                    <TextField
                      key={balance.id}
                      {...field}
                      {...register(`balances.${index}.fixed_assets`)}
                      margin="none"
                      sx={{ mb: 5 }}
                      fullWidth
                      label="Ativos Imobilizados"
                      autoFocus
                      color="primary"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocalAtmIcon
                              sx={{
                                color: '#cccccc',
                                width: '40px',
                                height: '40px',
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </NumberFormat>
                )}
              />
            </Stack>

            <Stack direction="row" spacing={3} sx={{ mt: 2 }}>
              <Controller
                name={`balances.${index}.share_capital`}
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, name, value, ...field } }) => (
                  <NumberFormat
                    customInput={TextField}
                    decimalScale={2}
                    decimalSeparator=","
                    thousandSeparator="."
                    fixedDecimalScale
                    fullWidth
                    label="Capital Social"
                    prefix="R$"
                    name={name}
                    value={value}
                    onChange={onChange}
                    error={!!errors.balances?.[index]?.share_capital}
                    helperText={errors.balances?.[index]?.share_capital?.message}
                  >
                    <TextField
                      key={balance.id}
                      {...field}
                      {...register(`balances.${index}.share_capital`)}
                      margin="none"
                      sx={{ mb: 5 }}
                      fullWidth
                      autoFocus
                      color="primary"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocalAtmIcon
                              sx={{
                                color: '#cccccc',
                                width: '40px',
                                height: '40px',
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </NumberFormat>
                )}
              />
              <Controller
                name={`balances.${index}.financing`}
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, name, value, ...field } }) => (
                  <NumberFormat
                    customInput={TextField}
                    decimalScale={2}
                    decimalSeparator=","
                    thousandSeparator="."
                    fixedDecimalScale
                    fullWidth
                    label="Financiamentos"
                    prefix="R$"
                    name={name}
                    value={value}
                    onChange={onChange}
                    error={!!errors.balances?.[index]?.financing}
                    helperText={errors.balances?.[index]?.financing?.message}
                  >
                    <TextField
                      key={balance.id}
                      {...field}
                      {...register(`balances.${index}.financing`)}
                      margin="none"
                      sx={{ mb: 5 }}
                      fullWidth
                      autoFocus
                      color="primary"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocalAtmIcon
                              sx={{
                                color: '#cccccc',
                                width: '40px',
                                height: '40px',
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </NumberFormat>
                )}
              />
            </Stack>

            <Stack direction="row" spacing={3} sx={{ mt: 2 }}>
              <Controller
                name={`balances.${index}.bills_receive`}
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, name, value, ...field } }) => (
                  <NumberFormat
                    customInput={TextField}
                    decimalScale={2}
                    decimalSeparator=","
                    thousandSeparator="."
                    fixedDecimalScale
                    fullWidth
                    label="Contas a receber"
                    prefix="R$"
                    name={name}
                    value={value}
                    onChange={onChange}
                    error={!!errors.balances?.[index]?.bills_receive}
                    helperText={errors.balances?.[index]?.bills_receive?.message}
                  >
                    <TextField
                      key={balance.id}
                      {...field}
                      {...register(`balances.${index}.bills_receive`)}
                      margin="none"
                      sx={{ mb: 5 }}
                      fullWidth
                      autoFocus
                      color="primary"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocalAtmIcon
                              sx={{
                                color: '#cccccc',
                                width: '40px',
                                height: '40px',
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </NumberFormat>
                )}
              />
              <Controller
                name={`balances.${index}.bills_pay`}
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, name, value, ...field } }) => (
                  <NumberFormat
                    customInput={TextField}
                    decimalScale={2}
                    decimalSeparator=","
                    thousandSeparator="."
                    fixedDecimalScale
                    fullWidth
                    label="Contas a pagar"
                    prefix="R$"
                    name={name}
                    value={value}
                    onChange={onChange}
                    error={!!errors.balances?.[index]?.bills_pay}
                    helperText={errors.balances?.[index]?.bills_pay?.message}
                  >
                    <TextField
                      key={balance.id}
                      {...field}
                      {...register(`balances.${index}.bills_pay`)}
                      margin="none"
                      sx={{ mb: 5 }}
                      fullWidth
                      autoFocus
                      color="primary"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocalAtmIcon
                              sx={{
                                color: '#cccccc',
                                width: '40px',
                                height: '40px',
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </NumberFormat>
                )}
              />
            </Stack>

            <Stack direction="row" spacing={3} sx={{ mt: 2 }}>
              <Controller
                name={`balances.${index}.accumulated_profit`}
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, name, value, ...field } }) => (
                  <NumberFormat
                    customInput={TextField}
                    decimalScale={2}
                    decimalSeparator=","
                    thousandSeparator="."
                    fixedDecimalScale
                    fullWidth
                    label="Lucros Acumulados"
                    prefix="R$"
                    name={name}
                    value={value}
                    onChange={onChange}
                    error={!!errors.balances?.[index]?.accumulated_profit}
                    helperText={errors.balances?.[index]?.accumulated_profit?.message}
                  >
                    <TextField
                      key={balance.id}
                      {...field}
                      {...register(`balances.${index}.accumulated_profit`)}
                      margin="none"
                      sx={{ mb: 5 }}
                      fullWidth
                      autoFocus
                      color="primary"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocalAtmIcon
                              sx={{
                                color: '#cccccc',
                                width: '40px',
                                height: '40px',
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </NumberFormat>
                )}
              />
            </Stack>
          </Box>
        ))}
        <Button
          variant="outlined"
          endIcon={<RemoveIcon />}
          onClick={handleRemoveInputs}
          sx={{
            mt: 2,
            mb: 5,
            ml: 'auto',
            mr: 2,
            py: '6px',
            px: '16px',
            fontWeight: 'bold',
          }}
          disabled={loading || balances.length < 2}
        >
          Remover ano
        </Button>
        <Button
          variant="outlined"
          endIcon={<AddIcon />}
          sx={{
            mt: 2,
            mb: 5,
            ml: 'auto',
            py: '6px',
            px: '16px',
            fontWeight: 'bold',
          }}
          onClick={handleAddMoreInputs}
          disabled={loading}
        >
          Adicionar ano
        </Button>
      </Box>
      <Button
        variant="outlined"
        color="primary"
        onClick={onSubmit}
        sx={{
          m: 'auto',
          position: 'realtive',

          mb: '70px',
          display: 'flex',
        }}
        disabled={loading}
        type="submit"
      >
        {loading ? 'Carregando' : 'Salvar Alterações'}
      </Button>
    </Box>
  );
}

export default BalanceForm;
