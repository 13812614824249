/* eslint-disable no-console */
/* eslint-disable jsx-a11y/anchor-is-valid */

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import OnboardStartupImg from '../../../assets/images/OnboardStartupImg.png';
import Header from '../../../components/Header/OnboardingHeader';

function Step() {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate('/onboarding-startup');
  };

  return (
    <Grid component="main">
      <Grid position="absolute" top={0} container>
        <Header />
      </Grid>
      <Grid container sx={{ height: '100vh', paddingTop: '60px' }}>
        <Grid
          item
          xs={false}
          sm={4}
          md={6}
          sx={{
            backgroundImage: `url(${OnboardStartupImg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'left',
          }}
        />

        <Grid item xs={12} sm={8} md={6} square position="relative">
          <Box
            px={6}
            py={3}
            sx={{
              background: '#FFFFFF;',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              component="p"
              sx={{
                fontSize: '24px',
                fontWeight: '400',
                color: '#2E2E38',
              }}
            >
              Olá, <br />
              <Typography
                component="p"
                sx={{
                  fontSize: '34px',
                  fontWeight: '700',
                  color: '#2E2E38',
                }}
              >
                Startup
              </Typography>
            </Typography>
          </Box>
          <Typography
            px={6}
            component="p"
            pt={5}
            sx={{
              fontSize: '20px',
              fontWeight: '500',
              color: '#2E2E38',
            }}
          >
            Antes de comerçarmos, é importante que você saiba que:
          </Typography>
          <Box px={6}>
            <Grid container pt={6}>
              <Grid item xs={2} sm={1} md={1}>
                <Box
                  sx={{
                    width: '30px',
                    height: '30px',
                    background: '#542EA6',
                    borderRadius: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    component="p"
                    sx={{
                      fontSize: '14px',
                      fontWeight: 800,
                      color: '#FFFFFF',
                    }}
                  >
                    1
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={10} sm={11} md={11}>
                <Typography
                  component="p"
                  sx={{
                    fontSize: '20px',
                    lineHeight: '21px',
                    fontWeight: '500',
                    color: '#2E2E38',
                  }}
                >
                  Este primeiro cadastro serve para que avaliemos as informações
                  de sua empresa.
                </Typography>
              </Grid>
            </Grid>

            <Grid container pt={6}>
              <Grid item xs={2} sm={1} md={1}>
                <Box
                  sx={{
                    width: '30px',
                    height: '30px',
                    background: '#542EA6',
                    borderRadius: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    component="p"
                    sx={{
                      fontSize: '14px',
                      fontWeight: 800,
                      color: '#FFFFFF',
                    }}
                  >
                    2
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={10} sm={11} md={11}>
                <Typography
                  component="p"
                  sx={{
                    fontSize: '20px',
                    lineHeight: '21px',
                    fontWeight: '500',
                    color: '#2E2E38',
                  }}
                >
                  Sua startup não terá acesso à plataforma enquanto sua conta
                  estiver em fase de aprovação.
                </Typography>
              </Grid>
            </Grid>
            <Grid container pt={6}>
              <Grid item xs={2} sm={1} md={1}>
                <Box
                  sx={{
                    width: '30px',
                    height: '30px',
                    background: '#542EA6',
                    borderRadius: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    component="p"
                    sx={{
                      fontSize: '14px',
                      fontWeight: 800,
                      color: '#FFFFFF',
                    }}
                  >
                    3
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={10} sm={11} md={11}>
                <Typography
                  component="p"
                  sx={{
                    fontSize: '20px',
                    lineHeight: '21px',
                    fontWeight: '400',
                    color: '#2E2E38',
                  }}
                >
                  Dentro de alguns dias você receberá um e-mail, no e-mail
                  cadastrado na etapa a seguir, referente ao status de aprovação
                  da sua empresa.
                </Typography>
              </Grid>
            </Grid>
            <Grid container pt={6}>
              <Grid item xs={2} sm={1} md={1}>
                <Box
                  sx={{
                    width: '30px',
                    height: '30px',
                    background: '#542EA6',
                    borderRadius: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    component="p"
                    sx={{
                      fontSize: '14px',
                      fontWeight: 800,
                      color: '#FFFFFF',
                    }}
                  >
                    4
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={10} sm={11} md={11}>
                <Typography
                  component="p"
                  sx={{
                    fontSize: '20px',
                    lineHeight: '21px',
                    fontWeight: '400',
                    color: '#2E2E38',
                  }}
                >
                  Em caso de dúvidas, você pode entrar em contato conosco pelos
                  canais de atendimento ou pelo botão de chat, localizado ao
                  lado esquerdo da tela.
                </Typography>
                <Grid container pt={6}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    justifyContent="right"
                    alignItems="flex-end"
                    display="flex"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                    >
                      Começar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Step;
