import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ThumbCompany from '@src/components/common/ThumbCompany';
import { useNavigate } from 'react-router-dom';
import { RoutesMap } from '@src/routes/maps';
import startupApprovalSvg from '@src/assets/images/startup-approval.svg';

function StartupApprovalCard() {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(RoutesMap.CONSULTOR_STARTUP_APPROVAL);
  };
  return (
    <Paper
      sx={{
        p: 3,
        maxWidth: '310px',
        height: '300px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box>
        <Typography
          sx={{
            fontSize: '.875rem',
            lineHeight: '1.2em',
            color: 'text.secondary',
            fontWeight: 800,
          }}
        >
          Startups
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          sx={{
            width: '160px',
            fontSize: '.875rem',
            color: 'text.primary',
            fontWeight: 400,
          }}
        >
          Aprove ou rejeite o cadastro de novas Startups na plataforma!
        </Typography>
        <ThumbCompany src={startupApprovalSvg} />
      </Box>

      <Button
        onClick={handleNavigate}
        variant="outlined"
        size="small"
        color="secondary"
        sx={{
          maxWidth: '232px',
          height: '30px',
          width: '100%',
          fontWeight: 700,
          fontSize: 12,
          marginTop: 1.75,
        }}
      >
        ANALISAR CADASTROS
      </Button>
    </Paper>
  );
}

export default StartupApprovalCard;
