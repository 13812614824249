/* eslint-disable no-console */
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import * as yup from 'yup';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import ApiClient from '@src/services/api/api';
import { store } from '@src/store';

const schema = yup.object({
  overview: yup.string().required('Campo obrigatório'),
  company_mission: yup.string().required('Campo obrigatório'),
  company_vision: yup.string().required('Campo obrigatório'),
  company_values: yup.string().required('Campo obrigatório'),
  business_model: yup.string().required('Campo obrigatório'),
});

function BasicInformatinForm() {
  const { user } = store.getState().auth;

  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      Object.assign(data, { id: user.startup_id });
      const response = await ApiClient.updateStartupCompletRegistration(data);
      if (response.data.success === false) {
        alert(`Falha: ${response.response.data.message}`);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(`Falha: ${error.response.data.message}`);
    }
  };

  useEffect(() => {
    setLoading(true);
    ApiClient.fetchStartupGeneralData(user.startup_id)
      .then((response) => {
        reset({
          overview: response.data.startupdata[0].overview,
          company_mission: response.data.startupdata[0].company_mission,
          company_vision: response.data.startupdata[0].company_vision,
          company_values: response.data.startupdata[0].company_values,
          business_model: response.data.startupdata[0].business_model,
        });
      })
      .catch((error) => {
        alert(`Falha: ${error.response.data.message}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [reset, user.startup_id]);

  return (
    <Box
      sx={{ mt: 8, px: 4 }}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box>
        <Stack direction="row" spacing={3} sx={{ mt: 2 }}>
          <Controller
            name="overview"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                margin="none"
                sx={{ mb: 5 }}
                fullWidth
                // Label Correction
                label="Resumo da Empresa"
                InputLabelProps={{ shrink: true }}
                autoFocus
                multiline
                rows={4}
                color="primary"
                error={!!errors.overview}
                helperText={errors?.overview?.message}
              />
            )}
          />
          <Controller
            name="company_mission"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                margin="none"
                sx={{ mb: 5 }}
                fullWidth
                label="Missão"
                InputLabelProps={{ shrink: true }}
                autoFocus
                multiline
                rows={4}
                color="primary"
                error={!!errors.company_mission}
                helperText={errors?.company_mission?.message}
              />
            )}
          />
        </Stack>

        <Stack direction="row" spacing={3} sx={{ mt: 2 }}>
          <Controller
            name="company_vision"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                margin="none"
                sx={{ mb: 5 }}
                fullWidth
                label="Visão"
                InputLabelProps={{ shrink: true }}
                autoFocus
                multiline
                rows={4}
                color="primary"
                error={!!errors.company_vision}
                helperText={errors?.company_vision?.message}
              />
            )}
          />
          <Controller
            name="company_values"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                margin="none"
                sx={{ mb: 5 }}
                fullWidth
                label="Valores"
                InputLabelProps={{ shrink: true }}
                autoFocus
                multiline
                rows={4}
                color="primary"
                error={!!errors.company_values}
                helperText={errors?.company_values?.message}
              />
            )}
          />
        </Stack>

        <Stack direction="row" spacing={3} sx={{ mt: 2 }}>
          <Controller
            name="business_model"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                margin="none"
                sx={{ mb: 5 }}
                fullWidth
                label="Modelo de Negócios"
                InputLabelProps={{ shrink: true }}
                autoFocus
                color="primary"
                error={!!errors.business_model}
                helperText={errors?.business_model?.message}
              />
            )}
          />
        </Stack>
      </Box>

      <Button
        variant="outlined"
        color="primary"
        type="submit"
        sx={{
          m: 'auto',
          position: 'realtive',
          bottom: '20px',
          py: '15px',
          px: '16px',
          display: 'flex',
        }}
        disabled={loading}
      >
        {loading ? 'Carregando' : 'Salvar Alterações'}
      </Button>
    </Box>
  );
}

export default BasicInformatinForm;
