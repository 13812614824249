/* eslint-disable no-console */
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { store } from '@src/store';
import ApiClient from '@src/services/api/api';
import { useEffect, useState } from 'react';
import { MenuItem } from '@mui/material';

const customer_retention_options = [
  { value: 'not_tested', label: 'Não foi testada' },
  { value: 'low_retention', label: 'Baixa retenção' },
  { value: 'average_retention', label: 'Média retenção' },
  { value: 'high_retention', label: 'Alta retenção' },
];
const partners_relationship_options = [
  { value: 'no_partners', label: 'Sem parceiros' },
  { value: 'we_identify', label: 'Nós identificamos e contatamos' },
  { value: 'informal_partners', label: 'Temos parcerias informais' },
  { value: 'assigned_contracts', label: 'Temos contrato assinado' },
];
const competition_level_options = [
  { value: 'low', label: 'Baixo' },
  { value: 'average', label: 'Médio' },
  { value: 'high', label: 'Alto' },
];

const schema = yup.object({
  addressable_market: yup.string('campo vazio').required('Campo obrigatório'),
  growth_expectation: yup.number().required('Campo obrigatório'),
  mainly_competitors: yup.string().required('Campo obrigatório'),
  quality_competition: yup.string().required('Campo obrigatório'),
  partners_relationship: yup.string().required('Campo obrigatório'),
  geography_actuation: yup.string().required('Campo obrigatório'),
  competition_level: yup.string().required('Campo obrigatório'),
  customer_retention: yup.string().required('Campo obrigatório'),
  number_customers: yup.number().required('Campo obrigatório'),
});

function MarketAndCompetitionForm() {
  const { user } = store.getState().auth;

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      Object.assign(data, { id: user.startup_id });
      const response = await ApiClient.updateStartupCompletRegistration(data);
      if (response.data.success === false) {
        alert(`Falha: ${response.response.data.message}`);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(`Falha: ${error.response.data.message}`);
    }
  };

  useEffect(() => {
    setLoading(true);
    ApiClient.fetchStartupGeneralData(user.startup_id)
      .then((response) => {
        reset({
          addressable_market: response.data.startupdata[0].addressable_market,
          growth_expectation: response.data.startupdata[0].growth_expectation,
          mainly_competitors: response.data.startupdata[0].mainly_competitors,
          quality_competition: response.data.startupdata[0].quality_competition,
          partners_relationship:
            response.data.startupdata[0].partners_relationship,
          geography_actuation: response.data.startupdata[0].geography_actuation,
          competition_level: response.data.startupdata[0].competition_level,
          customer_retention: response.data.startupdata[0].customer_retention,
          number_customers: response.data.startupdata[0].number_customers,
        });
      })
      .catch((error) => {
        alert(`Falha: ${error.response.data.message}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [reset, user.startup_id]);

  return (
    <Box
      sx={{ mt: 8, px: 4 }}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box>
        <Stack direction="row" spacing={3} sx={{ mt: 2 }}>
          <Controller
            name="addressable_market"
            defaultValue=""
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                margin="none"
                sx={{ mb: 5 }}
                fullWidth
                // Label Correction
                label="Mercado Endereçável (descreva seu público alvo)"
                autoFocus
                color="primary"
                error={!!errors.addressable_market}
                helperText={errors?.addressable_market?.message}
              />
            )}
          />
          <Controller
            name="growth_expectation"
            defaultValue=""
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                margin="none"
                sx={{ mb: 5 }}
                fullWidth
                label="Expectativa de crescimento do mercado (%)"
                autoFocus
                color="primary"
                error={!!errors.growth_expectation}
                helperText={errors?.growth_expectation?.message}
                type="number"
              />
            )}
          />
        </Stack>

        <Stack direction="row" spacing={3} sx={{ mt: 2 }}>
          <Controller
            name="mainly_competitors"
            defaultValue=""
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                margin="none"
                sx={{ mb: 5 }}
                fullWidth
                label="Principais concorrentes"
                autoFocus
                color="primary"
                error={!!errors.mainly_competitors}
                helperText={errors?.mainly_competitors?.message}
              />
            )}
          />
          <Controller
            name="quality_competition"
            defaultValue=""
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                margin="none"
                sx={{ mb: 5 }}
                fullWidth
                label="Qualidade da Concorrência"
                autoFocus
                color="primary"
                error={!!errors.quality_competition}
                helperText={errors?.quality_competition?.message}
              />
            )}
          />
        </Stack>

        <Stack direction="row" spacing={3} sx={{ mt: 2 }}>
          <Controller
            name="partners_relationship"
            defaultValue=""
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                margin="none"
                sx={{ mb: 5 }}
                fullWidth
                label="Relacionamento com parceiros estratégicos"
                autoFocus
                select
                color="primary"
                error={!!errors.partners_relationship}
                helperText={errors?.partners_relationship?.message}
              >
                {partners_relationship_options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <Controller
            name="geography_actuation"
            defaultValue=""
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                margin="none"
                sx={{ mb: 5 }}
                fullWidth
                label="Geografia da atuação"
                autoFocus
                color="primary"
                error={!!errors.geography_actuation}
                helperText={errors?.geography_actuation?.message}
              />
            )}
          />
        </Stack>
        <Stack direction="row" spacing={3} sx={{ mt: 2 }}>
          <Controller
            name="customer_retention"
            defaultValue=""
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                margin="none"
                sx={{ mb: 5 }}
                fullWidth
                label="Retenção de clientes"
                autoFocus
                select
                color="primary"
                error={!!errors.customer_retention}
                helperText={errors?.customer_retention?.message}
              >
                {customer_retention_options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <Controller
            name="number_customers"
            defaultValue=""
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                margin="none"
                sx={{ mb: 5 }}
                fullWidth
                label="Número de clientes"
                autoFocus
                type="number"
                color="primary"
                error={!!errors.number_customers}
                helperText={errors?.number_customers?.message}
              />
            )}
          />
        </Stack>

        <Stack direction="row" spacing={3} sx={{ mt: 2 }}>
          <Controller
            name="competition_level"
            defaultValue=""
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                margin="none"
                sx={{ mb: 5 }}
                fullWidth
                label="Nível de competição no seu mercado"
                autoFocus
                select
                color="primary"
                error={!!errors.competition_level}
                helperText={errors?.competition_level?.message}
              >
                {competition_level_options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Stack>
      </Box>

      <Button
        variant="outlined"
        color="primary"
        type="submit"
        sx={{
          m: 'auto',
          position: 'realtive',
          mb: '70px',
          py: '15px',
          px: '16px',
          display: 'flex',
        }}
        disabled={loading}
      >
        {loading ? 'Carregando' : 'Salvar Alterações'}
      </Button>
    </Box>
  );
}

export default MarketAndCompetitionForm;
