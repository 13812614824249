import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';

export const Container = styled(Grid)`
  justify-content: space-around;
  align-items: flex-start;
  padding-top: 15vh;
  padding-left: 12vh;
`;

export const ItemLeft = styled(Grid)``;

export const CardInformation = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  background: #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
  border-radius: 9px;
  margin-bottom: 20px;
`;

export const ItemHeaderTitle = styled(Typography)`
  display: flex;
  justify-content: flex-start;
  font-weight: 400;
  color: #1a1a24;
  font-size: 20px;
  margin-top: 10px;
  padding-left: 3vh;
`;

export const ItemDivider = styled(Divider)`
  background: #2e2e38;
  width: 37px;
  height: 2px;
  margin-left: 3vh;
`;

export const ItemDividerInvestments = styled(ItemDivider)`
  margin-left: 0;
`;

export const ContainerItemInformation = styled(Box)`
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
  margin-top: 25px;
  margin-bottom: 20px;
  padding-left: 3vh;
`;

export const ContainerItemBussinesData = styled(ContainerItemInformation)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 25px;
  padding-right: 15px;
`;

export const ContainerItemFounders = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 25px;
  margin-bottom: 20px;
`;

export const ContentItemInformation = styled(Typography)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 400;
  color: ${(props) => props.color};
  font-size: 12px;
  text-decoration: ${(props) => (props.textDecoration ? 'underline' : 'none')};
  margin-top: 6px;
`;

export const ItemRight = styled(ItemLeft)`
  height: auto;
  background-color: #fff;
  margin-bottom: 5vh;
  margin-right: 1vh;
`;
