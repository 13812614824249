import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import toast from 'react-hot-toast';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import CloudDownloadIcon from '@mui/icons-material/CloudDownloadOutlined';
import DropFile from '@src/components/DropFile';

import Check from '@src/assets/images/check.svg';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #1A1A2424',
  borderRadius: '6px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: 4,
};

function ModalConclude({
  open,
  handleClose,
  onSubmit,
  loading,
  success,
  actionSuccess,
  termsLink,
}) {
  const [files, setFiles] = React.useState([]);

  const onRemoveFile = (index) => {
    setFiles(files.filter((_, key) => key !== index));
  };

  const onAddFile = (newElement) => {
    if (files.length !== 2) {
      setFiles((oldArray) => [...oldArray, newElement]);
    } else {
      toast.error(`Aviso: os dois arquivos já foram adicionados!`);
    }
  };

  useEffect(() => {
    setFiles([]);
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          ...style,
          width: {
            sm: '550px',
            md: '670px',
            xs: '340px',
          },
          alignItems: success ? 'center' : 'inherit',
        }}
      >
        {success ? (
          <>
            <img src={Check} alt="check" />
            <Typography
              sx={{
                fontSize: '22px',
                color: 'text.secondary',
                mb: 7,
                mt: 3,
                px: 2,
              }}
              textAlign="center"
            >
              Investimento concluído com sucesso!
            </Typography>
            <Button variant="outlined" onClick={actionSuccess}>
              VER TODAS AS PROPOSTAS
            </Button>
          </>
        ) : (
          <>
            <IconButton
              sx={{ position: 'absolute', top: '10px', right: '10px' }}
              onClick={handleClose}
            >
              <CloseIcon sx={{ backgruound: '#575762' }} />
            </IconButton>
            <Typography sx={{ fontSize: '22px', color: 'text.secondary' }}>
              Tem certeza que deseja concluir a proposta?
            </Typography>
            <Box ml={2}>
              <Typography
                sx={{ fontSize: '14px', color: 'text.secondary' }}
                mt={1.5}
                mb={3}
              >
                Aqui está o Termsheet desta proposta:
              </Typography>
              <a target="_blank" href={termsLink} download rel="noreferrer">
                <Button
                  variant="contained"
                  size="small"
                  color="neutral"
                  startIcon={<CloudDownloadIcon />}
                  sx={{
                    borderRadius: '9px',
                    textTransform: 'none',
                  }}
                >
                  Termsheet
                </Button>
              </a>
              <Typography sx={{ fontSize: '14px', color: '#575762' }} mt={4}>
                Para concluir essa proposta, você deve anexar os seguintes
                documentos:
              </Typography>
              <Typography sx={{ fontSize: '14px', color: '#575762' }}>
                1. Minuta de compra e venda de ações
              </Typography>
              <Typography sx={{ fontSize: '14px', color: '#575762' }} mb={2}>
                2. Acordo de acionistas assinado
              </Typography>
              <Grid container>
                <Grid item xs={12} sm={10} md={10}>
                  <DropFile
                    files={files}
                    onDelete={(index) => onRemoveFile(index)}
                    title="Adicionar Arquivos"
                    backgroundColor="#fff"
                    callback={(newElement) => onAddFile(newElement)}
                    subtitle="Arraste e solte arquivos"
                    multiple
                    accept={{ 'text/*': ['.pdf', '.doc'] }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Stack direction="row" spacing={4} mt={5} justifyContent="center">
              <Button variant="outlined" onClick={handleClose}>
                Cancelar
              </Button>
              <Button
                disabled={files.length !== 2}
                variant="contained"
                onClick={() => onSubmit(files)}
                sx={{ width: '180px' }}
              >
                {loading ? (
                  <CircularProgress
                    size={15}
                    sx={{
                      color: '#fff',
                    }}
                  />
                ) : (
                  'SIM, CONCLUIR'
                )}
              </Button>
            </Stack>
          </>
        )}
      </Box>
    </Modal>
  );
}

ModalConclude.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  success: PropTypes.bool,
  actionSuccess: PropTypes.func,
  termsLink: PropTypes.string,
};

ModalConclude.defaultProps = {
  loading: false,
  success: false,
  actionSuccess: () => {},
  termsLink: '',
};

export default ModalConclude;
