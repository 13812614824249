/* eslint-disable default-param-last */
import produce from 'immer';
import * as investorApprovalTypes from './types';

const investorsApprovalsFilter = (investors, type_id) =>
  investors?.filter((investor) => investor.investor_status_id === type_id);
const INITIAL_STATE = {
  loading: false,
  completePreRegistration: [],
  approved: [],
  removed: [],
};
export default function consultorInvestorApprovalReducer(
  state = INITIAL_STATE,
  action
) {
  return produce(state, (draft) => {
    switch (action.type) {
      case investorApprovalTypes.investorApprovalRequest: {
        draft.loading = true;
        break;
      }
      case investorApprovalTypes.investorApprovalSuccess: {
        draft.completePreRegistration = investorsApprovalsFilter(
          action.payload.data.investors,
          1
        );
        draft.approved = investorsApprovalsFilter(
          action.payload.data.investors,
          2
        );
        draft.removed = investorsApprovalsFilter(
          action.payload.data.investors,
          3
        );
        draft.loading = false;
        break;
      }
      case investorApprovalTypes.investorApprovalFailure: {
        draft.loading = false;
        break;
      }
      case investorApprovalTypes.investorApprovalReset: {
        draft.loading = false;
        draft.completePreRegistration = [];
        draft.approved = [];
        draft.removed = [];
        break;
      }
      case '@auth/SIGN_OUT': {
        draft.loading = false;
        draft.completePreRegistration = [];
        draft.approved = [];
        draft.removed = [];
        break;
      }
      default:
    }
  });
}
