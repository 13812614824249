import * as investorApprovalTypes from './types';

export function investorApprovalRequest() {
  return {
    type: investorApprovalTypes.investorApprovalRequest,
  };
}
export function investorApprovalSuccess(data) {
  return {
    type: investorApprovalTypes.investorApprovalSuccess,
    payload: { data },
  };
}
export function investorApprovalFailure() {
  return {
    type: investorApprovalTypes.investorApprovalFailure,
  };
}
export function investorApprovalReset() {
  return {
    type: investorApprovalTypes.investorApprovalReset,
  };
}

const consultorInvestorApprovalActions = {
  investorApprovalRequest,
  investorApprovalSuccess,
  investorApprovalFailure,
  investorApprovalReset,
};

export default consultorInvestorApprovalActions;
