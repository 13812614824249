import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { Typography } from '@mui/material';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,

  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
}));

export default function ProposalCounter({ values }) {
  return (
    <Box sx={{}}>
      <Box
        sx={{
          [`& .${linearProgressClasses.bar}`]: {
            backgroundColor: '#45A0D0',
          },
        }}
      >
        <Typography
          sx={{
            fontSize: '12px',
          }}
        >
          Pendentes
        </Typography>
        <Grid container>
          <Grid item xs={10} sm={10} md={10}>
            <BorderLinearProgress
              variant="determinate"
              value={values.pending}
            />
          </Grid>
          <Grid item xs={2} sm={2} md={2} justifyContent="right" display="flex">
            <Typography
              sx={{
                fontSize: '14px',
                color: '#000',
              }}
            >
              {values.pending}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box
        mt={1}
        sx={{
          [`& .${linearProgressClasses.bar}`]: {
            backgroundColor: '#D84B54',
          },
        }}
      >
        <Typography
          sx={{
            fontSize: '12px',
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          Investimento Conclúido
        </Typography>
        <Grid container>
          <Grid item xs={10} sm={10} md={10}>
            <BorderLinearProgress
              variant="determinate"
              value={values.completed}
            />
          </Grid>
          <Grid item xs={2} sm={2} md={2} justifyContent="right" display="flex">
            <Typography
              sx={{
                fontSize: '14px',
                color: '#000000',
              }}
            >
              {values.completed}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box
        mt={1}
        sx={{
          [`& .${linearProgressClasses.bar}`]: {
            backgroundColor: '#542EA5',
          },
        }}
      >
        <Typography
          sx={{
            fontSize: '12px',
            color: '#5B5B5B',
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          Aceitas
        </Typography>
        <Grid container>
          <Grid item xs={10} sm={10} md={10}>
            <BorderLinearProgress
              variant="determinate"
              value={values.accepted}
            />
          </Grid>
          <Grid item xs={2} sm={2} md={2} justifyContent="right" display="flex">
            <Typography
              sx={{
                fontSize: '14px',
                color: '#000000',
              }}
            >
              {values.accepted}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box
        mt={1}
        sx={{
          [`& .${linearProgressClasses.bar}`]: {
            backgroundColor: '#569F59',
          },
        }}
      >
        <Typography
          sx={{
            fontSize: '12px',
            color: '#5B5B5B',
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          Recusadas
        </Typography>
        <Grid container>
          <Grid item xs={10} sm={10} md={10}>
            <BorderLinearProgress
              variant="determinate"
              value={values.refused}
            />
          </Grid>
          <Grid item xs={2} sm={2} md={2} justifyContent="right" display="flex">
            <Typography
              sx={{
                fontSize: '14px',
                color: '#000000',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              {values.refused}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

ProposalCounter.propTypes = {
  values: PropTypes.shape({
    pending: PropTypes.number,
    accepted: PropTypes.number,
    completed: PropTypes.number,
    refused: PropTypes.number,
  }),
};

ProposalCounter.defaultProps = {
  values: {
    pending: 2,
    accepted: 14,
    completed: 21,
    refused: 30,
  },
};
