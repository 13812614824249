import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import Header from '../../../components/Header';

import { Wrapper } from './styles';

export default function AuthLayout({ children }) {
  const location = useLocation();
  return (
    <Wrapper>
      {!location.pathname.includes('login') && <Header />}
      {children}
    </Wrapper>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
