/* eslint-disable react/jsx-no-duplicate-props */
import { useState } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ApiClient from '@src/services/api/api';
import toast from 'react-hot-toast';
import TitleSection from '@src/components/LandingPage/TitleSection';

const schema = yup
  .object({
    name: yup.string().required('Campo obrigatório'),
    email: yup
      .string()
      .email('Email com formato inválido')
      .required('Campo obrigatório'),
    company: yup.string().required('Campo obrigatório'),
    message: yup.string().required('Campo obrigatório'),
  })
  .required();

function Contato() {
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      Object.assign(data, {
        name: data.name,
        email: data.email,
        company: data.company,
        message: data.message,
      });

      const response = await ApiClient.contactForm(data);
      if (response.data.success) {
        toast.success('Formulário enviado com sucesso');
        reset({ name: '', email: '', company: '', message: '' });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error('Falha ao conectar');
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Container
          maxWidth="xl"
          fixed
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            py: { xs: 5, sm: 10 },
          }}
        >
          <TitleSection
            TitleText="Ainda tem dúvidas?"
            SubtitleText="Fale conosco"
          />

          <Box
            sx={{
              width: { xs: '100%', lg: '90%' },
              display: 'grid',
              gridTemplateColumns: { xs: '1fr', md: 'repeat(2, 1fr)' },
              gridGap: '24px',
              gridTemplateAreas: {
                xs: ` 
              "nome"
              "empresa"
              "email"
              "mensagem"
              "botao"`,
                md: ` 
              "nome mensagem"
              "empresa mensagem"
              "email mensagem"
              ". botao"`,
              },
            }}
          >
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="nome"
                  label="Nome"
                  placeholder="Insira seu nome..."
                  color="primary"
                  sx={{ gridArea: 'nome' }}
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                />
              )}
            />

            <Controller
              name="company"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="empresa"
                  label="Empresa"
                  placeholder="Insira o nome da sua empresa..."
                  color="primary"
                  sx={{ gridArea: 'empresa' }}
                  error={!!errors.company}
                  helperText={errors?.company?.message}
                />
              )}
            />

            <Controller
              name="email"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  // margin="none"
                  label="Email"
                  id="email"
                  placeholder="Insira seu e-mail..."
                  color="primary"
                  sx={{ gridArea: 'email' }}
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                />
              )}
            />

            <Controller
              name="message"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  // margin="none"
                  label="Mensagem"
                  id="mensagem"
                  color="primary"
                  placeholder="Insira sua dúvida..."
                  multiline
                  rows={8}
                  sx={{ gridArea: 'mensagem' }}
                  error={!!errors.message}
                  helperText={errors?.message?.message}
                />
              )}
            />

            <Button
              type="submit"
              variant="contained"
              sx={{ gridArea: 'botao', justifySelf: 'end' }}
              disabled={loading}
            >
              {loading ? 'Enviando...' : 'Enviar mensagem'}
            </Button>
          </Box>
        </Container>
      </Box>
    </form>
  );
}

export default Contato;
