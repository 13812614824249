import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ErrorIcon from '@mui/icons-material/Error';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Capture from './Capture';

export default function SimpleAccordion() {
  return (
    <Grid
      container
      direction="column"
      sx={{
        mt: 2,
      }}
    >
      <Accordion
        sx={{
          position: 'relative',
          boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12)',
          borderRadius: '5px',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: '#000' }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <ErrorIcon
            sx={{
              color: '#C66900',
              mt: 1.8,
            }}
          />
          <Typography
            sx={{
              fontSize: 20,
              fontWeight: 700,
              color: '#575762',
              ml: 1.5,
              pb: 1.5,
              mt: 1.5,
            }}
          >
            Captação
          </Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          <Capture />
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
