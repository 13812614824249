import styled from 'styled-components';

const SubHeaderBreadchrumbs = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 42px;
  background: #fafafa;
  border-left: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
`;

const TitlePage = styled.h1`
  color: #1a1a24;
  font-size: 1.25rem;
  font-weight: 700;
`;

export { SubHeaderBreadchrumbs, TitlePage };
