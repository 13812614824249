import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import { StepAprovacao } from './style';

function AprovacaoCadastro() {
  return (
    <StepAprovacao>
      <AccessTimeIcon
        color="info"
        sx={{ width: 80, height: 80, mt: 17, mb: 4 }}
      />
      <Typography variant="h5" sx={{ mb: 4 }}>
        Cadastro em aprovação
      </Typography>
      <Typography sx={{ textAlign: 'center', mb: 10 }}>
        Sua conta será analisada pela nossa equipe e em breve te enviaremos um
        e-mail com informações referentes à sua conta.
      </Typography>
      <Link to="/">
        <Button variant="outlined">voltar para a página inicial</Button>
      </Link>
    </StepAprovacao>
  );
}

export default AprovacaoCadastro;
