import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Box } from '@mui/material';
import { numberFormat } from '@src/utils/functions';
import EyeIcon from '../EyeIcon';
import CardContent from '../CardContent';
import TotalChart from '../../../../../components/ReCharts/Total';

function CardTotal({ values, locked }) {
  const [data, setData] = useState([]);

  React.useEffect(() => {
    const arr = [];

    arr.push({ name: 'Total captured', value: values.captured });
    arr.push({ name: 'Total', value: values.total });

    setData(arr);
  }, [values]);

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      {locked ? <EyeIcon /> : null}
      <CardContent locked={locked}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
          mb={2}
        >
          <Typography
            sx={{
              color: '#1A1A24',
              fontSize: '14px',
              fontWeight: 800,
            }}
          >
            Total captado
          </Typography>
          <Typography
            sx={{
              color: '#B4B4B4',
              fontSize: '14px',
              fontWeight: 800,
            }}
          >
            Pré seed
          </Typography>
        </Box>
        <Grid container>
          <Grid
            item
            xs={7}
            sm={7}
            md={7}
            justifyContent="center"
            display="flex"
            alignItems="center"
          >
            <TotalChart data={data} />
          </Grid>
          <Grid
            item
            xs={5}
            sm={5}
            md={5}
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }} mb={2}>
              <Typography
                sx={{
                  color: '#1A1A24',
                  fontSize: '16px',
                  fontWeight: 400,
                }}
                mr="5px"
              >
                R$
              </Typography>
              <Typography
                sx={{
                  color: '#1A1A24',
                  fontSize: '18px',
                  fontWeight: 800,
                }}
              >
                {numberFormat(values.captured)}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  background: '#240D75',
                  width: '9px',
                  height: '9px',
                  borderRadius: '20px',
                }}
                mr={1}
              />
              <Typography
                sx={{
                  color: '#575762',
                  fontSize: '12px',
                  fontWeight: 400,
                }}
              >
                Valor captado
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
              mb={2}
            >
              <Typography
                sx={{
                  color: '#575762',
                  fontSize: '12px',
                  fontWeight: 400,
                }}
                mr="5px"
              >
                R$
              </Typography>
              <Typography
                sx={{
                  color: '#575762',
                  fontSize: '14px',
                  fontWeight: 800,
                }}
              >
                {numberFormat(values.captured)}
              </Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  background: '#E8E8E8',
                  width: '9px',
                  height: '9px',
                  borderRadius: '20px',
                }}
                mr={1}
              />
              <Typography
                sx={{
                  color: '#575762',
                  fontSize: '12px',
                  fontWeight: 400,
                }}
              >
                Valor total
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  color: '#575762',
                  fontSize: '12px',
                  fontWeight: 400,
                }}
                mr="5px"
              >
                R$
              </Typography>
              <Typography
                sx={{
                  color: '#575762',
                  fontSize: '14px',
                  fontWeight: 800,
                }}
              >
                {numberFormat(values.total)}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Box>
  );
}

CardTotal.propTypes = {
  locked: PropTypes.bool,
  values: PropTypes.shape({
    total: PropTypes.number,
    captured: PropTypes.number,
  }),
};

CardTotal.defaultProps = {
  locked: false,
  values: {
    total: 1000000,
    captured: 300000,
  },
};

export default CardTotal;
