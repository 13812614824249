export function preRegisterUser(data) {
    return {
        type: '@user_register/PRE_REGISTER_USER',
        payload: data,
    };
}

export function preRegisterCompany(data) {
    return {
        type: '@user_register/PRE_REGISTER_COMPANY',
        payload: data,
    };
}

export function preRegisterPartner(data) {
    return {
        type: '@user_register/PRE_REGISTER_PARTNER',
        payload: data,
    };
}

export function preRegisterCompanyData(data) {
    return {
        type: '@user_register/PRE_REGISTER_COMPANY_DATA',
        payload: data,
    };
}

export function clearPreRegister() {
    return {
        type: '@user_register/PRE_REGISTER_CLEAR',
    };
}