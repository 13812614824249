/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { format } from 'date-fns/esm';
import Box from '@mui/material/Box';

import Chart from 'react-apexcharts';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Header from '@src/components/Header';
import Sidebar from '@src/components/Sidebar';
import ListProposalsInvestor from '@src/components/ListProposalsInvestor';
import SubHeader from '@src/components/SubHeader/sub-header-startup';
import { formatReal } from '@src/utils/functions';
import ApiClient from '@src/services/api/api';
import { ApprovalStartupsTypes } from '@src/libs/models/types';
import { ROLES } from '@src/libs/models/roles';
import { RoutesMap } from '@src/routes/maps';

import { Container, CircleColor } from './styles';

const options = {
  chart: {
    type: 'donut',
  },
  dataLabels: {
    enabled: false,
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
        },
        legend: {
          show: false,
        },
      },
    },
  ],
  fill: {
    colors: ['#496691', '#0E9CFF', '#6F3A76', '#C66900'],
  },
  legend: {
    show: false,
  },
  tooltip: {
    enabled: false,
  },
};

function InvestorProposals() {
  const { id } = useParams();
  const [data, setData] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchInvestor = useCallback(async () => {
    setLoading(true);
    ApiClient.fetchProposalsInvestor({
      investor_id: id,
    })
      .then((response) => {
        if (response.data.investor) {
          setData(response.data.investor);
        }
        setLoading(false);
      })
      .catch((error) => {
        toast.error(`Falha: ${error.response.data.message}`);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchInvestor();
  }, [fetchInvestor]);

  return (
    <>
      <Header />

      <Sidebar isConsultant />
      {data ? (
        <SubHeader
          enterprise={data}
          role={ROLES[3]}
          type={ApprovalStartupsTypes[7]}
          crumbText1="Aprovação de Investidores"
          crumbText2={data.fantasy_name}
          crumbRoute1={RoutesMap.CONSULTOR_INVESTOR_APPROVAL}
          showBreadcrumbs
        />
      ) : null}
      <Box
        sx={{
          width: 'calc(100% - 60px)',
          position: 'relative',
          top: '60px',
          left: '60px',
        }}
      >
        <Container>
          {loading ? (
            <Box justifyContent="center" display="flex" p={3}>
              <CircularProgress size={30} />
            </Box>
          ) : (
            <div>
              {data ? (
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={5}>
                    <Card sx={{ marginBottom: 3 }}>
                      <CardContent>
                        <Typography
                          fontSize="18px"
                          sx={{ color: 'text.secondary' }}
                          mb={2}
                        >
                          Percentual de propostas
                        </Typography>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={5} md={5}>
                            <Chart
                              options={options}
                              series={[
                                data?.pending_proposals,
                                data?.accepted_proposals,
                                data?.completed_proposals,
                                data?.rejected_proposals,
                              ]}
                              type="donut"
                              height={180}
                            />
                            <Typography
                              fontSize="14px"
                              sx={{ color: 'text.secondary' }}
                              textAlign="center"
                            >
                              {`Total: ${data.number_of_proposals} propostas`}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={7}
                            md={7}
                            justifyContent="center"
                            display="flex"
                            flexDirection="column"
                          >
                            <Stack direction="row" alignItems="center" mb={2}>
                              <CircleColor color="#496691" />
                              <Typography
                                fontSize="14px"
                                sx={{ color: 'text.secondary' }}
                                textAlign="center"
                                ml={2}
                              >
                                {`Propostas concluídas: ${data.pending_proposals}`}
                              </Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center" mb={2}>
                              <CircleColor color="#0E9CFF" />
                              <Typography
                                fontSize="14px"
                                sx={{ color: 'text.secondary' }}
                                textAlign="center"
                                ml={2}
                              >
                                {`Propostas aceitas: ${data.accepted_proposals}`}
                              </Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center" mb={2}>
                              <CircleColor color="#6F3A76" />
                              <Typography
                                fontSize="14px"
                                sx={{ color: 'text.secondary' }}
                                textAlign="center"
                                ml={2}
                              >
                                {`Propostas concluídas: ${data.completed_proposals}`}
                              </Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center">
                              <CircleColor color="#C66900" />
                              <Typography
                                fontSize="14px"
                                sx={{ color: 'text.secondary' }}
                                textAlign="center"
                                ml={2}
                              >
                                {`Propostas recusadas: ${data.rejected_proposals}`}
                              </Typography>
                            </Stack>
                          </Grid>
                        </Grid>
                        <Typography
                          fontSize="14px"
                          sx={{ color: 'text.secondary' }}
                          textAlign="right"
                          mt={1}
                        >
                          {`Membro desde ${
                            data?.created_at
                              ? format(new Date(data.created_at), 'dd/MM/yyyy')
                              : ''
                          } `}
                        </Typography>
                      </CardContent>
                    </Card>
                    <Card>
                      <CardContent>
                        <Typography
                          fontSize="18px"
                          sx={{ color: 'text.secondary' }}
                        >
                          Total Investido
                        </Typography>
                        <Box py={4}>
                          <Typography
                            sx={{
                              fontSize: '22px',
                              color: 'text.secondary',
                              textAlign: 'center',
                            }}
                          >
                            R$
                            <span style={{ fontSize: '32px' }}>
                              {` ${formatReal(data.total_invested)}`}
                            </span>
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12} sm={12} md={7}>
                    <ListProposalsInvestor
                      data={data?.transactions}
                      showLabel
                    />
                  </Grid>
                </Grid>
              ) : null}
            </div>
          )}
        </Container>
      </Box>
    </>
  );
}

export default InvestorProposals;
