import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import * as yup from 'yup';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import { store } from '@src/store';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import ApiClient from '@src/services/api/api';
import { MenuItem } from '@mui/material';
import NumberFormat from 'react-number-format';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const tax_regime_options = [
  { label: 'Simples', value: 'simples' },
  { label: 'Presumido', value: 'presumido' },
  { label: 'Real', value: 'real' },
];

const schema = yup.object().shape({
  dres: yup.array().of(
    yup.object().shape({
      year: yup.number().required("Campo obrigatório"),
      invoice: yup.string().required("Campo obrigatório"),
      tax_regime: yup.string().required("Campo obrigatório"),
      indirect_direct_taxes: yup.string().required("Campo obrigatório"),
      costs_expenses: yup.string().required("Campo obrigatório"),
      ebitide: yup.string().required("Campo obrigatório"),
      depreciation: yup.string().required("Campo obrigatório"),
      financial_expenses: yup.string().required("Campo obrigatório"),
      liquid_profit: yup.string().required("Campo obrigatório")
    })
  ),
});

function DREForm() {
  const { user } = store.getState().auth;
  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const {
    fields: dres,
    append,
    remove,
  } = useFieldArray({ name: 'dres', control });
  const [loading, setLoading] = useState(false);

  const handleAddMoreInputs = () => {
    append({
      year: '',
      invoice: '',
      tax_regime: '',
      indirect_direct_taxes: '',
      costs_expenses: '',
      ebitide: '',
      depreciation: '',
      financial_expenses: '',
      liquid_profit: '',
    });
  };

  const handleRemoveInputs = () => {
    remove(dres.length - 1);
  };

  const replaceToFloatNumber = (string) => {
    const [first, second] = string.split('R$');
    if(second) {
      return parseFloat(second.replace('.', '').replace(',', '.'));
    } 
    return parseFloat(first);
  }

  const onSubmit = async (data) => {
    if(!data?.dres?.length) return;
    setLoading(true);
    try {
      const {length} = data.dres;
      let signUpDresError = false;
      for (let i = 0; i <= length - 1; i += 1) {
        const dreData = {
          startup_id: user.startup_id,
          year: data.dres[i].year,
          invoice: replaceToFloatNumber(data.dres[i].invoice),
          indirect_direct_taxes: replaceToFloatNumber(data.dres[i].indirect_direct_taxes),
          costs_expenses: replaceToFloatNumber(data.dres[i].costs_expenses),
          ebitide: replaceToFloatNumber(data.dres[i].ebitide),
          depreciation: replaceToFloatNumber(data.dres[i].depreciation),
          financial_expenses: replaceToFloatNumber(data.dres[i].financial_expenses),
          liquid_profit: replaceToFloatNumber(data.dres[i].liquid_profit),
          tax_regime: data.dres[i].tax_regime,
        }
        // eslint-disable-next-line no-await-in-loop
        const response = await ApiClient.signUpDRE(dreData);
        if (response.data.success === false) {
          signUpDresError = true;
        }
      }
      if (signUpDresError) {
        alert(
          `Um ou mais dres podem não ter sido cadastrados, tente novamente`
        );
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      alert(`Falha ${err}`);
    }
  };

  return (
    <Box
      sx={{ mt: 8, px: 4 }}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Typography
        sx={{
          fontSize: '16px',
          width: '43vw',
          color: '#575762',
          fontWeight: 400,
          position: 'relative',
          bottom: '40px',
        }}
      >
        Insira as informações de DRE dos últimos 3 anos de atuação da sua
        empresa. Caso a sua empresa seja mais jovem do que o período indicado,
        insira as informações dos últimos anos de operação da sua empresa.
      </Typography>
      <Box>
        {dres.map((dre, index) => (
          <Box key={dre.id}>
            <Stack direction="row" spacing={3} sx={{ mt: 2 }}>
              <Controller
                name={`dres.${index}.year`}
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                     key={dre.id}
                    {...field}
                    {...register(`dres.${index}.year`)}
                    margin="none"
                    sx={{ mb: 5 }}
                    fullWidth
                    label="Ano"
                    autoFocus
                    color="primary"
                    type="number"
                    error={!!errors.dres?.[index]?.year}
                    helperText={errors.dres?.[index]?.year?.message}
                  />
                )}
              />
            </Stack>

            <Stack direction="row" spacing={3} sx={{ mt: 2 }}>
              <Controller
                name={`dres.${index}.invoice`}
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, name, value, ...field } }) => (
                  <NumberFormat
                    customInput={TextField}
                    decimalScale={2}
                    decimalSeparator=","
                    thousandSeparator="."
                    fixedDecimalScale
                    fullWidth
                    label="Faturamento"
                    prefix="R$"
                    name={name}
                    value={value}
                    onChange={onChange}
                    error={!!errors.dres?.[index]?.invoice}
                    helperText={errors.dres?.[index]?.invoice?.message}
                  >
                    <TextField
                      key={dre.id}
                      {...field}
                      {...register(`dres.${index}.invoice`)}
                      margin="none"
                      sx={{ mb: 5 }}
                      fullWidth
                      autoFocus
                      color="primary"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocalAtmIcon
                              sx={{
                                color: '#cccccc',
                                width: '40px',
                                height: '40px',
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </NumberFormat>
                )}
              />
              <Controller
                name={`dres.${index}.tax_regime`}
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                     key={dre.id}
                    {...field}
                    {...register(`dres.${index}.tax_regime`)}
                    margin="none"
                    sx={{ mb: 5 }}
                    fullWidth
                    label="Regime Tributário"
                    select
                    autoFocus
                    color="primary"
                    error={!!errors.dres?.[index]?.tax_regime}
                    helperText={errors.dres?.[index]?.tax_regime?.message}
                  >
                    {tax_regime_options.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Stack>

            <Stack direction="row" spacing={3} sx={{ mt: 2 }}>
              <Controller
                name={`dres.${index}.indirect_direct_taxes`}
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, name, value, ...field } }) => (
                  <NumberFormat
                    customInput={TextField}
                    decimalScale={2}
                    decimalSeparator=","
                    thousandSeparator="."
                    fixedDecimalScale
                    fullWidth
                    label="Impostos Indiretos e Diretos"
                    prefix="R$"
                    name={name}
                    value={value}
                    onChange={onChange}
                    error={!!errors.dres?.[index]?.indirect_direct_taxes}
                    helperText={errors.dres?.[index]?.indirect_direct_taxes?.message}
                  >
                    <TextField
                      key={dre.id}
                      {...field}
                      {...register(`dres.${index}.indirect_direct_taxes`)}
                      margin="none"
                      sx={{ mb: 5 }}
                      fullWidth
                      autoFocus
                      color="primary"InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocalAtmIcon
                              sx={{
                                color: '#cccccc',
                                width: '40px',
                                height: '40px',
                              }}
                              />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </NumberFormat>
                )}
              />
              <Controller
                name={`dres.${index}.costs_expenses`}
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, name, value, ...field } }) => (
                  <NumberFormat
                    customInput={TextField}
                    decimalScale={2}
                    decimalSeparator=","
                    thousandSeparator="."
                    fixedDecimalScale
                    fullWidth
                    label="Custos e Despesas"
                    prefix="R$"
                    name={name}
                    value={value}
                    onChange={onChange}
                    error={!!errors.dres?.[index]?.costs_expenses}
                    helperText={errors.dres?.[index]?.costs_expenses?.message}
                  > 
                    <TextField
                      key={dre.id}
                      {...field}
                      {...register(`dres.${index}.costs_expenses`)}
                      margin="none"
                      sx={{ mb: 5 }}
                      fullWidth
                      autoFocus
                      color="primary"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocalAtmIcon
                              sx={{
                                color: '#cccccc',
                                width: '40px',
                                height: '40px',
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </NumberFormat>
                )}
              />
            </Stack>

            <Stack direction="row" spacing={3} sx={{ mt: 2 }}>
              <Controller
                name={`dres.${index}.ebitide`}
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, name, value, ...field } }) => (
                  <NumberFormat
                    customInput={TextField}
                    decimalScale={2}
                    decimalSeparator=","
                    thousandSeparator="."
                    fixedDecimalScale
                    fullWidth
                    label="EBITIDA"
                    prefix="R$"
                    name={name}
                    value={value}
                    onChange={onChange}
                    error={!!errors.dres?.[index]?.ebitide}
                    helperText={errors.dres?.[index]?.ebitide?.message}
                  >
                    <TextField
                       key={dre.id}
                      {...field}
                      {...register(`dres.${index}.ebitide`)}
                      margin="none"
                      sx={{ mb: 5 }}
                      fullWidth
                      autoFocus
                      color="primary"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocalAtmIcon
                              sx={{
                                color: '#cccccc',
                                width: '40px',
                                height: '40px',
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </NumberFormat>
                )}
              />
              <Controller
                name={`dres.${index}.depreciation`}
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, name, value, ...field } }) => (
                  <NumberFormat
                    customInput={TextField}
                    decimalScale={2}
                    decimalSeparator=","
                    thousandSeparator="."
                    fixedDecimalScale
                    fullWidth
                    label="Depreciação"
                    prefix="R$"
                    name={name}
                    value={value}
                    onChange={onChange}
                    error={!!errors.dres?.[index]?.depreciation}
                    helperText={errors.dres?.[index]?.depreciation?.message}
                  >
                    <TextField
                      key={dre.id}
                      {...field}
                      {...register(`dres.${index}.depreciation`)}
                      margin="none"
                      sx={{ mb: 5 }}
                      fullWidth
                      label="Depreciação"
                      autoFocus
                      color="primary"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocalAtmIcon
                              sx={{
                                color: '#cccccc',
                                width: '40px',
                                height: '40px',
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </NumberFormat>
                )}
              />
            </Stack>

            <Stack direction="row" spacing={3} sx={{ mt: 2 }}>
              <Controller
                name={`dres.${index}.financial_expenses`}
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, name, value, ...field } }) => (
                  <NumberFormat
                    customInput={TextField}
                    decimalScale={2}
                    decimalSeparator=","
                    thousandSeparator="."
                    fixedDecimalScale
                    fullWidth
                    label="Despesas e Receitas Financeiras"
                    prefix="R$"
                    name={name}
                    value={value}
                    onChange={onChange}
                    error={!!errors.dres?.[index]?.financial_expenses}
                    helperText={errors.dres?.[index]?.financial_expenses?.message}
                  >
                    <TextField
                       key={dre.id}
                      {...field}
                      {...register(`dres.${index}.financial_expenses`)}
                      margin="none"
                      sx={{ mb: 5 }}
                      fullWidth
                      autoFocus
                      color="primary"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocalAtmIcon
                              sx={{
                                color: '#cccccc',
                                width: '40px',
                                height: '40px',
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </NumberFormat>
                )}
              />
              <Controller
                name={`dres.${index}.liquid_profit`}
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, name, value, ...field } }) => (
                  <NumberFormat
                    customInput={TextField}
                    decimalScale={2}
                    decimalSeparator=","
                    thousandSeparator="."
                    fixedDecimalScale
                    fullWidth
                    label="Lucro Líquido"
                    prefix="R$"
                    name={name}
                    value={value}
                    onChange={onChange}
                    error={!!errors.dres?.[index]?.liquid_profit}
                    helperText={errors.dres?.[index]?.liquid_profit?.message}
                  >
                    <TextField
                      key={dre.id}
                      {...field}
                      {...register(`dres.${index}.liquid_profit`)}
                      margin="none"
                      sx={{ mb: 5 }}
                      fullWidth
                      label="Lucro Líquido"
                      autoFocus
                      color="primary"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocalAtmIcon
                              sx={{
                                color: '#cccccc',
                                width: '40px',
                                height: '40px',
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </NumberFormat>
                )}
              />
            </Stack>
          </Box>
        ))}
        <Button
          variant="outlined"
          endIcon={<RemoveIcon />}
          onClick={handleRemoveInputs}
          sx={{
            mt: 2,
            mb: 5,
            ml: 'auto',
            mr: 2,
            py: '6px',
            px: '16px',
            fontWeight: 'bold',
          }}
          disabled={loading || dres.length < 2}
        >
          Remover ano
        </Button>
        <Button
          variant="outlined"
          endIcon={<AddIcon />}
          sx={{
            mt: 2,
            mb: 5,
            ml: 'auto',
            py: '6px',
            px: '16px',
            fontWeight: 'bold',
          }}
          onClick={handleAddMoreInputs}
          disabled={loading}
        >
          Adicionar ano
        </Button>
      </Box>
      <Button
        variant="outlined"
        color="primary"
        sx={{
          m: 'auto',
          mb: 10,
          position: 'realtive',
          top: '20px',
          display: 'flex',
        }}
        type="submit"
        disabled={loading}
      >
        {loading ? 'Carregando' : 'Salvar Alterações'}
      </Button>
    </Box>
  );
}

export default DREForm;
