/* eslint-disable no-console */
/* eslint-disable default-param-last */
import produce from 'immer';

const INITIAL_STATE = {
  signed: false,
  loading: false,
  roleActive: null,
  user: {
    id: null,
    user_type_id: null,
    user_status_id: null,
    user_consultant_id: null,
    email: null,
    full_name: null,
    phone: null,
    telephone: null,
    linkedin: null,
    cep: null,
    state: null,
    city: null,
    district: null,
    address: null,
    number: null,
    complement: null,
    country: null,
    createdAt: null,
    updatedAt: null,
  },
  token: null,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@auth/USER_ROLE': {
        draft.roleActive = action.payload.role;
        break;
      }
      case '@auth/SIGN_IN_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@auth/SIGN_IN_SUCCESS': {
        draft.token = action.payload.token;
        draft.user = action.payload.user;
        draft.signed = true;
        draft.loading = false;
        break;
      }
      case '@auth/SIGN_FAILURE': {
        draft.loading = false;
        break;
      }
      case '@auth/SIGN_OUT': {
        draft.token = null;
        draft.user = null;
        draft.signed = false;
        draft.loading = false;
        break;
      }
      default:
    }
  });
}
