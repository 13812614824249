import { useState } from 'react';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export default function SelectStage() {
  const stages = [
    {
      value: 'Option-1',
      label: 'Option 1',
    },
    {
      value: 'Option-2',
      label: 'Option 2',
    },
  ];

  const [stage, setStages] = useState('');

  const handleChange = (event) => {
    setStages(event.target.value);
  };

  return (
    <FormControl sx={{ minWidth: 100 }} size="small">
      <InputLabel htmlFor="stage">Estágio</InputLabel>
      <Select
        sx={{
          borderRadius: '20px',
        }}
        defaultValue=""
        id="stage"
        label="Estagio"
        value={stage}
        onChange={handleChange}
      >
        {stages.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
