/* eslint-disable default-param-last */
import produce from 'immer';

const INITIAL_STATE = {
  user: null,
  company: null,
  partner: null,
  companyData: null,
};

export default function user(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@user_register/PRE_REGISTER_USER': {
        draft.user = action.payload;
        break;
      }
      case '@user_register/PRE_REGISTER_COMPANY': {
        draft.company = action.payload;
        break;
      }
      case '@user_register/PRE_REGISTER_PARTNER': {
        draft.partner = action.payload;
        break;
      }
      case '@user_register/PRE_REGISTER_COMPANY_DATA': {
        draft.companyData = action.payload;
        break;
      }
      case '@user_register/PRE_REGISTER_CLEAR': {
        draft.user = null;
        draft.company = null;
        draft.partner = null;
        draft.companyData = null;
        break;
      }
      case '@auth/SIGN_OUT': {
        draft.user = null;
        draft.company = null;
        draft.partner = null;
        draft.companyData = null;
        break;
      }
      default:
    }
  });
}
