/* eslint-disable no-console */
import { takeLatest, call, put } from 'redux-saga/effects';

import api from '@src/services/api/api';
import * as investorApprovalTypes from './types';
import * as investorApprovalActions from './actions';

export function* investorApprovalRequest() {
  try {
    const response = yield call(api.getInvestors);
    yield put(investorApprovalActions.investorApprovalSuccess(response.data));
  } catch (err) {
    yield put(investorApprovalActions.investorApprovalFailure());
  }
}

export default function* watchConsultorInvestorApproval() {
  yield takeLatest(
    investorApprovalTypes.investorApprovalRequest,
    investorApprovalRequest
  );
}
