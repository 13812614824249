/* eslint-disable react/prop-types */

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { IconButton } from '@mui/material';

export default function FoundersCard({ dataFounders }) {
  return (
    <Box
      style={{
        width: '100%',
      }}
    >
      {dataFounders.map((founder, index) => (
        <Box
          key={founder.id}
          style={{
            width: '100%',
            backgroundColor: !index % 2 ? '#F1F1F3' : '#fff',
            padding: '0 20px 0 20px',
          }}
        >
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <Box>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
              >
                <PersonOutlineIcon />
                <Typography
                  sx={{
                    fontWeight: 800,
                    color: '#1A1A24',
                    fontSize: 16,
                    marginLeft: 1,
                  }}
                >
                  {founder.name}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: 300,
                    color: '#1A1A24',
                    fontSize: 15,
                    marginLeft: 4,
                  }}
                >
                  {founder.function_role}
                </Typography>
              </Box>
            </Box>
            <Box>
              <IconButton
                onClick={() => window.open(`mailto:${founder.email}`)}
              >
                <MailOutlineIcon
                  sx={{
                    width: '20px',
                    height: '20px',
                    color: '#000000',
                  }}
                />
              </IconButton>
              <IconButton
                onClick={() => {
                  alert(`Telefone: ${founder.phone}`);
                }}
              >
                <LocalPhoneOutlinedIcon
                  sx={{
                    width: '20px',
                    height: '20px',
                    color: '#000000',
                  }}
                />
              </IconButton>
              <IconButton
                onClick={() => window.open(founder.linkedin, '_blank')}
              >
                <LinkedInIcon
                  sx={{
                    width: '20px',
                    height: '20px',
                    color: '#000000',
                  }}
                />
              </IconButton>
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
}
