/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import ProposalStatusEnum from '@src/constants/enums/proposal-status.enum';
import ApiClient from '@src/services/api/api';
import Header from '@src/components/Header';
import Sidebar from '@src/components/Sidebar';
import CompanyProfile from '@src/components/CompanyProfile';
import Proposal from '@src/components/Proposal';
import ModalConfirm from '@src/components/Modal/ModalConfirm';

import { SubHeaderBreadchrumbs, TitlePage, Container } from './styles';

function PropostasRecebidas() {
  const { id, investor } = useParams();
  const profile = useSelector((state) => state.user.profile);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState(null);
  const [openAccept, setOpenAccept] = useState(false);
  const [openRefuse, setOpenRefuse] = useState(false);
  const handleOpenAccept = () => setOpenAccept(true);
  const handleCloseAccept = () => setOpenAccept(false);
  const handleOpenRefuse = () => setOpenRefuse(true);
  const handleCloseRefuse = () => setOpenRefuse(false);

  const handleBack = () => {
    navigate('/propostas');
  };

  const fetchProposal = useCallback(async () => {
    setLoading(true);
    ApiClient.fetchProposals({
      startup_id: profile.startup_id,
      id,
      investor_id: investor,
    })
      .then((response) => {
        if (
          response.data?.proposals.length &&
          response.data?.proposals.length === 2
        ) {
          setData({
            ...response.data?.proposals[0],
            ...response.data?.proposals[1],
          });
        } else {
          toast.error(`Falha: id inválido`);
        }
        setLoading(false);
      })
      .catch((error) => {
        toast.error(`Falha: ${error.response.data.message}`);
        setLoading(false);
      });
  }, [profile.startup_id]);

  const onChangeStatus = async (status) => {
    setLoadingSubmit(true);

    let type = ProposalStatusEnum.ACEITA;

    if (status === 'refused') {
      type = ProposalStatusEnum.RECUSADA;
    }

    ApiClient.approveOrRejectProposal({
      status_id: type,
      id,
    })
      .then((response) => {
        if (response.data) {
          setSuccess(true);
        }
        setLoadingSubmit(false);
      })
      .catch((error) => {
        toast.error(`Falha: ${error.response.data.message}`);
        setLoadingSubmit(false);
      });
  };

  useEffect(() => {
    fetchProposal();
  }, [fetchProposal]);

  return (
    <>
      <Header />
      <Sidebar isStartup />

      <Box
        sx={{
          width: 'calc(100% - 60px)',
          position: 'relative',
          top: '60px',
          left: '60px',
        }}
      >
        <SubHeaderBreadchrumbs>
          <Breadcrumbs
            aria-label="breadcrumb"
            sx={{ color: '#9999AB', fontSize: 12, mb: 1 }}
          >
            <Link underline="hover" color="inherit" href="/">
              Home
            </Link>
            <Link underline="hover" color="inherit" href="/propostas">
              Propostas
            </Link>
            <Link
              underline="hover"
              color="inherit"
              href="/propostas/recebidas"
              aria-current="page"
              sx={{ pointerEvents: 'none' }}
            >
              Proposta Recebida
            </Link>
          </Breadcrumbs>

          <TitlePage>Proposta Recebida</TitlePage>
        </SubHeaderBreadchrumbs>

        <Container>
          {loading ? (
            <Box justifyContent="center" display="flex" p={3}>
              <CircularProgress size={30} />
            </Box>
          ) : (
            <div>
              {data ? (
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={7}>
                    <CompanyProfile data={data} />
                  </Grid>

                  <Grid item xs={12} sm={12} md={5}>
                    <Proposal
                      data={data}
                      onClick={handleOpenAccept}
                      onClickRefuse={handleOpenRefuse}
                    />
                  </Grid>
                </Grid>
              ) : null}
            </div>
          )}
        </Container>
      </Box>
      <ModalConfirm
        open={openAccept}
        handleClose={handleCloseAccept}
        onSubmit={() => onChangeStatus('accept')}
        message="Tem certeza que deseja aceitar a proposta?"
        txtBtnSuccess="VER TODAS AS PROPOSTAS"
        msgSuccess="Cotas reservadas com sucesso!"
        actionSuccess={handleBack}
        loading={loadingSubmit}
        success={success}
      />
      <ModalConfirm
        open={openRefuse}
        handleClose={handleCloseRefuse}
        onSubmit={() => onChangeStatus('refused')}
        message="Tem certeza que deseja recusar a proposta?"
        txtBtnSuccess="VER TODAS AS PROPOSTAS"
        msgSuccess="Proposta recusada com sucesso!"
        actionSuccess={handleBack}
        success={success}
        loading={loadingSubmit}
      />
    </>
  );
}

export default PropostasRecebidas;
