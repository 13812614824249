import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import Header from '@src/components/Header';
import Sidebar from '@src/components/Sidebar';
import SubHeader from '@src/components/SubHeader';
import LastSeen from '@src/components/investor/LastSeen';
import Filter from '@src/components/common/Buttons/Filter';
import Stage from '@src/components/common/Select/stage';
import Sector from '@src/components/common/Select/sector';
import Invoicing from '@src/components/common/Select/invoicing';
import SelectOrderBy from '@src/components/common/Select/order-by';
import CardStartup from '@src/components/common/Cards/Startup';

import InvestitorHomeActions from './actions';

const Container = styled(Stack)`
  display: flex;
  flex-direction: column;
  padding: 32px 40px;
`;

function Home() {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch({
      type: InvestitorHomeActions.asyncFindLastSeenStartupsAction,
      userId: user.id,
    });
    dispatch({
      type: InvestitorHomeActions.asyncFindAllStartupsAction,
    });
  }, [dispatch, user]);

  const { lastSeenStartups, startups } = useSelector(
    (state) => state.investitorHome
  );

  const startupsFilter = startups.filter((startup) => startup.startup_status_id === 5);

  return (
    <>
      <Header />

      <Sidebar isInvestor />

      <Box
        sx={{
          width: 'calc(100% - 60px)',
          position: 'relative',
          top: '60px',
          left: '60px',
        }}
      >
        <SubHeader />

        <Container spacing={5}>
          {lastSeenStartups.length !== 0 ? (
            <LastSeen dataLastSeen={lastSeenStartups} />
          ) : null}

          <Box>
            <Typography
              sx={{
                fontSize: '1.250rem',
                color: 'text.secondary',
                mb: 3,
              }}
            >
              Todas as empresas
            </Typography>

            <Box>
              <Box
                sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}
              >
                <Stack direction="row" spacing={2} sx={{ flexWrap: 'wrap' }}>
                  <Filter />

                  <Stage />
                  <Sector />
                  <Invoicing />
                </Stack>

                <SelectOrderBy />
              </Box>

              <Grid container spacing={{ xs: '20px' }}>
                {startupsFilter.map((startup) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={startup.id}>
                    <CardStartup dataStartup={startup} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default Home;
