/* eslint-disable no-console */

import api from '@src/services/api/api';
import toast from 'react-hot-toast';
import StartupProposalActions from './actions';

const { call, takeLatest } = require('redux-saga/effects');

function* RegisterProposal({ investorId, startupId, quotas, file }) {
  try {
    const dataFile = new FormData();

    dataFile.append('file', file, file.name);

    const responseDataStartupFile = yield call(
      api.postStartupProposalGenerateUrlNameFile,
      {
        startupId,
        dataFile,
      }
    );

    if (responseDataStartupFile) {
      const { data } = responseDataStartupFile;

      if (data.file_name !== '') {
        try {
          const responseDataRegisterProposal = yield call(
            api.postStartupDetailsInvestorRegisterProposal,
            {
              investorId,
              startupId,
              quotas,
              fileName: data.file_name,
            }
          );

          if (responseDataRegisterProposal) {
            const { data: dataProposal } = responseDataRegisterProposal;

            if (dataProposal.success) {
              toast.success('Proposta enviada!');
            } else {
              toast.error(dataProposal.message);
            }
          } else {
            toast.error(
              'Arquivo salvo mas houve um erro ao enviar a proposta!'
            );
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        toast.error('Arquivo salvo mas a proposta não foi enviada!');
      }
    } else {
      toast.error('Arquivo não foi salvo, erro ao enviar proposta!');
    }
  } catch (error) {
    console.log(error);
  }
}

export default [
  takeLatest(StartupProposalActions.asyncRegisterProposal, RegisterProposal),
];
