import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import ApiClient from '@src/services/api/api';
import { useDispatch } from 'react-redux';
import { preRegisterUser } from '@src/store/modules/preRegister/actions';
import Header from '../../../components/Header/OnboardingHeader';
import CadastroBasico from '../../../components/Forms/Startup/CadastroBasico';
import CadastroEmpresa from '../../../components/Forms/Startup/CadastroEmpresa';
import DadosSocios from '../../../components/Forms/Startup/DadosSocios';
import DadosEmpresa from '../../../components/Forms/Startup/DadosEmpresa';
import AprovacaoCadastro from '../../../components/AprovacaoCadastro';

const steps = [
  'Cadastro Básico',
  'Cadastre sua Empresa',
  'Dados dos Sócios',
  'Dados da Empresa',
  'Aprovação',
];


export default function HorizontalLinearStepper() {
  const queryParams = (new URL(document.location)).searchParams;
  
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const cod = queryParams.get("cod");
    const email = queryParams.get("email");

    if(cod && email) {
      setLoading(true);
      ApiClient.verifyEmailConfirmation(cod, email).then(response => {
        const { token, user } = response.data;

        if(token) {
          setActiveStep(1);
          dispatch(preRegisterUser({user, startup: response.data?.startup}))
        }
      }).catch(() => {
        alert('Falha na verificação de email');
        setActiveStep(0);
      }).finally(() => setLoading(false));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <CadastroBasico />;
      case 1:
        return <CadastroEmpresa setActiveStep={setActiveStep} />;
      case 2:
        return <DadosSocios setActiveStep={setActiveStep} />;
      case 3:
        return <DadosEmpresa setActiveStep={setActiveStep} />;
      case 4:
        return <AprovacaoCadastro />;
      default:
        return 'unknown step';
    }
  }

  if(loading) {
    // SHOULD HANDLE A LOADING HERE
    return <div />
  }

  return (
    <>
      <Header />

      <Box>
        <Stepper activeStep={activeStep} sx={{ px: 5, py: 4, bgcolor: '#fff' }}>
          {steps.map((label) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel style={{ color: 'red' }} {...labelProps}>
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {getStepContent(activeStep)}
      </Box>
    </>
  );
}
