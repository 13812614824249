/* eslint-disable react/no-array-index-key */
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import { linkedinIcon } from '@src/constants/imgs';

function ListSocios({ data }) {
  return (
    <>
      <Typography sx={{ color: 'text.secondary', pl: 3, mb: 2 }}>
        Sócios
      </Typography>
      {data && data.length ? (
        <>
          {data.map((item, index) => (
            <Box
              sx={{
                bgcolor: index % 2 === 1 ? '#FFFFFF' : '#F1F1F3',
                display: 'flex',
                p: 2,
              }}
              key={index}
            >
              <PersonOutlineIcon sx={{ color: '#000' }} />
              <Typography
                sx={{
                  fontSize: '14px',
                  color: '#515162',
                  ml: '8px',
                  mr: 'auto',
                }}
              >
                <span
                  style={{
                    display: 'block',
                    fontSize: '16px',
                    color: 'text.secondary',
                  }}
                >
                  {item.name}
                </span>
                {item.function_role}
              </Typography>
              <IconButton aria-label="MailOutlineIcon" sx={{ width: '45px' }}>
                <a
                  href={`mailto:${item.email}`}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    color: '#000',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <MailOutlineIcon sx={{ width: '20px' }} />
                </a>
              </IconButton>
              <IconButton
                aria-label="LocalPhoneOutlinedIcon"
                sx={{ width: '45px' }}
              >
                <a
                  href={`tel:${item.phone}`}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    color: '#000',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <LocalPhoneOutlinedIcon sx={{ width: '20px' }} />
                </a>
              </IconButton>
              <IconButton aria-label="LinkedInIcon" sx={{ width: '45px' }}>
                <a
                  href={item.linkedin}
                  target="_blank"
                  style={{
                    color: '#000',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  rel="noopener noreferrer"
                >
                  <img src={linkedinIcon} alt="Icone Linkedin" width="16px" />
                </a>
              </IconButton>
            </Box>
          ))}
        </>
      ) : null}
    </>
  );
}

ListSocios.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ListSocios;
