/* eslint-disable react/prop-types */
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import InputMask from "react-input-mask";
import { store } from '@src/store';
import { preRegisterPartner } from '@src/store/modules/preRegister/actions';
import StartupStatusEnum from '@src/constants/enums/startup-status.enum';
import ApiClient from '@src/services/api/api';
import { unMask } from '@src/utils/functions';

const defaultErrorMessage = "Campo obrigatório"

function DadosSocios({ setActiveStep }) {
  const dispatch = useDispatch();
  const { partner: partnerStore, user } = store.getState().preRegister;

  const [partners, setPartners] = useState(structuredClone(partnerStore) ||
    [
      {
        name: '',
        function_role: '',
        email: '',
        phone: '',
        linkedin: '',
        investor_id: user?.startup?.id,
        name_error: false,
        function_role_error: false,
        email_error: false,
        phone_error: false,
      }
    ]
  );

  const handleDynamicInputsChange = (
    event,
    index
  ) => {
    const inputs = [...partners];
    inputs[index][event.target.id] = event.target.value;
    setPartners(inputs);
  };

  const handleAddMoreInputs = () => {
    const inputs = [...partners];
    inputs.push({
      name: '',
      function_role: '',
      email: '',
      phone: '',
      linkedin: '',
      investor_id: user?.startup?.id,
      name_error: false,
      function_role_error: false,
      email_error: false,
      phone_error: false,
    });
    setPartners(inputs);
  };

  const handleRemoveInputs = () => {
    const inputs = [...partners];
    if (inputs.length === 1) {
      return;
    }
    inputs.pop();
    setPartners(inputs);
  };


  const onSubmit = async (event) => {
    event.preventDefault();
    let error = false;
    const {length} = partners;
    const partnersData = [...partners];
    for(let i = 0; i <= length -1; i+=1) {
      if (!partnersData[i].name) {
        partnersData[i].name_error = true
        error = true
      } else {
        partnersData[i].name_error = false
      }
      if (!partnersData[i].function_role) {
        partnersData[i].function_role_error = true
        error = true
      } else {
        partnersData[i].function_role_error = false
      }
      if (!partnersData[i].email) {
        partnersData[i].email_error = true
        error = true
      } else {
        partnersData[i].email_error = false
      }
      if (!partnersData[i].phone) {
        partnersData[i].phone_error = true
        error = true
      } else {
        partnersData[i].phone_error = false
      }
    }

    let signUpPartnerError = false;

    for (let i = 0; i <= length - 1; i += 1) {
      const partnerData = {
        name: partnersData[i].name,
        function_role: partnersData[i].function_role,
        email: partnersData[i].email,
        phone: unMask(partnersData[i].phone),
        linkedin: partnersData[i].linkedin,
        investor_id: partnersData[i].investor_id,
      };
      // eslint-disable-next-line no-await-in-loop
      const response = await ApiClient.signUpInvestorPartner(partnerData);
      if (response.data.success === false) {
        signUpPartnerError = true;
      }
    }

    setPartners(partnersData)
    if(!error && !signUpPartnerError) {
      const data = {
        id: user.startup.id,
        investor_status_id: StartupStatusEnum.PRECADASTRO_COMPLETO
      }
      const response = await  ApiClient.updateInvestorCompany(data);

      if(response.data.success) {
        dispatch(preRegisterPartner(partnersData))
        setActiveStep(3)
      } else {
        alert('Falha no cadastro')
      }
    }
  }

  return (
    <Box sx={{ mt: 8, mb: 3, px: 4 }}>
      <form onSubmit={onSubmit}>
        <Box>
          <Typography variant="h5" sx={{ mb: 6, fontSize: 20, fontWeight: 400 }}>
            Dados dos Sócios
          </Typography>
          {partners.map((partner, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box key={index}>
              <Typography variant="h5" sx={{ fontSize: 14, fontWeight: 600 }}>
                {`${index + 1} - Sócio`}
              </Typography>
              <Stack direction="row" spacing={3} sx={{ mt: 2 }}>
                <TextField
                  id="name"
                  label="Nome do Sócio"
                  variant="outlined"
                  placeholder="Insira nome do Sócio"
                  margin="none"
                  name="name"
                  sx={{ minWidth: 440, mb: 5 }}
                  onChange={(event) => handleDynamicInputsChange(event, index)}
                  value={partner.name ? partner.name : ''}
                  error={partner.name_error}
                  helperText={partner.name_error ? defaultErrorMessage : undefined}
                />
                <TextField
                  id="function_role"
                  label="Função"
                  variant="outlined"
                  placeholder="Insira função"
                  margin="none"
                  name="funcao-socio"
                  sx={{ minWidth: 440, mb: 5 }}
                  onChange={(event) => handleDynamicInputsChange(event, index)}
                  value={partner.function_role ? partner.function_role : ''}
                  error={partner.function_role_error}
                  helperText={partner.function_role_error ? defaultErrorMessage : undefined}
                />
              </Stack>

              <Stack direction="row" spacing={3} sx={{ mt: 2 }}>
                <TextField
                  id="email"
                  type="email"
                  label="E-mail"
                  variant="outlined"
                  placeholder="Insira seu e-mail"
                  margin="none"
                  name="emailSocio"
                  sx={{ minWidth: 440, mb: 5 }}
                  onChange={(event) => handleDynamicInputsChange(event, index)}
                  value={partner.email ? partner.email : ''}
                  error={partner.email_error}
                  helperText={partner.email_error ? defaultErrorMessage : undefined}
                />

                <InputMask
                  mask="(99) 99999-9999"
                  value={partner.phone ? partner.phone : ''}
                  onChange={(event) => handleDynamicInputsChange(event, index)}
                
                >
                <TextField
                  id="phone"
                  label="Telefone"
                  variant="outlined"
                  placeholder="Insira seu telefone"
                  margin="none"
                  name="TelefoneSocio"
                  sx={{ minWidth: 320, mb: 5 }}
                  error={partner.phone_error}
                  helperText={partner.phone_error ? defaultErrorMessage : undefined}
                />

                </InputMask>
           

              </Stack>

              <TextField
                id="linkedin"
                label="Linkedin"
                variant="outlined"
                placeholder="Insira seu Linkedin"
                margin="normal"
                name="linkedinSocio"
                sx={{ minWidth: 440, mr: '100%', mb: 5 }}
                onChange={(event) => handleDynamicInputsChange(event, index)}
                value={partner.linkedin ? partner.linkedin : ''}
              />
            </Box>
          ))}
          {partners.length > 1 && (
            <Button
              variant="outlined"
              endIcon={<RemoveIcon />}
              sx={{ mt: 2, mb: 5, mr: 3 }}
              onClick={handleRemoveInputs}
            >
              Remover sócio
            </Button>
          )}
          <Button
            variant="outlined"
            endIcon={<AddIcon />}
            sx={{ mt: 2, mb: 5 }}
            onClick={handleAddMoreInputs}
          >
            Adicionar mais um sócio
          </Button>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            mt: 7,
            mb: 12,
          }}
        >
          <Button variant="outlined" onClick={() => setActiveStep(1)} sx={{ mr: 4 }}>
            Voltar
          </Button>
          <Button type='submit' variant="contained" color="primary">
            Próximo
          </Button>
        </Box>
      </form>
    </Box>
  );
}

export default DadosSocios;
