import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import * as yup from 'yup';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import ApiClient from '@src/services/api/api';
import { store } from '@src/store';
import NumberFormat from 'react-number-format';

const schema = yup
  .object({
    capture_end_date: yup.string().required("Campo obrigatório"),
    round_value: yup.string().required("Campo obrigatório"),
    investment_thesis: yup.string().required("Campo obrigatório"),
    investment_differentials: yup.string().required("Campo obrigatório"),
    use_of_resources: yup.string().required("Campo obrigatório"),
    quantities_of_quotas: yup.number().required("Campo obrigatório"),
    sales_percentage: yup.number().required("Campo obrigatório")
  })

function BasicInformatinForm() {
  const { user } = store.getState().auth;

  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(schema),
  });

  const replaceToFloatNumber = (string) => {
    const [first, second] = string.split('R$');
    if(second) {
      return parseFloat(second.replace('.', '').replace(',', '.'));
    } 
    return parseFloat(first);
  }

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      Object.assign(data, { 
        id: user.startup_id,
        round_value: replaceToFloatNumber(data.round_value),
      })
      const response = await ApiClient.updateStartupCompletRegistration(data)
      if(response.data.success === false) {
        alert(`Falha: ${response.response.data.message}`)
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(`Falha: ${error.response.data.message}`)
    }
  }

  useEffect(() => {
    setLoading(true);
    ApiClient.fetchStartupGeneralData(user.startup_id).then(response => {
      reset(
        {
          capture_end_date: response.data.startupdata[0].capture_end_date,
          round_value: response.data.startupdata[0].round_value,
          investment_thesis: response.data.startupdata[0].investment_thesis,
          investment_differentials: response.data.startupdata[0].investment_differentials,
          use_of_resources: response.data.startupdata[0].use_of_resources,
          quantities_of_quotas: response.data.startupdata[0].quantities_of_quotas,
          sales_percentage: response.data.startupdata[0].sales_percentage,
        }
      )
    }).catch(error => {
      alert(`Falha: ${error.response.data.message}`)
    }).finally(() => {
      setLoading(false)
    })
  },[reset, user.startup_id]);

  return (
    <Box sx={{ mt: 8, px: 4 }} component="form" onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Stack direction="row" spacing={3} sx={{ mt: 2 }}>
          <Controller
            name="capture_end_date"
            defaultValue=""
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                margin="none"
                sx={{ mb: 5 }}
                fullWidth
                label="Término da Captação"
                autoFocus
                type="date"
                color="primary"
                error={!!errors.capture_end_date}
                helperText={errors?.capture_end_date?.message}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
          <Controller
            name="round_value"
            defaultValue=""
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, name, value, ...field } }) => (
              <NumberFormat
                customInput={TextField}
                decimalScale={2}
                decimalSeparator=","
                thousandSeparator="."
                fixedDecimalScale
                fullWidth
                label="Valor da rodada"
                prefix="R$"
                name={name}
                value={value}
                onChange={onChange}
                error={!!errors.round_value}
                helperText={errors?.round_value?.message}
              >
                <TextField
                  {...field}
                  margin="none"
                  sx={{ mb: 5 }}
                  fullWidth
                  autoFocus
                  color="primary"
                />
              </NumberFormat>
            )}
          />
        </Stack>

        <Stack direction="row" spacing={3} sx={{ mt: 2 }}>
          <Controller
            name="investment_thesis"
            defaultValue=""
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                margin="none"
                sx={{ mb: 5 }}
                fullWidth
                // Label Correction
                label="Objetivo do Investimento"
                autoFocus
                color="primary"
                multiline
                rows={3}
                error={!!errors.investment_thesis}
                helperText={errors?.investment_thesis?.message}
              />
            )}
          />
          <Controller
            name="investment_differentials"
            defaultValue=""
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                margin="none"
                sx={{ mb: 5 }}
                fullWidth
                // Label Correction
                label="Atrativos para o Investidor"
                autoFocus
                color="primary"
                multiline
                rows={3}
                error={!!errors.investment_differentials}
                helperText={errors?.investment_differentials?.message}
              />
            )}
          />
        </Stack>

        <Stack direction="row" spacing={3} sx={{ mt: 2 }}>
          <Controller
            name="use_of_resources"
            defaultValue=""
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                margin="none"
                sx={{ mb: 5 }}
                fullWidth
                label="Uso de Recursos"
                autoFocus
                color="primary"
                multiline
                rows={3}
                error={!!errors.use_of_resources}
                helperText={errors?.use_of_resources?.message}
              />
            )}
          />
        </Stack>

        <Stack direction="row" spacing={3} sx={{ mt: 2 }}>
          <Controller
            name="quantities_of_quotas"
            defaultValue=""
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                margin="none"
                sx={{ mb: 5 }}
                fullWidth
                label="Número de cotas"
                type="number"
                autoFocus
                color="primary"
                error={!!errors.quantities_of_quotas}
                helperText={errors?.quantities_of_quotas?.message}
              />
            )}
          />
          <Controller
            name="sales_percentage"
            defaultValue=""
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                margin="none"
                sx={{ mb: 5 }}
                fullWidth
                label="Percentual de vendas (%)"
                type="number"
                autoFocus
                color="primary"
                error={!!errors.sales_percentage}
                helperText={errors?.sales_percentage?.message}
              />
            )}
          />
        </Stack>
      </Box>

      <Button
        variant="outlined"
        color="primary"
        sx={{
          m: 'auto',
          mb: 10,
          position: 'realtive',
          top: '20px',
          py: '15px',
          px: '16px',
          display: 'flex',
        }}
        type="submit"
        disabled={loading}
      >
        {loading ? 'Carregando' : 'Salvar Alterações'}
      </Button>
    </Box>
  );
}

export default BasicInformatinForm;
