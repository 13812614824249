export const enterpriseDetailRequest = '@enterprise/ENTERPRISE_DETAIL_REQUEST';
export const enterpriseDetailSuccess = '@enterprise/ENTERPRISE_DETAIL_SUCCESS';
export const enterpriseDetailFailure = '@enterprise/ENTERPRISE_DETAIL_FAILURE';
export const enterpriseApprovalRequest =
  '@enterprise/ENTERPRISE_APPROVAL_REQUEST';
export const enterpriseApprovalSuccess =
  '@enterprise/ENTERPRISE_APPROVAL_SUCCESS';
export const enterpriseApprovalFailure =
  '@enterprise/ENTERPRISE_APPROVAL_FAILURE';
export const enterpriseRemoveRequest = '@enterprise/ENTERPRISE_REMOVE_REQUEST';
export const enterpriseRemoveSuccess = '@enterprise/ENTERPRISE_REMOVE_SUCCESS';
export const enterpriseRemoveFailure = '@enterprise/ENTERPRISE_REMOVE_FAILURE';
export const enterpriseReset = '@enterprise/ENTERPRISE_RESET';

const enterpriseDetailTypes = {
  enterpriseDetailRequest,
  enterpriseDetailSuccess,
  enterpriseDetailFailure,
  enterpriseApprovalRequest,
  enterpriseApprovalSuccess,
  enterpriseApprovalFailure,
  enterpriseRemoveRequest,
  enterpriseRemoveSuccess,
  enterpriseRemoveFailure,
  enterpriseReset,
};

export default enterpriseDetailTypes;
