import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ErrorIcon from '@mui/icons-material/Error';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import DREForm from './DREForm';
import BalanceForm from './BalanceForm';
import UploadDocuments from './UploadDocuments';

export default function SimpleAccordion() {
  return (
    <Grid
      container
      direction="column"
      gap={5}
      sx={{
        mt: 2,
      }}
    >
      <Accordion
        sx={{
          position: 'relative',
          borderRadius: '5px',
          boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12)',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: '#000' }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <ErrorIcon
            sx={{
              color: '#C66900',
              mt: 1.8,
            }}
          />
          <Typography
            sx={{
              fontSize: 20,
              fontWeight: 700,
              color: '#575762',
              ml: 1.5,
              pb: 1.5,
              mt: 1.5,
            }}
          >
            DRE
          </Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          <DREForm />
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{
          position: 'relative',
          borderRadius: '5px',
          boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12)',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: '#000' }} />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <ErrorIcon sx={{ color: '#C66900', mt: 1.8 }} />
          <Typography
            sx={{
              fontSize: 20,
              fontWeight: 700,
              color: '#575762',

              ml: 1.5,
              pb: 1.5,
              mt: 1.5,
            }}
          >
            Balanço
          </Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          <BalanceForm />
        </AccordionDetails>
      </Accordion>

      <Accordion
        sx={{
          position: 'relative',
          borderRadius: '5px',
          boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12)',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: '#000' }} />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <ErrorIcon sx={{ color: '#C66900', mt: 1.8 }} />
          <Typography
            sx={{
              fontSize: 20,
              fontWeight: 700,
              color: '#575762',
              ml: 1.5,
              pb: 1.5,
              mt: 1.5,
            }}
          >
            Documentos
          </Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          <UploadDocuments />
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
