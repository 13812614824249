import { useState, useEffect } from 'react';
import { Box, Tabs, Tab /* , Button */ } from '@mui/material';
// import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';

import Header from '@src/components/Header';
import SubHeader from '@src/components/SubHeader/sub-header-consultant';
import Sidebar from '@src/components/Sidebar';
import EnterpriseList from '@src/components/EnterpriseList';
import { ApprovalStartupsTypes, JourneyTypes } from '@src/libs/models/types';
import TabPanel from '@src/components/TabPanel';
import { a11yProps } from '@src/utils/functions';
import * as startupApprovalActions from '@src/store/modules/consultorStartupApproval/actions';
import * as startupApprovalSelectors from '@src/store/modules/consultorStartupApproval/selectors';
import { Container } from './styles';

export default function Transactions() {
  const [value, setValue] = useState(0);
  const loading = useSelector(startupApprovalSelectors.getLoading);
  const preRegistration = useSelector(
    startupApprovalSelectors.getCompeltePreRegistrationStartups
  );
  const pendingRegistration = useSelector(
    startupApprovalSelectors.getPendingRegistrationStartups
  );
  const completeRegistration = useSelector(
    startupApprovalSelectors.getCompleteRegistrationStartups
  );
  const approved = useSelector(startupApprovalSelectors.getApprovedStartups);
  const signedContract = useSelector(
    startupApprovalSelectors.getSignedContractStartups
  );
  const removed = useSelector(startupApprovalSelectors.getRemovedStartups);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(startupApprovalActions.startupApprovalRequest());
  }, [dispatch]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Header />

      <Sidebar isConsultant />

      <Box
        sx={{
          width: 'calc(100% - 60px)',
          position: 'relative',
          top: '60px',
          left: '60px',
        }}
      >
        <SubHeader content="Aprovação de Startups" />
        <Container spacing={5}>
          <Box
            sx={{
              borderBottom: 1,

              borderColor: 'divider',
              position: 'relative',
              bottom: '30px',
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="aprovação-de-startups"
            >
              <Tab label="Pré-cadastro Completo" {...a11yProps(0)} />
              <Tab label="Cadastro Pendente" {...a11yProps(1)} />
              <Tab label="Cadastro Completo" {...a11yProps(2)} />
              <Tab label="Aprovadas" {...a11yProps(3)} />
              <Tab label="Contrato assinado" {...a11yProps(4)} />
              <Tab label="Removidas" {...a11yProps(5)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <EnterpriseList
              loading={loading}
              registrationType={ApprovalStartupsTypes[1]}
              enterprises={preRegistration}
              journey={JourneyTypes[1]}
            />
          </TabPanel>

          <TabPanel value={value} index={1}>
            <EnterpriseList
              loading={loading}
              registrationType={ApprovalStartupsTypes[2]}
              enterprises={pendingRegistration}
              journey={JourneyTypes[1]}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <EnterpriseList
              loading={loading}
              registrationType={ApprovalStartupsTypes[3]}
              enterprises={completeRegistration}
              journey={JourneyTypes[1]}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <EnterpriseList
              loading={loading}
              registrationType={ApprovalStartupsTypes[4]}
              enterprises={approved}
              journey={JourneyTypes[1]}
            />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <EnterpriseList
              loading={loading}
              registrationType={ApprovalStartupsTypes[5]}
              enterprises={signedContract}
              journey={JourneyTypes[1]}
            />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <EnterpriseList
              loading={loading}
              registrationType={ApprovalStartupsTypes[6]}
              enterprises={removed}
              journey={JourneyTypes[1]}
            />
          </TabPanel>

          {/* <Box sx={{ display: 'flex' }}>
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              sx={{
                display: 'flex',
                position: 'relative',
                top: '15px',
                mx: 'auto',
                fontWeight: 700,
                fontSize: '14px',
              }}
            >
              <AddIcon />
              ver mais
            </Button>
          </Box> */}
        </Container>
      </Box>
    </>
  );
}
