/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useState } from 'react';

import ApiClient from '@src/services/api/api';
import { store } from '@src/store';
import StartupRegistrationStepsEnum from '@src/constants/enums/registration-steps.enum';
import { useNavigate } from 'react-router-dom';
import { setStep } from '@src/store/modules/startup/actions';
import { useDispatch } from 'react-redux';
import Header from '../../../../components/Header';
import Sidebar from '../../../../components/Sidebar';
import ProgressBar from '../../../../components/ProgressBar/CompleteRegistrationProgress';
import DrowpDownStartupStep3 from '../../../../components/DropDown/InvestmentForm';

import { Container, SubHeaderBreadchrumbs, TitlePage } from './styles';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function CompleteRegistration() {
  const { user } = store.getState().auth;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    try {
      setLoading(true);
      const data = { 
        id: user.startup_id, 
        registration_step_id: StartupRegistrationStepsEnum.INVESTIMENTO 
      }
      const response = await ApiClient.updateStartupCompletRegistration(data)
      if(response.data.success === false) {
        alert(`Falha: ${response.response.data.message}`)
      }
      setLoading(false);
      dispatch(setStep(StartupRegistrationStepsEnum.INVESTIMENTO))
      navigate('/complete-registration')
    } catch (error) {
      setLoading(false);
      alert(`Falha: ${error.response.data.message}`)
    }
  }

  return (
    <>
      <Header />

      <Sidebar isStartup />

      <Box
        sx={{
          width: 'calc(100% - 60px)',
          position: 'relative',
          top: '60px',
          left: '60px',
        }}
      >
        <SubHeaderBreadchrumbs>
          <Breadcrumbs
            aria-label="breadcrumb"
            sx={{ color: '#9999AB', fontSize: 12, mb: 1 }}
          >
            <Link underline="hover" color="inherit" href="/">
              Home
            </Link>
            <Link
              underline="hover"
              color="inherit"
              href="/complete-registration"
              aria-current="page"
            >
              Cadastro Completo
            </Link>

            <Link
              underline="hover"
              color="inherit"
              href="#"
              aria-current="page"
            >
              Investimento
            </Link>
          </Breadcrumbs>

          <TitlePage>Investimento</TitlePage>

          <ProgressBar />
        </SubHeaderBreadchrumbs>
        <Container>
          <DrowpDownStartupStep3 />

          <Button
            variant="contained"
            color="primary"
            sx={{
              m: 'auto',
              mb: 20,
              position: 'realtive',
              top: '200px',
              py: '15px',
              px: '16px',
              display: 'flex',
            }}
            type="button"
            disabled={loading}
            onClick={onSubmit}
          >
            {loading ? 'Carregando' : 'Salvar Alterações'}
          </Button>
        </Container>
      </Box>
    </>
  );
}

export default CompleteRegistration;
