/* eslint-disable react/no-array-index-key */
import { useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { store } from '@src/store';
import ApiClient from '@src/services/api/api';

const schema = yup.object().shape({
  shareHolders: yup.array().of(
    yup.object().shape({
      desc_shareholder_id: yup.string().required('Campo obrigatório'),
      percentage: yup.string().required('Campo obrigatório'),
      id: yup.number().optional(),
    })
  ),
});

function ShareholderForm() {
  const { user } = store.getState().auth;
  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      shareHolders: [
        { desc_shareholder_id: '', percentage: '', id: undefined },
      ],
    },
  });
  const {
    fields: shareHolders,
    append,
    remove,
  } = useFieldArray({ name: 'shareHolders', control });
  const [loading, setLoading] = useState(false);

  const handleAddMoreInputs = () => {
    append({ desc_shareholder_id: '', percentage: '', id: undefined });
  };

  const handleRemoveInputs = () => {
    remove(shareHolders.length - 1);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const { length } = data.shareHolders;
    const shareHoldersData = data.shareHolders;
    try {
      let signUpShareHolderError = false;
      for (let i = 0; i <= length - 1; i += 1) {
        const shareHolderData = {
          desc_shareholder_id: shareHoldersData[i].desc_shareholder_id,
          percentage: shareHoldersData[i].percentage,
          startup_id: user.startup_id,
        };
        // eslint-disable-next-line no-await-in-loop
        const response = await ApiClient.signUpShareHolder(shareHolderData);
        if (response.data.success === false) {
          signUpShareHolderError = true;
        }
      }
      if (signUpShareHolderError) {
        alert(
          `Um ou mais shareholder podem não ter sido cadastrados, tente novamente`
        );
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      alert(`Falha ${err}`);
    }
  };

  return (
    <Box
      sx={{ mt: 8, mb: 3, px: 4 }}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box>
        {shareHolders.map((shareHolder, index) => (
          <Box key={index}>
            <Stack direction="row" spacing={3} sx={{ mt: 2 }}>
              <Controller
                name={`shareHolders.${index}.desc_shareholder_id`}
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    key={shareHolder.id}
                    {...field}
                    {...register(`shareHolders.${index}.desc_shareholder_id`)}
                    margin="none"
                    sx={{ mb: 5 }}
                    fullWidth
                    // Label Correction
                    label="Insira o nome do acionista"
                    autoFocus
                    color="primary"
                    error={!!errors.shareHolders?.[index]?.desc_shareholder_id}
                    helperText={
                      errors.shareHolders?.[index]?.desc_shareholder_id?.message
                    }
                  />
                )}
              />
              <Controller
                name={`shareHolders.${index}.percentage`}
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    {...register(`shareHolders.${index}.percentage`)}
                    margin="none"
                    sx={{ mb: 5 }}
                    fullWidth
                    // Label Correction
                    label="Porcentagem do Acionista (%)"
                    autoFocus
                    color="primary"
                    type="number"
                    error={!!errors.shareHolders?.[index]?.percentage}
                    helperText={
                      errors.shareHolders?.[index]?.percentage?.message
                    }
                  />
                )}
              />
            </Stack>
          </Box>
        ))}
        <Button
          variant="outlined"
          onClick={handleRemoveInputs}
          sx={{
            mt: 0,
            mb: 5,
            ml: 'auto',
            mr: 2,
            py: '6px',
            px: '16px',
            fontWeight: 'bold',
          }}
          disabled={loading || shareHolders.length < 2}
        >
          Remover shareholder
        </Button>
        <Button
          variant="outlined"
          sx={{
            mt: 0,
            mb: 5,
            ml: 'auto',
            py: '6px',
            px: '16px',
            fontWeight: 'bold',
          }}
          onClick={handleAddMoreInputs}
          disabled={loading}
        >
          Adicionar nova Shareholder
        </Button>
      </Box>
      <Button
        variant="outlined"
        color="primary"
        sx={{
          m: 'auto',
          position: 'realtive',

          mb: '70px',
          py: '15px',
          px: '16px',
          display: 'flex',
        }}
        disabled={loading}
        type="submit"
      >
        {loading ? 'Carregando' : 'Salvar Alterações'}
      </Button>
    </Box>
  );
}

export default ShareholderForm;
