/* eslint-disable react/prop-types */
import FormatMoneyBRL from '@src/helpers/FormatMoneyBRL';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

import Label from '@src/components/common/Label';
import ThumbCompany from '@src/components/common/ThumbCompany';
import ProgressBar from '@src/components/common/ProgressBar';

function CardInvestor({ dataInvestor }) {

  const hasSector = dataInvestor.sector;

  return (
    <Paper sx={{ p: 3 }}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '74px auto',
          gap: '0 12px',
          gridTemplateAreas: `
        "thumb title"
        "thumb label"`,
          justifyItems: 'flex-start',
          alignItems: 'flex-start',
        }}
      >
        <ThumbCompany src={dataInvestor.logo} />

        <Typography
          sx={{
            fontSize: '1.250rem',
            lineHeight: '1.2em',
            color: 'text.secondary',
            gridArea: 'title',
          }}
        >
          {dataInvestor.fantasy_name}
        </Typography>

        {hasSector && <Label text={dataInvestor.sector} />}
      </Box>

      <Box sx={{ my: 4 }}>
        <ProgressBar
          initialValue={dataInvestor.quotas}
          maxValue={dataInvestor.startup_total_quotas}
        />

        <Typography
          sx={{
            fontSize: '.750rem',
            color: '#888',
            mt: 1,
          }}
          // Correction
        >
          Quotas compradas:
          <b>{dataInvestor.quotas}</b>
        </Typography>

        <Typography
          sx={{
            fontSize: '.750rem',
            color: '#888',
          }}
        >
         Valor investido: R$
          <b> {FormatMoneyBRL(dataInvestor.amount)}</b>
        </Typography>
        <Typography
          sx={{
            fontSize: '.750rem',
            color: '#888',
          }}
        >
          <a href={`${dataInvestor.url_docs}${dataInvestor.termsheet}`} target="_blank" rel="noreferrer"><b>Termsheet</b></a>
        </Typography>
      </Box>

      <Button
        variant="contained"
        size="small"
        color="secondary"
        sx={{
          display: 'block',
          mt: 2,
          mx: 'auto',
        }}
      >
        CONTRATAR DILIGÊNCIA
      </Button>
      <Button
        variant="outlined"
        size="small"
        color="secondary"
        sx={{
          display: 'block',
          mt: 2,
          mx: 'auto',
        }}
      >
        CONSULTAR EY
      </Button>
    </Paper>
  );
}

export default CardInvestor;
