/* eslint-disable no-console */
import { useState } from 'react';
import { Link as InnerLink, animateScroll as scroll } from 'react-scroll';
import { Link } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';

import { LogoEY } from '@src/constants/imgs/';
import { useDispatch } from 'react-redux';

import authActions from '@src/store/modules/auth/actions';
import { RoutesMap } from '@src/routes/maps';
import { ROLES } from '@src/libs/models/roles';

const navMenuItems = [
  {
    MenuTitle: 'Como funciona',
    IdSection: 'como-funciona',
  },
  {
    MenuTitle: 'Qual nosso papel',
    IdSection: 'nosso-papel',
  },
  {
    MenuTitle: 'FAQ',
    IdSection: 'faq',
  },
  {
    MenuTitle: 'Contato',
    IdSection: 'contato',
  },
];

const login = [
  {
    title: 'Sou Startup',
    link: RoutesMap.LOGIN_STARTUP,
  },
  {
    title: 'Sou Investidor',
    link: RoutesMap.LOGIN_INVESTOR,
  },
  {
    title: 'Sou Consultor EY',
    link: RoutesMap.LOGIN_CONSULTOR,
  },
];

const Role = {
  [login[0].link]: ROLES[1],
  [login[1].link]: ROLES[2],
  [login[2].link]: ROLES[3],
};

const sigin = [
  {
    title: 'Sou Startup',
    link: '/pre-onboarding-startup',
  },
  {
    title: 'Sou Investidor',
    link: '/cadastro',
  },
];

export default function HeaderLP() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorSigin, setAnchorSigin] = useState(null);
  const dispatch = useDispatch();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenSignMenu = (event) => {
    setAnchorSigin(event.currentTarget);
  };
  const handleCloseSignMenu = () => {
    setAnchorSigin(null);
  };
  const handleUserRole = (link) => {
    dispatch(authActions.setUserRole(Role[link]));
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed">
        <Toolbar
          sx={{
            justifyContent: 'space-between',
            pl: { xs: 1, sm: 1.5, md: '24px' },
          }}
        >
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {navMenuItems.map((section) => (
                <MenuItem key={section} sx={{ display: 'block' }}>
                  <InnerLink
                    to={section.IdSection}
                    spy
                    smooth
                    offset={-50}
                    duration={500}
                    style={{ display: 'block', lineHeight: '36px' }}
                    onClick={handleCloseNavMenu}
                  >
                    {section.MenuTitle}
                  </InnerLink>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box
            onClick={() => scroll.scrollToTop()}
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              mr: { xs: 'auto', md: 'unset' },
            }}
          >
            <img src={LogoEY} alt="Logo EY" />
            <Typography variant="h6" sx={{ ml: 2, mt: '3px', fontSize: 16 }}>
              Startup <b>Showcase</b>
            </Typography>
          </Box>

          <Box sx={{ display: { xs: 'none', md: 'flex' }, mx: 'auto' }}>
            {navMenuItems.map((section) => (
              <Typography
                key={section}
                sx={{
                  fontSize: '.875rem',
                  textTransform: 'uppercase',
                  px: 2,
                  mx: 2,
                  cursor: 'pointer',
                }}
              >
                <InnerLink
                  to={section.IdSection}
                  spy
                  smooth
                  offset={0}
                  duration={500}
                >
                  {section.MenuTitle}
                </InnerLink>
              </Typography>
            ))}
          </Box>
          <Box sx={{ mr: { xs: 0, sm: 2 } }}>
            <Button
              variant="outlined"
              size="small"
              onClick={handleOpenUserMenu}
              sx={[
                {
                  color: 'primary.contrastText',
                  borderColor: 'primary.contrastText',
                },
                {
                  '&:hover': {
                    borderColor: 'primary.contrastText',
                  },
                },
              ]}
            >
              Entrar
            </Button>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {login.map((persona) => (
                <MenuItem
                  key={persona}
                  onClick={handleCloseUserMenu}
                  sx={{ display: 'block', minWidth: { xs: '200px' } }}
                >
                  <Link
                    to={persona.link}
                    onClick={() => handleUserRole(persona.link)}
                    style={{
                      display: 'block',
                      color: '#1A1A24',
                      lineHeight: '36px',
                    }}
                  >
                    {persona.title}
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
            <Button
              variant="contained"
              size="small"
              onClick={handleOpenSignMenu}
              sx={[
                {
                  color: 'primary.main',
                  bgcolor: 'primary.contrastText',
                },
                {
                  '&:hover': {
                    color: 'primary.main',
                    bgcolor: 'primary.contrastText',
                  },
                },
              ]}
            >
              Cadastre-se
            </Button>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorSigin}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorSigin)}
              onClose={handleCloseSignMenu}
            >
              {sigin.map((persona) => (
                <MenuItem
                  key={persona}
                  onClick={handleCloseSignMenu}
                  sx={{ display: 'block', minWidth: { xs: '200px' } }}
                >
                  <Link
                    to={persona.link}
                    style={{
                      display: 'block',
                      color: '#1A1A24',
                      lineHeight: '36px',
                    }}
                  >
                    {persona.title}
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
