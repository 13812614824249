import React, {
  useImperativeHandle,
  useEffect,
  forwardRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from '@mui/material';

import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import PartnersData from '@src/pages/consultant/StartupApproval/Components/PartnersData';
import { ApprovalStartupsTypes, JourneyTypes } from '@src/libs/models/types';
import enterpriseActions from '@src/store/modules/enterprise/actions';
import * as investorApprovalActions from '@src/store/modules/consultorInvestorApproval/actions';
import * as startupApprovalActions from '@src/store/modules/consultorStartupApproval/actions';
import enterpriseSelectors from '@src/store/modules/enterprise/selectors';
import authSelectors from '@src/store/modules/auth/selectors';
import { BootstrapDialogTitle } from './styles';

const viewLeftButtons = {
  [ApprovalStartupsTypes[1]]: 'recusar',
  [ApprovalStartupsTypes[4]]: 'cancelar',
  [ApprovalStartupsTypes[6]]: 'cancelar',
};
const viewRightButtons = {
  [ApprovalStartupsTypes[1]]: 'aprovar',
  [ApprovalStartupsTypes[4]]: 'remover',
  [ApprovalStartupsTypes[6]]: 'reativar empresa',
};

const PreRegistrationModal = forwardRef(({ id, type, journey }, ref) => {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();
  const loading = useSelector(enterpriseSelectors.getLoading);
  const loadingApproval = useSelector(enterpriseSelectors.getLoadingApproval);
  const enterprise = useSelector(enterpriseSelectors.getEnterprise);
  const success = useSelector(enterpriseSelectors.getSuccessEnterprise);
  const userActive = useSelector(authSelectors.getUser);
  const [currentButton, setCurrentButton] = useState('');

  useImperativeHandle(ref, () => ({
    open: () => {
      setOpen((x) => !x);
    },

    close: () => {
      setOpen((x) => !x);
    },
  }));

  useEffect(() => {
    if (open && id && journey) {
      dispatch(enterpriseActions.enterpriseDetailRequest(id, journey));
    }
  }, [dispatch, open, id, journey]);

  useEffect(() => {
    if (open && success) {
      setOpen((x) => !x);
      dispatch(enterpriseActions.enterpriseReset());
      if (journey === JourneyTypes[1]) {
        dispatch(startupApprovalActions.startupApprovalRequest());
      } else {
        dispatch(investorApprovalActions.investorApprovalRequest());
      }
    }
  }, [dispatch, open, success, journey]);

  const handleClose = () => {
    setOpen((x) => !x);
  };

  const handleCancel = () => {
    setCurrentButton('left');
    if (viewLeftButtons[type] === 'recusar') {
      if (journey === JourneyTypes[1]) {
        dispatch(enterpriseActions.enterpriseRemoveRequest(enterprise.id));
      } else {
        dispatch(
          enterpriseActions.enterpriseApprovalRequest({
            id: enterprise.id,
            user_consultant_id: userActive.id,
            status_id: 3,
            journey,
          })
        );
      }
    } else {
      handleClose();
    }
  };

  const investorActionsChoose = (viewRight) => {
    switch (viewRight) {
      case 'aprovar':
        return 2;
      case 'remover':
        return 3;
      case 'reativar empresa':
        return 2;
      default:
        return 0;
    }
  };

  const handleSubmit = () => {
    setCurrentButton('right');
    if (journey === JourneyTypes[1]) {
      dispatch(
        enterpriseActions.enterpriseApprovalRequest({
          id: enterprise.id,
          user_consultant_id: userActive.id,
          status_id: 2,
          email: enterprise.email,
          journey,
        })
      );
    } else {
      dispatch(
        enterpriseActions.enterpriseApprovalRequest({
          id: enterprise.id,
          user_consultant_id: userActive.id,
          status_id: investorActionsChoose(viewRightButtons[type]),
          journey,
        })
      );
    }
    handleClose();
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      maxWidth="sm"
      fullWidth
    >
      <BootstrapDialogTitle
        onClose={handleClose}
        sx={{
          fontFamily: 'EYInterstate',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '18px',
          lineHeight: '22px',
          color: 'text.secondary',
        }}
      >
        Pré-Cadastro
      </BootstrapDialogTitle>
      <DialogContent
        sx={{
          '&::-webkit-scrollbar': {
            width: '7px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'text.primary',
            borderRadius: '9px',
          },
        }}
      >
        {loading && (
          <Box
            sx={{
              display: 'flex',
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(100vh - 200px)',
              width: '100%',
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {!loading && (
          <DialogContentText>
            <Box sx={{ paddingLeft: 8, paddingRight: 8 }}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: 16,
                        color: 'text.secondary',
                        fontWeight: 400,
                      }}
                    >
                      Cadastro Básico
                    </Typography>
                    <Box sx={{ ml: 1.5 }}>
                      <Typography
                        mt={5}
                        id="transition-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{
                          fontSize: '14px',
                          fontWeight: '300',
                          color: '#2E2E38',
                          mt: 1.5,
                        }}
                      >
                        <b>Nome Completo:</b> {enterprise.fantasy_name}
                      </Typography>
                      <Typography
                        mt={5}
                        id="transition-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{
                          fontSize: '14px',
                          fontWeight: '300',
                          color: '#2E2E38',
                          mt: 1.5,
                        }}
                      >
                        <b>Email:</b> {enterprise?.email}
                      </Typography>

                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography
                          mt={5}
                          id="transition-modal-title"
                          variant="h6"
                          component="h2"
                          sx={{
                            fontSize: '14px',
                            fontWeight: '300',
                            color: '#2E2E38',
                            mt: 1,
                          }}
                        >
                          <b>Celular:</b> {enterprise?.phone}
                        </Typography>
                        <Typography
                          mt={5}
                          id="transition-modal-title"
                          variant="h6"
                          component="h2"
                          sx={{
                            fontSize: '14px',
                            fontWeight: '300',
                            color: '#2E2E38',
                            mt: 1,
                            mr: 20,
                          }}
                        >
                          <b>Telefone:</b> {enterprise?.telephone}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          mt={5}
                          id="transition-modal-title"
                          variant="h6"
                          component="h2"
                          sx={{
                            fontSize: '14px',
                            fontWeight: '300',
                            color: '#2E2E38',
                            mt: 1,
                          }}
                        >
                          <b>Cidade:</b> {enterprise?.city}
                        </Typography>
                        <Typography
                          mt={5}
                          id="transition-modal-title"
                          variant="h6"
                          component="h2"
                          sx={{
                            fontSize: '14px',
                            fontWeight: '300',
                            color: '#2E2E38',
                            mt: 1.5,
                            mr: 20,
                          }}
                        >
                          <b>Estado:</b> {enterprise?.state}
                        </Typography>
                      </Box>
                      <Typography
                        mt={5}
                        id="transition-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{
                          fontSize: '14px',
                          fontWeight: '300',
                          color: '#2E2E38',
                          mt: 1,
                          mb: 5,
                        }}
                      >
                        <b>País:</b> {enterprise?.country}
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: 16,
                        color: 'text.secondary',
                        fontWeight: 400,
                      }}
                    >
                      Dados da empresa:
                    </Typography>
                    <Box sx={{ ml: 1.5 }}>
                      <Typography
                        mt={5}
                        id="transition-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{
                          fontSize: '14px',
                          fontWeight: '300',
                          color: '#2E2E38',
                          mt: 1.5,
                        }}
                      >
                        <b>Razão Social:</b> {enterprise?.reason_social}
                      </Typography>
                      <Typography
                        mt={5}
                        id="transition-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{
                          fontSize: '14px',
                          fontWeight: '300',
                          color: '#2E2E38',
                          mt: 1.5,
                        }}
                      >
                        <b>Nome Fantasia:</b> {enterprise?.fantasy_name}
                      </Typography>
                      <Typography
                        mt={5}
                        id="transition-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{
                          fontSize: '14px',
                          fontWeight: '300',
                          color: '#2E2E38',
                          mt: 1.2,
                        }}
                      >
                        <b>CNPJ:</b> {enterprise?.cnpj}
                      </Typography>

                      <Typography
                        mt={5}
                        id="transition-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{
                          fontSize: '14px',

                          fontWeight: '300',
                          color: '#2E2E38',
                          mt: 1.2,
                        }}
                      >
                        <b>Endereço:</b> {enterprise?.address}
                      </Typography>
                      <Typography
                        mt={5}
                        id="transition-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{
                          fontSize: '14px',
                          fontWeight: '300',
                          color: '#2E2E38',
                          mt: 1,
                        }}
                      >
                        <b>Setor:</b> {enterprise?.sector}
                      </Typography>
                      <Typography
                        mt={5}
                        id="transition-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{
                          fontSize: '14px',
                          fontWeight: '300',
                          color: '#2E2E38',
                          mt: 1.2,
                        }}
                      >
                        <b>Subsetor:</b> {enterprise?.sub_sector}
                      </Typography>
                      <Typography
                        mt={5}
                        id="transition-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{
                          fontSize: '14px',
                          fontWeight: '300',
                          color: '#2E2E38',
                          mt: 1.2,
                        }}
                      >
                        <b>Website:</b> {enterprise?.website}
                      </Typography>
                      <Typography
                        mt={5}
                        id="transition-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{
                          fontSize: '14px',
                          fontWeight: '300',
                          color: '#2E2E38',
                          mt: 1.2,
                        }}
                      >
                        <b>Faturamento:</b> {enterprise?.invoice}
                      </Typography>

                      <Typography
                        mt={5}
                        id="transition-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{
                          fontSize: '14px',
                          fontWeight: '300',
                          color: '#2E2E38',

                          mt: 1.2,
                          mb: 1.2,
                        }}
                      >
                        <b>Último Valuation:</b> {enterprise?.evaluation}
                      </Typography>

                      <Typography
                        mt={5}
                        id="transition-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{
                          fontSize: '14px',
                          fontWeight: '300',
                          color: '#2E2E38',

                          mt: 1.2,
                          mb: 5,
                        }}
                      >
                        <b>Descrição:</b> {enterprise?.description}
                      </Typography>
                    </Box>
                    {enterprise.partners && enterprise.partners.length > 0 && (
                      <>
                        <Typography
                          sx={{
                            fontSize: 16,
                            color: 'text.secondary',
                            fontWeight: 400,
                          }}
                        >
                          Dados dos Sócios:
                        </Typography>
                        <Box sx={{ ml: 1.5, mb: 8, mt: 3 }}>
                          <PartnersData partners={enterprise.partners} />
                        </Box>
                      </>
                    )}
                    <Typography
                      sx={{
                        fontSize: 16,
                        color: 'text.secondary',
                        fontWeight: 400,
                      }}
                    >
                      Relacionamento EY
                    </Typography>

                    <Box sx={{ ml: 1.5 }}>
                      <Typography
                        mt={5}
                        id="transition-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{
                          fontSize: '14px',
                          fontWeight: '300',
                          color: '#2E2E38',
                          mt: 1.2,
                        }}
                      >
                        <b>Pessoa de relacionamento na EY:</b>{' '}
                        {enterprise?.consultant_name_ey}
                      </Typography>
                      <Typography
                        mt={5}
                        id="transition-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{
                          fontSize: '14px',
                          fontWeight: '300',
                          color: '#2E2E38',
                          mt: 1.2,
                          mb: 5,
                        }}
                      >
                        <b>É cliente EY:</b>{' '}
                        {enterprise?.consultant_name_ey ? 'Sim' : 'Não'}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            sx={{
              borderRadius: 28,
              background: '#FFFFFF',
              '&:hover': {
                backgroundColor: '#FFFFFF',
                opacity: [0.9, 0.8, 0.7],
              },
              borderColor: '#542EA6;',
              border: 1,
              color: '#542EA6;',
              px: 1.5,
              height: '40px',
              minWidth: '100px',
              fontWeight: '400',
              textTransform: 'uppercase',
              fontSize: '14px',
              marginRight: 2,
            }}
            onClick={handleCancel}
            disabled={loading || loadingApproval}
          >
            {viewLeftButtons[type] === 'recusar' &&
            loadingApproval &&
            currentButton === 'left' ? (
              <CircularProgress size={30} />
            ) : (
              viewLeftButtons[type]
            )}
          </Button>
          <Button
            onClick={handleSubmit}
            sx={{
              borderRadius: 28,
              background: '#542EA6',
              '&:hover': {
                backgroundColor: '#542EA6',
                opacity: [0.9, 0.8, 0.7],
              },
              borderColor: '#542EA6;',
              border: 1,
              color: '#FFFFFF;',
              height: '40px',
              fontWeight: '400',
              px: 5,
              textTransform: 'uppercase',
            }}
            disabled={loading || loadingApproval}
          >
            {loadingApproval && currentButton === 'right' ? (
              <CircularProgress
                size={30}
                sx={{
                  color: '#fff',
                }}
              />
            ) : (
              viewRightButtons[type]
            )}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
});

PreRegistrationModal.displayName = 'PreRegistrationModal';
PreRegistrationModal.propTypes = {
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  journey: PropTypes.string.isRequired,
};

PreRegistrationModal.defaultProps = {};
export default PreRegistrationModal;
