/* eslint-disable default-param-last */

import InvestitorHomeActions from './actions';

const INITIAL_STATE = {
  lastSeenStartups: [],
  startups: [],
};

const InvestitorHomeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case InvestitorHomeActions.findLastSeenStartupsAction:
      return {
        ...state,
        lastSeenStartups: action.lastSeenStartups,
      };

    case InvestitorHomeActions.findAllStartupsAction:
      return {
        ...state,
        startups: action.startups,
      };

    default:
      return { ...state };
  }
};

export default InvestitorHomeReducer;
