import PropTypes from 'prop-types';
import { Typography, Box } from '@mui/material';
import EyeIcon from '../EyeIcon';
import CardContent from '../CardContent';
import ProposalCounter from '../../../../../components/ProgressBar/ProposalCounter';

function CardProposals({ values, locked }) {
  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      {locked ? <EyeIcon /> : null}
      <CardContent locked={locked}>
        <Box mb={1}>
          <Typography
            sx={{
              color: '#1A1A24',
              fontSize: '14px',
              fontWeight: 800,
            }}
          >
            Propostas
          </Typography>
        </Box>

        <ProposalCounter values={values} />
      </CardContent>
    </Box>
  );
}

CardProposals.propTypes = {
  locked: PropTypes.bool,
  values: PropTypes.shape({
    pending: PropTypes.number,
    accepted: PropTypes.number,
    completed: PropTypes.number,
    refused: PropTypes.number,
  }),
};

CardProposals.defaultProps = {
  locked: false,
  values: {
    pending: 2,
    accepted: 14,
    completed: 21,
    refused: 30,
  },
};

export default CardProposals;
