import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { MailIcon } from './styles';

import Mail from '../../../assets/images/Mail.svg';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 670,
  height: 376,
  bgcolor: '#ffffff',
  border: '1px solid rgba(26, 26, 36, 0.14)',
  boxShadow: 24,
  borderRadius: '6px',
  p: 4,
};

// eslint-disable-next-line react/prop-types
export default function ModalEmailConfirmation({ closeModal, isOpen, disableBackdropClick = false, title}) {
  const [open, setOpen] = React.useState(isOpen);

  const handleClose = () => {
    if (!disableBackdropClick) setOpen(false);
    if (closeModal) closeModal()
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        dis
      >
        <Fade in={open}>
          <Box sx={style}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                marginTop: '50px',
              }}
            >
              <MailIcon src={Mail} alt="Logo Startup-showcase" loading="lazy" />
            </Box>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{
                fontSize: '22px',
                marginTop: '40px',
                marginBottom: '30px',
                fontWeight: '400',
                color: '#2E2E38',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
              }}
            >
              {title || 'Confirme seu e-mail'}
            </Typography>
            <Typography
              id="transition-modal-description"
              sx={{
                fontSize: '16px',
                marginBottom: '30px',
                fontWeight: '300',
                color: '#747480',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
              }}
            >
              Te enviaremos um link por e-mail para que <br /> você continue seu
              cadastro
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
