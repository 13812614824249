export const RoutesMap = {
  BASE: '/',
  LOGIN_INVESTOR: '/investor/login',
  LOGIN_STARTUP: '/startup/login',
  LOGIN_CONSULTOR: '/consultor/login',
  HOME_INVESTOR: '/investor/home',
  INVESTOR_STARTUP: '/investor/startup/:id',
  HOME_STARTUP: '/startup/home',
  HOME_CONSULTOR: '/consultor/home',
  CONSULTOR_STARTUP_PROPOSALS: '/consultor/startup/propostas/:id',
  CONSULTOR_INVESTOR_PROPOSALS: '/consultor/investidor/propostas/:id',
  CONSULTOR_STARTUP: '/consultor/startup/:id',
  CONSULTOR_STARTUP_APPROVAL: '/consultor/startup/approval',
  CONSULTOR_INVESTOR_APPROVAL: '/consultor/investor/approval',
  SIGN_UP_CONSULTOR: '/cadastro',
  STARTUP: '/startup',
  TRANSACTIONS: '/transactions',
  PROPOSALS: '/propostas',
  PRE_ONBOARD_STARTUP: '/pre-onboarding-startup',
  ONBOARDING_STARTUP: '/onboarding-startup',
  PROPOSALS_RECEIVED: '/propostas/recebidas/:investor/:id',
  ACCEPTED_PROPOSALS: '/propostas/aceitas/:investor/:id',
  COMPLETE_REGISTRATION: '/complete-registration',
  REGISTRATION_STEP_1: '/registration-step1',
  REGISTRATION_STEP_2: '/registration-step2',
  REGISTRATION_STEP_3: '/registration-step3',
  CONTRACT_SESSION: '/contract-session',
};
