import { all, fork } from 'redux-saga/effects';

import investitorStartuoSaga from '@src/pages/investor/Startup/saga';
import investitorHomeSaga from '@src/pages/investor/Home/saga';
import StartupProposalSaga from '@src/components/Modal/Proposal/saga';
import auth from './auth/sagas';
import startupApproval from './consultorStartupApproval/sagas';
import investorApproval from './consultorInvestorApproval/sagas';
import enterprise from './enterprise/sagas';
import transactionsProposalsSaga from './transactions/saga';

export default function* rootSaga() {
  yield fork(auth);
  yield fork(startupApproval);
  yield fork(investorApproval);
  yield fork(enterprise);
  yield all(investitorStartuoSaga);
  yield all(investitorHomeSaga);
  yield all(transactionsProposalsSaga);
  yield all(StartupProposalSaga);
}
