/* eslint-disable no-console */
/* eslint-disable no-shadow */
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { ROLES, ROUTES_ROLES } from '@src/libs/models/roles';
import AuthLayout from '../pages/_layouts/auth';
import DefaultLayout from '../pages/_layouts/default';
import { RoutesMap } from './maps';

function RouteWrapper({ component: Component, isPrivate, role, ...rest }) {
  const { signed, user } = useSelector((state) => state.auth);
  if (!signed && isPrivate) {
    return {
      ...rest,
      element: <Navigate to={RoutesMap.BASE} />,
    };
  }

  if (signed && !isPrivate) {
    return {
      ...rest,
      element: <Navigate to={ROUTES_ROLES[user.user_type_id]} />,
    };
  }

  if (
    signed &&
    user.user_type_id &&
    isPrivate &&
    role !== ROLES[user.user_type_id]
  ) {
    return {
      ...rest,
      element: <Navigate to={ROUTES_ROLES[user.user_type_id]} />,
    };
  }

  const Layout = signed ? DefaultLayout : AuthLayout;

  return {
    ...rest,
    element: (
      <Layout>
        <Component />
      </Layout>
    ),
  };
}

export default RouteWrapper;

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  role: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
  role: '',
};
