import * as startupApprovalTypes from './types';

export function startupApprovalRequest() {
  return {
    type: startupApprovalTypes.startupApprovalRequest,
  };
}
export function startupApprovalSuccess(data) {
  return {
    type: startupApprovalTypes.startupApprovalSuccess,
    payload: { data },
  };
}
export function startupApprovalFailure() {
  return {
    type: startupApprovalTypes.startupApprovalFailure,
  };
}
export function startupApprovalReset() {
  return {
    type: startupApprovalTypes.startupApprovalReset,
  };
}

const consultorStartupApprovalActions = {
  startupApprovalRequest,
  startupApprovalSuccess,
  startupApprovalFailure,
  startupApprovalReset,
};

export default consultorStartupApprovalActions;
