/* eslint-disable react/prop-types */
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import NumberFormat from 'react-number-format';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Button } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  clearPreRegister,
  preRegisterCompanyData,
} from '@src/store/modules/preRegister/actions';
import { store } from '@src/store';
import ApiClient from '@src/services/api/api';
import { unMask } from '@src/utils/functions';
import StartupStatusEnum from '@src/constants/enums/startup-status.enum';

const schema = yup
  .object({
    sector: yup.string().required('Campo obrigatório'),
    sub_sector: yup.string().required('Campo obrigatório'),
    evaluation: yup.string().required('Campo obrigatório'),
    invoice: yup.string().required('Campo obrigatório'),
    capited_value: yup.string().required('Campo obrigatório'),
    consultant_name_ey: yup.string().optional(),
  })
  .required();

function DadosEmpresa({ setActiveStep }) {
  const dispatch = useDispatch();
  const { companyData, partner, user } = store.getState().preRegister;
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: companyData,
  });

  const [disabledConsultantInputName, setDisabledConsultantName] =
    useState(true);
  const [loading, setLoading] = useState(false);

  const handleClientEyOptionChange = (event) => {
    if (event.target.value === 'no') {
      setValue({ consultant_name_ey: '' });
      setDisabledConsultantName(true);
    } else {
      setDisabledConsultantName(false);
    }
  };

  const onSubmit = async (data) => {
    Object.assign(data, {
      evaluation: parseFloat(
        data.evaluation.split('R$')[1].replace('.', '').replace(',', '.')
      ),
      invoice: parseFloat(
        data.invoice.split('R$')[1].replace('.', '').replace(',', '.')
      ),
      capited_value: parseFloat(
        data.capited_value.split('R$')[1].replace('.', '').replace(',', '.')
      ),
    });

    const assignData = { ...data };
    dispatch(preRegisterCompanyData(data));
    setLoading(true);
    try {
      let signUpPartnerError = false;
      const { length } = partner;
      for (let i = 0; i <= length - 1; i += 1) {
        const partnerData = {
          name: partner[i].name,
          function_role: partner[i].function_role,
          email: partner[i].email,
          phone: unMask(partner[i].phone),
          linkedin: partner[i].linkedin,
          startup_id: partner[i].startup_id,
        };
        // eslint-disable-next-line no-await-in-loop
        const response = await ApiClient.signUpPartner(partnerData);
        if (response.data.success === false) {
          signUpPartnerError = true;
        }
      }

      Object.assign(assignData, {
        id: user.startup.id,
        capited_value: String(assignData.capited_value),
        startup_status_id: StartupStatusEnum.PRECADASTRO_COMPLETO
      });

      const response = await ApiClient.signUpStartupCompany(assignData);

      if (!signUpPartnerError && response.data.success) {
        setActiveStep(4);
        dispatch(clearPreRegister());
      } else {
        alert(`Falha no cadastro.`);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      alert(`Falha ${err}`);
    }
  };
  return (
    <Box sx={{ mt: 8, px: 4 }}>
      <Typography variant="h5" sx={{ mb: 6, fontSize: 20, fontWeight: 400 }}>
        Cadastre sua Empresa
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="row" spacing={3} sx={{ mt: 2, mb: 5 }}>
          <Controller
            name="sector"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                margin="none"
                fullWidth
                label="Setor"
                autoFocus
                color="primary"
                error={!!errors.sector}
                helperText={errors?.sector?.message}
              />
            )}
          />
          <Controller
            name="sub_sector"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                margin="none"
                fullWidth
                label="Subsetor"
                autoFocus
                color="primary"
                error={!!errors.sub_sector}
                helperText={errors?.sub_sector?.message}
              />
            )}
          />
          <Controller
            render={({ field }) => (
              <NumberFormat
                decimalScale={2}
                decimalSeparator=","
                thousandSeparator="."
                fixedDecimalScale
                allowEmptyFormatting={false}
                control={control}
                {...field}
                customInput={TextField}
                prefix="R$"
                fullWidth
                label="Último Valuation"
                error={!!errors.evaluation}
                helperText={errors?.evaluation?.message}
              />
            )}
            name="evaluation"
            rules={{ required: true }}
            control={control}
          />
        </Stack>

        <Stack direction="row" spacing={3} sx={{ mt: 2, mb: 5 }}>
          <Controller
            render={({ field }) => (
              <NumberFormat
                decimalScale={2}
                decimalSeparator=","
                thousandSeparator="."
                fixedDecimalScale
                allowEmptyFormatting={false}
                control={control}
                {...field}
                customInput={TextField}
                prefix="R$"
                fullWidth
                label="Faturamento"
                error={!!errors.invoice}
                helperText={errors?.invoice?.message}
              />
            )}
            name="invoice"
            rules={{ required: true }}
            control={control}
          />

          <Controller
            render={({ field }) => (
              <NumberFormat
                decimalScale={2}
                decimalSeparator=","
                thousandSeparator="."
                fixedDecimalScale
                allowEmptyFormatting={false}
                control={control}
                {...field}
                customInput={TextField}
                prefix="R$"
                fullWidth
                label="Valores Captados"
                error={!!errors.capited_value}
                helperText={errors?.capited_value?.message}
              />
            )}
            name="capited_value"
            rules={{ required: true }}
            control={control}
          />
        </Stack>
        <FormControl sx={{ width: '100%', mb: 3.5 }}>
          <FormLabel sx={{ mb: 2 }} id="client-ey">
            Você é cliente EY?
          </FormLabel>
          <RadioGroup
            sx={{ gap: 6 }}
            row
            aria-labelledby="client-ey"
            name="clienteEy"
            onChange={handleClientEyOptionChange}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Sim" />
            <FormControlLabel value="no" control={<Radio />} label="Não" />
          </RadioGroup>
        </FormControl>

        <Controller
          name="consultant_name_ey"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              margin="none"
              fullWidth
              // Label Correction
              label="Pessoa de Relacionamento EY"
              autoFocus
              disabled={disabledConsultantInputName}
              color="primary"
              error={!!errors.consultant_name_ey}
              helperText={errors?.consultant_name_ey?.message}
            />
          )}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            mt: 7,
            mb: 12,
          }}
        >
          <Button
            variant="outlined"
            onClick={() => setActiveStep(2)}
            sx={{ mr: 4 }}
          >
            Voltar
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
          >
            {loading ? 'Carregando' : 'Próximo'}
          </Button>
        </Box>
      </form>
    </Box>
  );
}

export default DadosEmpresa;
