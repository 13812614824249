import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import TitleSection from '@src/components/LandingPage/TitleSection';

import {
  StepNumber,
  BoxStep,
  StepText,
  StepArrow,
  StepArrowMiddle,
} from './styles';

function ComoFunciona() {
  return (
    <Box id="como-funciona">
      <Container
        maxWidth="xl"
        fixed
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          py: { xs: 5, sm: 5, md: 10 },
        }}
      >
        <TitleSection TitleText="Como funciona?" />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            flexDirection: { xs: 'column', sm: 'row' },
            flexWrap: { xs: 'nowrap', sm: 'wrap', md: 'nowrap', lg: 'wrap' },
            width: '100%',
            position: 'relative',
          }}
        >
          <BoxStep>
            <StepNumber>1</StepNumber>
            <StepText>
              Cadastre a sua <br /> <b>Startup</b> em nossa <br />
              plataforma
            </StepText>
          </BoxStep>
          <StepArrow />
          <BoxStep>
            <StepNumber>2</StepNumber>
            <StepText>
              Seu perfil será
              <br /> visitado por
              <br /> <b>Investidores</b>
            </StepText>
          </BoxStep>
          <StepArrowMiddle />
          <BoxStep>
            <StepNumber>3</StepNumber>
            <StepText>
              Receba <br />
              <b>
                propostas de <br /> investimento
              </b>
            </StepText>
          </BoxStep>
          <StepArrow />
          <BoxStep>
            <StepNumber>4</StepNumber>
            <StepText>
              Alcance sua
              <br />
              <b>meta de captação</b>
            </StepText>
          </BoxStep>
        </Box>
      </Container>
    </Box>
  );
}

export default ComoFunciona;
