export const getLoading = (store) => store.enterprise.loading;
export const getLoadingApproval = (store) => store.enterprise.loadingApproval;
export const getEnterprise = (store) => store.enterprise.enterprise;
export const getSuccessEnterprise = (store) => store.enterprise.success;

const enterpriseSelectors = {
  getLoading,
  getLoadingApproval,
  getEnterprise,
  getSuccessEnterprise,
};

export default enterpriseSelectors;
