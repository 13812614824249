import { CardContent, Card } from '@mui/material';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Content = styled(CardContent)`
  padding: ${(props) => props.padding};
  min-height: ${(props) => props.height}px;

  ${(props) =>
    props.locked &&
    css`
      filter: blur(15px);
      background: rgba(255, 255, 255, 0.4);
      pointer-events: none;
      user-select: none;
    `};
`;
 
export default function CardContentComponent({
  children,
  height,
  locked,
  padding,
}) {
  return (
    <Card>
      <Content height={height} locked={locked ? 1 : 0} padding={padding}>
        {children}
      </Content>
    </Card>
  );
}

CardContentComponent.propTypes = {
  locked: PropTypes.bool,
  children: PropTypes.node.isRequired,
  height: PropTypes.number,
  padding: PropTypes.string,
};

CardContentComponent.defaultProps = {
  locked: false,
  height: 260,
  padding: '24px 29px',
};
