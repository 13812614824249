import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import Label from '@src/components/common/Label';
import ListSocios from '@src/components/ListSocios';
import { formatCnpj } from '@src/utils/functions';

function CompanyProfile({ data }) {
  return (
    <Paper>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          px: '20px',
          py: '20px',
          borderBottom: '1px solid #EBEBEB',
        }}
      >
        <Grid container>
          <Grid item xs={12} sm={6} md={6} display="flex">
            <img
              src={data?.logo}
              alt="logo"
              aria-hidden="true"
              width="82"
              height="82"
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                ml: 2,
                mb: 'auto',
              }}
            >
              <Typography
                sx={{
                  fontSize: 22,
                  color: '#1A1A24',
                  mb: 1,
                }}
              >
                {data?.fantasy_name}
              </Typography>
              <Label text={data?.sector} />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            textAlign="right"
            sx={{
              marginTop: {
                md: 0,
                sm: 0,
                xs: 2,
              },
            }}
          >
            <Button
              variant="outlined"
              size="small"
              href={`mailto:${data?.email}`}
            >
              Falar com a empresa
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Grid container>
        <Grid item xs={12} sm={6} md={6}>
          <Box style={{}}>
            <Box sx={{ py: 3, pl: 4, pr: 4 }}>
              <Typography sx={{ mb: 4, fontSize: '14px' }}>
                {data?.description}
              </Typography>
              <Typography
                sx={{ fontSize: '14px', color: 'text.secondary', mb: 2 }}
              >
                <span style={{ display: 'block', color: '#515162' }}>
                  Razão social
                </span>
                {data?.reason_social}
              </Typography>
              <Typography
                sx={{ fontSize: '14px', color: 'text.secondary', mb: 2 }}
              >
                <span style={{ display: 'block', color: '#515162' }}>CNPJ</span>
                {formatCnpj(data?.cnpj)}
              </Typography>
              <Typography
                sx={{ fontSize: '14px', color: 'text.secondary', mb: 2 }}
              >
                <span style={{ display: 'block', color: '#515162' }}>
                  Setor
                </span>
                {data?.sector}
              </Typography>
              <Typography
                sx={{ fontSize: '14px', color: 'text.secondary', mb: 2 }}
              >
                <span style={{ display: 'block', color: '#515162' }}>
                  Website
                </span>
                {data?.website}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Box sx={{ pt: 2, borderLeft: '1px solid #F1F1F3' }}>
            <ListSocios data={data?.partners} />
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}

CompanyProfile.propTypes = {
  data: PropTypes.shape({
    fantasy_name: PropTypes.string,
    logo: PropTypes.string,
    cnpj: PropTypes.string,
    description: PropTypes.string,
    partners: PropTypes.arrayOf(PropTypes.shape()),
    reason_social: PropTypes.string,
    sector: PropTypes.string,
    website: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
};

export default CompanyProfile;
