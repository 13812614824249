import * as enterpriseDetailTypes from './types';

export function enterpriseDetailRequest(id, journey) {
  return {
    type: enterpriseDetailTypes.enterpriseDetailRequest,
    payload: { id, journey },
  };
}
export function enterpriseDetailSuccess(data) {
  return {
    type: enterpriseDetailTypes.enterpriseDetailSuccess,
    payload: { data },
  };
}
export function enterpriseDetailFailure() {
  return {
    type: enterpriseDetailTypes.enterpriseDetailFailure,
  };
}
export function enterpriseApprovalRequest(data) {
  return {
    type: enterpriseDetailTypes.enterpriseApprovalRequest,
    payload: data,
  };
}
export function enterpriseApprovalSuccess() {
  return {
    type: enterpriseDetailTypes.enterpriseApprovalSuccess,
  };
}
export function enterpriseApprovalFailure() {
  return {
    type: enterpriseDetailTypes.enterpriseApprovalFailure,
  };
}
export function enterpriseRemoveRequest(data) {
  return {
    type: enterpriseDetailTypes.enterpriseRemoveRequest,
    payload: data,
  };
}
export function enterpriseRemoveSuccess() {
  return {
    type: enterpriseDetailTypes.enterpriseRemoveSuccess,
  };
}
export function enterpriseRemoveFailure() {
  return {
    type: enterpriseDetailTypes.enterpriseRemoveFailure,
  };
}
export function enterpriseReset() {
  return {
    type: enterpriseDetailTypes.enterpriseReset,
  };
}

const enterpriseActions = {
  enterpriseDetailRequest,
  enterpriseDetailSuccess,
  enterpriseDetailFailure,
  enterpriseApprovalRequest,
  enterpriseApprovalSuccess,
  enterpriseApprovalFailure,
  enterpriseRemoveRequest,
  enterpriseRemoveSuccess,
  enterpriseRemoveFailure,
  enterpriseReset,
};

export default enterpriseActions;
