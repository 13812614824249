/* eslint-disable default-param-last */
import TransactionsProposalsActions from './actions';

const INITIAL_STATE = {
  pendingProposals: [],
};

const TransactionsProposalsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TransactionsProposalsActions.findPedingProposals:
      return {
        ...state,
        pendingProposals: action.pendingProposals,
      };
    case '@auth/SIGN_OUT':
      return {
        ...state,
        pendingProposals: [],
      };

    default:
      return { ...state };
  }
};

export default TransactionsProposalsReducer;
