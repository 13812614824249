import { ThemeProvider } from '@mui/material';
import { Toaster } from 'react-hot-toast';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import history from './services/history';

import { store, persistor } from './store';
import theme from './styles/theme';
import GlobalStyles from './styles/global';
import { AppRoutes } from './routes';

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <HistoryRouter history={history}>
          <ThemeProvider theme={theme}>
            <GlobalStyles />
            <AppRoutes />
          </ThemeProvider>
          <Toaster position="top-left" reverseOrder />
        </HistoryRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
