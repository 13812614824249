import PropTypes from 'prop-types';
import { Box, Typography, Button, Grid } from '@mui/material';

import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

export default function PartnersData({ partners }) {
  return (
    <Grid
      container
      spacing={{ xs: 2, md: 3 }}
      columns={{ xs: 4, sm: 8, md: 12 }}
      marginTop={2}
    >
      {partners?.map((partner) => (
        <Grid item xs={12} sm={12} md={12} key={partner.id}>
          <Box
            sx={{
              width: '100%',
              height: '42px',
              // marginLeft: '16px',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <PersonOutlineIcon sx={{}} />
            <Box
              sx={{
                width: '100%',
                height: '42px',
                marginLeft: '16px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography
                sx={{
                  fontWeight: '400',
                  color: '#1A1A24',
                  fontSize: '16px',
                  display: 'flex',
                  alignContent: 'flex-start',
                }}
              >
                {' '}
                {partner.name}
              </Typography>
              <Typography
                sx={{
                  fontWeight: '300',
                  color: '#1A1A24',
                  fontSize: '15px',
                  width: '100%',
                }}
              >
                {partner.function_role}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <Button
                sx={{
                  width: '20px',
                  height: '20px',
                }}
              >
                <MailOutlineIcon
                  sx={{
                    width: '15px',
                    height: '15px',
                    color: '#000000',
                  }}
                />
              </Button>
              <Button
                sx={{
                  width: '20px',
                  height: '20px',
                }}
              >
                <LocalPhoneOutlinedIcon
                  sx={{
                    width: '15px',
                    height: '15px',
                    color: '#000000',
                  }}
                />
              </Button>
              <Button
                sx={{
                  width: '20px',
                  height: '20px',
                }}
              >
                <LinkedInIcon
                  sx={{
                    width: '15px',
                    height: '15px',
                    color: '#000000',
                  }}
                />
              </Button>
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}

PartnersData.propTypes = {
  partners: PropTypes.oneOfType([PropTypes.array]),
};
PartnersData.defaultProps = {
  partners: [],
};
