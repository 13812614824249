import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { experimentalStyled as styled } from '@mui/material/styles';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import {
  Box,
  Button,
  CircularProgress,
  CssBaseline,
  Typography,
  Link,
  Breadcrumbs,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { ApprovalStartupsTypes, JourneyTypes } from '@src/libs/models/types';
import { ROLES } from '@src/libs/models/roles';
import { useDispatch, useSelector } from 'react-redux';
import enterpriseActions from '@src/store/modules/enterprise/actions';
import enterpriseSelectors from '@src/store/modules/enterprise/selectors';
import { RoutesMap } from '@src/routes/maps';
import authSelectors from '@src/store/modules/auth/selectors';
import ProposalModal from '../../Modal/Proposal';

function ElevationScroll(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const Img = styled('img')({
  width: '114px',
  height: '106px',
  filter: 'drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.12))',
  borderRadius: '6px',
});

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,

  window: PropTypes.func,
};

const viewLeftButtons = {
  [ApprovalStartupsTypes[3]]: 'recusar',
  [ApprovalStartupsTypes[4]]: 'requisitar assinatura do contrato',
  [ApprovalStartupsTypes[5]]: 'falar com empresa',
  [ApprovalStartupsTypes[6]]: 'falar com empresa',
  [ApprovalStartupsTypes[7]]: 'falar com a empresa',
};
const viewRightButtons = {
  [ApprovalStartupsTypes[3]]: 'aprovar',
  [ApprovalStartupsTypes[4]]: '',
  [ApprovalStartupsTypes[5]]: 'remover',
  [ApprovalStartupsTypes[6]]: 'reativar empresa',
  [ApprovalStartupsTypes[7]]: '',
};
const investorActionsChoose = {
  aprovar: 4,
  remover: 6,
  'reativar empresa': 3,
};
export default function SubHeader({
  enterprise,
  role,
  type,
  showBreadcrumbs,
  crumbText1,
  crumbText2,
  crumbRoute1,
  crumbRoute2,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loadingApproval = useSelector(enterpriseSelectors.getLoadingApproval);
  const success = useSelector(enterpriseSelectors.getSuccessEnterprise);
  const userActive = useSelector(authSelectors.getUser);
  const [currentButton, setCurrentButton] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (success) {
      dispatch(enterpriseActions.enterpriseReset());
      navigate(RoutesMap.CONSULTOR_STARTUP_APPROVAL);
    }
  }, [dispatch, navigate, success]);

  const textLeftRender = useMemo(() => {
    if (ROLES[3] === role && type) {
      return viewLeftButtons[type];
    }

    return viewLeftButtons[ApprovalStartupsTypes[5]];
  }, [role, type]);

  console.log(role, type)

  const textRightRender = useMemo(() => {
    if (ROLES[3] === role && type) {
      return viewRightButtons[type];
    }
    return 'FAZER PROPOSTA';
  }, [role, type]);

  const handleCancel = () => {
    setCurrentButton('left');
    if (viewLeftButtons[type] === 'recusar') {
      dispatch(
        enterpriseActions.enterpriseApprovalRequest({
          id: enterprise.id,
          user_consultant_id: userActive.id,
          status_id: 6,
          email: enterprise.email,
          journey: JourneyTypes[1],
        })
      );
    } else {
      window.location.href = `mailto:${enterprise.email}`;
    }
  };

  const handleSubmitOrOpen = () => {
    setCurrentButton('right');
    if (ROLES[3] === role) {
      dispatch(
        enterpriseActions.enterpriseApprovalRequest({
          id: enterprise.id,
          user_consultant_id: userActive.id,
          status_id: investorActionsChoose[viewRightButtons[type]],
          email: enterprise.email,
          journey: JourneyTypes[1],
        })
      );
    } else {
      setModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <Box sx={{ marginTop: '60px' }}>
      <CssBaseline />
      <Box
        sx={{
          backgroundColor: '#fff',
          padding: '10px 0 5px 100px',
        }}
      >
        {showBreadcrumbs ? (
          <Breadcrumbs
            aria-label="breadcrumb"
            sx={{ color: '#9999AB', fontSize: 12, mb: 1 }}
          >
            <Link underline="hover" color="inherit" href="/">
              Home
            </Link>
            <Link underline="hover" color="inherit" href={crumbRoute1}>
              {crumbText1}
            </Link>
            <Link
              underline="hover"
              color="inherit"
              href={crumbRoute2}
              aria-current="page"
              sx={{ pointerEvents: 'none' }}
            >
              {crumbText2}
            </Link>
          </Breadcrumbs>
        ) : null}
      </Box>

      <Box
        sx={{
          width: '100%',
          backgroundColor: '#fff',
          boxShadow: '0 4px 4px rgba(0,0,0,0.14)',
          paddingRight: 4,
          position: 'relative',
          padding: '20px',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            left: '100px',
            top: '30px',
            display: 'flex',
          }}
        >
          <Img alt="logo" src={enterprise?.logo} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              marginLeft: '27px',
            }}
          >
            <Typography
              sx={{
                fontSize: '22px',
                fontWeight: '700',
                color: '#1A1A24',
              }}
            >
              {enterprise?.fantasy_name}
            </Typography>

            <Typography
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                fontWeight: '400',
                color: '#888888',
                fontSize: '12px',
                border: '1px solid #D6D6D6',
                borderRadius: '100px',
                width: '79px',
                position: 'relative',
                marginBottom: '14px',
              }}
            >
              {enterprise?.sector}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gridGap: '30px',
          }}
        >
          <Button
            sx={{
              borderRadius: 28,
              background: '#F6F6F6',
              '&:hover': {
                backgroundColor: '#F6F6F6',
                opacity: [0.9, 0.8, 0.7],
              },
              borderColor: '#542EA6;',
              border: 1,
              color: '#542EA6;',
              width: 'auto',
              height: '35px',
              fontWeight: '400',
              padding: '10px',
              textTransform: 'uppercase',
            }}
            onClick={handleCancel}
          >
            {viewLeftButtons[type] === 'recusar' &&
            loadingApproval &&
            currentButton === 'left' ? (
              <CircularProgress size={30} />
            ) : (
              textLeftRender
            )}
          </Button>
          {textRightRender && (
            <Button
              sx={{
                borderRadius: 28,
                background: '#542EA6',
                '&:hover': {
                  backgroundColor: '#542EA6',
                  opacity: [0.9, 0.8, 0.7],
                },

                color: '#FFFFFF',
                width: 'auto',
                height: '35px',
                fontWeight: '400',
                textTransform: 'uppercase',
              }}
              onClick={handleSubmitOrOpen}
              disabled={loadingApproval}
            >
              {loadingApproval && currentButton === 'right' ? (
                <CircularProgress
                  size={30}
                  sx={{
                    color: '#fff',
                  }}
                />
              ) : (
                textRightRender
              )}
              <Box />
            </Button>
          )}
          <ProposalModal modalOpen={modalOpen} handleClose={handleCloseModal} />
        </Box>
      </Box>
    </Box>
  );
}

SubHeader.propTypes = {
  enterprise: PropTypes.oneOfType([PropTypes.object]).isRequired,
  role: PropTypes.string.isRequired,
  type: PropTypes.string,
  showBreadcrumbs: PropTypes.bool,
  crumbText1: PropTypes.string,
  crumbText2: PropTypes.string,
  crumbRoute1: PropTypes.string,
  crumbRoute2: PropTypes.string,
};

SubHeader.defaultProps = {
  type: '',
  showBreadcrumbs: false,
  crumbText1: '',
  crumbText2: '',
  crumbRoute1: '',
  crumbRoute2: '',
};
