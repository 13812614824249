import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';

const SubHeaderContainer = styled(Box)`
  padding: 24px 42px;
  background: #fafafa;
  border: 1px solid #dbdbdb;
`;

export default function SubHeader() {
  const { user } = useSelector((state) => state.auth);

  return (
    <SubHeaderContainer>
      <Typography sx={{ fontSize: '1.250rem' }}>
        Olá, <b>{user.full_name}</b>
      </Typography>
    </SubHeaderContainer>
  );
}
