import Home from '@src/pages/home/Home';
import SignIn from '@src/pages/investor/SignIn/SignIn';
import SignInStartup from '@src/pages/startup/SignIn/SignIn';
import SignInConsultor from '@src/pages/consultant/SignIn/SignIn';
import HomeStartup from '@src/pages/startup/Home';
import HomeIvestor from '@src/pages/investor/Home/Home';
import HomeConsultant from '@src/pages/consultant/Home';
import Onboarding from '@src/pages/investor/Onboarding/Onboarding';
import InvestorStartup from '@src/pages/investor/Startup';
import InvestorProposals from '@src/pages/consultant/InvestorProposals';
import Transactions from '@src/pages/investor/Transactions/Transactions';
import StepOne from '@src/pages/startup/Onboarding/PreOnboarding';
import OnboardingStartup from '@src/pages/startup/Onboarding/Onboarding';
import Propostas from '@src/pages/startup/Propostas/Propostas';
import PropostasRecebidas from '@src/pages/startup/PropostasRecebidas/PropostasRecebidas';
import StartupApproval from '@src/pages/consultant/StartupApproval';
import StartupProposals from '@src/pages/consultant/StartupProposals';
import PropostasAceitas from '@src/pages/startup/PropostasAceitas/PropostasAceitas';
import CompleteRegistration from '@src/pages/startup/CompleteRegistration';
import RegistrationStep1 from '@src/pages/startup/CompleteRegistration/RegistrationStep1';
import RegistrationStep2 from '@src/pages/startup/CompleteRegistration/RegistrationStep2';
import RegistrationStep3 from '@src/pages/startup/CompleteRegistration/RegistrationStep3';
import ContractSession from '@src/pages/startup/CompleteRegistration/ContractSession';
import { ROLES } from '@src/libs/models/roles';
import InvestorApproval from '@src/pages/consultant/InvestorApproval';
import { RoutesMap } from './maps';

const routes = [
  {
    component: Home,
    path: RoutesMap.BASE,
    isPrivate: false,
  },
  {
    component: SignInStartup,
    path: RoutesMap.LOGIN_STARTUP,
    isPrivate: false,
    role: ROLES[1],
  },
  {
    component: SignIn,
    path: RoutesMap.LOGIN_INVESTOR,
    isPrivate: false,
    role: ROLES[2],
  },
  {
    component: SignInConsultor,
    path: RoutesMap.LOGIN_CONSULTOR,
    isPrivate: false,
    role: ROLES[3],
  },
  {
    component: HomeStartup,
    path: RoutesMap.HOME_STARTUP,
    isPrivate: true,
    role: ROLES[1],
  },
  {
    component: HomeIvestor,
    path: RoutesMap.HOME_INVESTOR,
    isPrivate: true,
    role: ROLES[2],
  },
  {
    component: HomeConsultant,
    path: RoutesMap.HOME_CONSULTOR,
    isPrivate: true,
    role: ROLES[3],
  },
  {
    component: StartupApproval,
    path: RoutesMap.CONSULTOR_STARTUP_APPROVAL,
    isPrivate: true,
    role: ROLES[3],
  },
  {
    component: InvestorApproval,
    path: RoutesMap.CONSULTOR_INVESTOR_APPROVAL,
    isPrivate: true,
    role: ROLES[3],
  },
  {
    component: InvestorStartup,
    path: RoutesMap.CONSULTOR_STARTUP,
    isPrivate: true,
    role: ROLES[3],
  },
  {
    component: StartupProposals,
    path: RoutesMap.CONSULTOR_STARTUP_PROPOSALS,
    isPrivate: true,
    role: ROLES[3],
  },
  {
    component: InvestorProposals,
    path: RoutesMap.CONSULTOR_INVESTOR_PROPOSALS,
    isPrivate: true,
    role: ROLES[3],
  },
  {
    component: Onboarding,
    path: RoutesMap.SIGN_UP_CONSULTOR,
    isPrivate: false,
  },
  {
    component: InvestorStartup,
    path: RoutesMap.INVESTOR_STARTUP,
    isPrivate: true,
    role: ROLES[2],
  },
  {
    component: Transactions,
    path: RoutesMap.TRANSACTIONS,
    isPrivate: true,
    role: ROLES[2],
  },
  {
    component: Propostas,
    path: RoutesMap.PROPOSALS,
    isPrivate: true,
    role: ROLES[1],
  },
  {
    component: PropostasRecebidas,
    path: RoutesMap.PROPOSALS_RECEIVED,
    isPrivate: true,
    role: ROLES[1],
  },
  {
    component: PropostasAceitas,
    path: RoutesMap.ACCEPTED_PROPOSALS,
    isPrivate: true,
    role: ROLES[1],
  },
  {
    component: StepOne,
    path: RoutesMap.PRE_ONBOARD_STARTUP,
    isPrivate: false,
  },
  {
    component: OnboardingStartup,
    path: RoutesMap.ONBOARDING_STARTUP,
    isPrivate: false,
  },
  {
    component: OnboardingStartup,
    path: RoutesMap.ONBOARDING_STARTUP,
    isPrivate: false,
  },
  {
    component: CompleteRegistration,
    path: RoutesMap.COMPLETE_REGISTRATION,
    isPrivate: true,
    role: ROLES[1],
  },
  {
    component: RegistrationStep1,
    path: RoutesMap.REGISTRATION_STEP_1,
    isPrivate: true,
    role: ROLES[1],
  },
  {
    component: RegistrationStep2,
    path: RoutesMap.REGISTRATION_STEP_2,
    isPrivate: true,
    role: ROLES[1],
  },
  {
    component: RegistrationStep3,
    path: RoutesMap.REGISTRATION_STEP_3,
    isPrivate: true,
    role: ROLES[1],
  },
  {
    component: ContractSession,
    path: RoutesMap.CONTRACT_SESSION,
    isPrivate: true,
    role: ROLES[1],
  },
];

export default routes;
