import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0
  }
  *:focus {
    outline: 0;
  }

  html, body, #root {
    min-height: 100vh;
  }
  body {
    background: #F1F1F3 !important;
    -webkit-font-smoothing: antialiased !important;
    font-family: "EYInterstate", Arial, sans-serif !important;
    font-size: 16px;
    overflow-x: hidden;
  }
  body, input, button {
    font: "EYInterstate", Arial, sans-serif !important;
    font-size: 10px;
  }
  a {
    text-decoration: none;
  }
  ul {
    list-style: none;
  }
  button {
    cursor: pointer;
  }
  p, h1, h2 {
    text-align: start;
  }
`;
