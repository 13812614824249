import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { store } from '@src/store';
import PropTypes from 'prop-types';

import Picker from "../../Picker";

const SubHeaderContainer = styled(Box)`
  padding: 24px 42px;
  background: #fafafa;
  border: 1px solid #dbdbdb;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default function SubHeader({onChange}) {
  const { profile } = store.getState().user;
  return (
    <SubHeaderContainer>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={12} sm={6} md={3}>
          <Typography sx={{ fontSize: '1.250rem' }}>
            Olá, <b>{profile.full_name}</b>
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          sx={{
            marginTop: {
              sm: 0,
              md: 0,
              xs: 2,
            },
          }}
        >
          <Picker onChange={data => onChange(data)} />
        </Grid>
      </Grid>
    </SubHeaderContainer>
  );
}

SubHeader.propTypes = {
  onChange: PropTypes.func
};

SubHeader.defaultProps = {
  onChange: () => {},
}
