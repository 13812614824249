/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import Box from '@mui/material/Box';

import Chart from 'react-apexcharts';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Header from '@src/components/Header';
import Sidebar from '@src/components/Sidebar';
import ListProposalsInvestor from '@src/components/ListProposalsInvestor';
import SubHeader from '@src/components/SubHeader/sub-header-startup';
import ApiClient from '@src/services/api/api';
import { ApprovalStartupsTypes } from '@src/libs/models/types';
import { ROLES } from '@src/libs/models/roles';
import { RoutesMap } from '@src/routes/maps';
import { formatReal } from '@src/utils/functions';

import { Container, CircleColor } from './styles';

const options = {
  plotOptions: {
    radialBar: {
      size: undefined,
      inverseOrder: false,
      startAngle: 0,
      endAngle: 360,
      offsetX: 0,
      offsetY: 0,
      track: {
        show: true,
        startAngle: undefined,
        endAngle: undefined,
        background: '#C4C4C4',
        strokeWidth: '60%',
        opacity: 1,
        margin: 5,
      },
      dataLabels: {
        show: false,
      },
    },
  },
  colors: ['#542EA6'],
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
        },
        legend: {
          show: false,
        },
      },
    },
  ],
};

function StartupProposals() {
  const { id } = useParams();
  const [data, setData] = useState(false);
  const [percent, setPercent] = useState(0);
  const [proposals, setProposals] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchProposals = useCallback(async () => {
    ApiClient.fetchProposals({
      startup_id: id,
    })
      .then((response) => {
        if (response.data.proposals) {
          setProposals(response.data.proposals);
        }
      })
      .catch((error) => {
        toast.error(`Falha: ${error.response.data.message}`);
      });
  }, []);

  const fetchInfo = useCallback(async () => {
    setLoading(true);
    ApiClient.fetchStartupInfos({
      startup_id: id,
    })
      .then((response) => {
        if (response.data.startup) {
          setData(response.data.startup);
        }
        setLoading(false);
      })
      .catch((error) => {
        toast.error(`Falha: ${error.response.data.message}`);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchProposals();
    fetchInfo();
  }, []);

  useEffect(() => {
    if (data) {
      setPercent(((data.total_captured * 100) / data.total_value).toFixed(2));
    }
  }, [data]);

  return (
    <>
      <Header />

      <Sidebar isConsultant />
      {data ? (
        <SubHeader
          enterprise={data}
          role={ROLES[3]}
          type={ApprovalStartupsTypes[7]}
          crumbText1="Aprovação de Startups"
          crumbText2={data.fantasy_name}
          crumbRoute1={RoutesMap.CONSULTOR_STARTUP_APPROVAL}
          showBreadcrumbs
        />
      ) : null}
      <Box
        sx={{
          width: 'calc(100% - 60px)',
          position: 'relative',
          top: '60px',
          left: '60px',
        }}
      >
        <Container>
          {loading ? (
            <Box justifyContent="center" display="flex" p={3}>
              <CircularProgress size={30} />
            </Box>
          ) : (
            <div>
              {data ? (
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={5}>
                    <Card sx={{ marginBottom: 3 }}>
                      <CardContent>
                        <Typography
                          fontSize="18px"
                          sx={{ color: 'text.secondary' }}
                        >
                          Investimento
                        </Typography>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={5} md={5}>
                            <Chart
                              options={options}
                              series={[percent]}
                              type="radialBar"
                              height={200}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={7}
                            md={7}
                            justifyContent="center"
                            display="flex"
                            flexDirection="column"
                          >
                            <Typography
                              mb={2}
                              fontSize="14px"
                              sx={{ color: 'text.secondary' }}
                            >
                              Rodada: Pré-Seed
                            </Typography>
                            <Stack direction="row" alignItems="center" mb={2}>
                              <CircleColor color="#C4C4C4" />
                              <Typography
                                fontSize="14px"
                                sx={{ color: 'text.secondary' }}
                                textAlign="center"
                                ml={2}
                              >
                                {`Total: R$ ${formatReal(data.total_value)}`}
                              </Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center">
                              <CircleColor color="#542EA6" />
                              <Typography
                                fontSize="14px"
                                sx={{ color: 'text.secondary' }}
                                textAlign="center"
                                ml={2}
                              >
                                {`Captado: R$ ${formatReal(
                                  data.total_captured
                                )}`}
                              </Typography>
                            </Stack>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={12} md={7}>
                    <ListProposalsInvestor data={proposals} />
                  </Grid>
                </Grid>
              ) : null}
            </div>
          )}
        </Container>
      </Box>
    </>
  );
}

export default StartupProposals;
