/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/require-default-props */
import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ErrorIcon from '@mui/icons-material/Error';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import StartupRegistrationStepsEnum from '@src/constants/enums/registration-steps.enum';
import ApiClient from '@src/services/api/api';
import { setStep } from '@src/store/modules/startup/actions';
import { store } from '@src/store';
import StartupStatusEnum from '@src/constants/enums/startup-status.enum';
import { RoutesMap } from '@src/routes/maps';
import Header from '../../../components/Header';
import Sidebar from '../../../components/Sidebar';
import ProgressBar from '../../../components/ProgressBar/CompleteRegistrationProgress';

import {
  Container,
  SubHeaderBreadchrumbs,
  TitlePage,
  BoxContent,
} from './styles';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function CompleteProfile() {
  const { startup } = store.getState();
  const { startup_id } = useSelector((s) => s.auth.user);

  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLockedContract, setIslockedContract] = useState(true);
  const [isLockedAll,] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [loading, setLoading] = useState(false);

  const getStartupById = useCallback(async () => {
    ApiClient.fetchStartup(startup_id)
      .then((response) => {
        setUser(response.data.startups);
      })
      .catch((error) => {
        console.log(`Falha: ${error.response.data.message}`);
      });
  }, [startup_id]);

  useEffect(() => {
    getStartupById();
  }, [getStartupById]);

  useEffect(() => {
    if(user?.startup_status_id >= 4) {
      setIslockedContract(false);
      setIsEditable(false);
    } else {
      setIslockedContract(true);
      setIsEditable(true);
    }
  }, [user, dispatch]);

  const handleNextStep1 = () => {
    navigate('/registration-step1');
  };

  const handleNextStep2 = () => {
    navigate('/registration-step2');
  };

  const handleNextStep3 = () => {
    navigate('/registration-step3');
  };

  const handleContratct = () => {
    navigate('/contract-session');
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const data = {
        id: startup_id,
        registration_step_id: StartupRegistrationStepsEnum.INVESTIMENTO,
        startup_status_id: StartupStatusEnum.CADASTRO_COMPLETO,
      };
      const response = await ApiClient.updateStartupCompletRegistration(data);
      if (response.data.success === false) {
        alert(`Falha: ${response.data.message}`);
      }
      dispatch(setStep(StartupRegistrationStepsEnum.INVESTIMENTO));
      navigate(RoutesMap.HOME_STARTUP);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(`Falha: ${error.response.data.message}`);
    }
  };

  const showAccordionLeftIcon = (step) =>
    startup.step >= step ? (
      <CheckCircleIcon sx={{ color: 'green' }} />
    ) : (
      <ErrorIcon sx={{ color: '#C66900' }} />
    );

  const showOrNotEditButton = (step, nextStep) => (
    <Button
      onClick={nextStep}
      variant={startup.step >= step ? 'outlined' : 'contained'}
      color="primary"
      sx={{ ml: 'auto', py: '6px', px: '16px' }}
    >
      {startup.step >= step ? 'Editar' : 'Começar'}
    </Button>
  );

  const boxLockedContent = (title) => (
      <BoxContent sx={{ mt: 4, px: 2, py: 2 }} isLockedContract>
        <LockOutlinedIcon sx={{ color: '#9D9DAE' }} />
        <Typography
          sx={{
            fontSize: 20,
            fontWeight: 700,
            color: '#575762',
            ml: 3,
          }}
        >
          {title}
        </Typography>
      </BoxContent>
    )

  return (
    <>
      <Header />

      <Sidebar isStartup />

      <Box
        sx={{
          width: 'calc(100% - 60px)',
          position: 'relative',
          top: '60px',
          left: '60px',
        }}
      >
        <SubHeaderBreadchrumbs>
          <Breadcrumbs
            aria-label="breadcrumb"
            sx={{ color: '#9999AB', fontSize: 12, mb: 1 }}
          >
            <Link underline="hover" color="inherit" href="/">
              Home
            </Link>
            <Link
              underline="hover"
              color="inherit"
              href="/complete-registration"
            >
              Cadastro Completo
            </Link>
          </Breadcrumbs>

          <TitlePage>Cadastro Completo</TitlePage>

          <ProgressBar />
        </SubHeaderBreadchrumbs>
        <Container>
          <BoxContent sx={{ mt: 2, px: 2, py: 2 }}>
            {showAccordionLeftIcon(1)}
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 700,
                color: '#575762',
                ml: 3,
              }}
            >
              Dados Gerais e Operacionais
            </Typography>
            {showOrNotEditButton(1, handleNextStep1)}
          </BoxContent>
        
         {startup.step >= 1 ? (
           <BoxContent sx={{ mt: 4, px: 2, py: 2 }}>
            {showAccordionLeftIcon(2)}
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 700,
                color: '#575762',
                ml: 3,
              }}
            >
              Dados Financeiros
            </Typography>
            {showOrNotEditButton(2, handleNextStep2)}
          </BoxContent>) : (
            boxLockedContent('Dados Financeiros')
          )}
        {startup.step >= 2 ? (
          <BoxContent sx={{ mt: 4, px: 2, py: 2 }}>
            {showAccordionLeftIcon(3)}
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 700,
                color: '#575762',
                ml: 3,
              }}
            >
              Investimento
            </Typography>

            {showOrNotEditButton(3, handleNextStep3)}
          </BoxContent>): (
            boxLockedContent('Investimento')
          )}

          {isLockedContract || isLockedAll ? (
            boxLockedContent('Contrato')
          ) : (
            <BoxContent sx={{ mt: 4, px: 2, py: 2 }}>
             {user?.startup_status_id >= 5 ? (
                <CheckCircleIcon sx={{ color: 'green' }} />
              ) : (
                <ErrorIcon sx={{ color: '#C66900' }} />
              )}
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 700,
                  color: '#575762',
                  ml: 3,
                }}
              >
                Contrato
              </Typography>

              <Button
                onClick={handleContratct}
                variant={user?.startup_status_id >= 5 ? 'outlined' : 'contained'}
                sx={{ ml: 'auto', py: '6px', px: '16px' }}
              >
                {user?.startup_status_id >= 5 ? 'Editar' : 'Começar'}
              </Button>
            </BoxContent>
          )}
          {isEditable && (
            <Button
              variant="contained"
              color="primary"
              sx={{
                m: 'auto',
                position: 'realtive',
                top: '100px',
                py: '15px',
                px: '16px',
                display: 'flex',
              }}
              type="button"
              onClick={handleSave}
              disabled={loading}
            >
              {loading ? 'Carregando' : 'Salvar Alterações'}
            </Button>
          )}
        </Container>
      </Box>
    </>
  );
}

export default CompleteProfile;
