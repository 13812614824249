/* eslint-disable no-console */
import { takeLatest, call, put } from 'redux-saga/effects';
import toast from 'react-hot-toast';

import api from '@src/services/api/api';
import { JourneyTypes } from '@src/libs/models/types';
import * as enterpriseDetailTypes from './types';
import * as enterpriseDetailActions from './actions';

export function* enterpriseDetailRequest({ payload }) {
  try {
    const { id, journey } = payload;
    if (journey === JourneyTypes[1]) {
      const response = yield call(api.getStartupDetail, id);
      yield put(
        enterpriseDetailActions.enterpriseDetailSuccess(response.data.startup)
      );
    } else {
      const response = yield call(api.getInvestorDetail, id);
      yield put(
        enterpriseDetailActions.enterpriseDetailSuccess(response.data.investor)
      );
    }
  } catch (err) {
    yield put(enterpriseDetailActions.enterpriseDetailFailure());
  }
}

export function* enterpriseApprovalRequest({ payload }) {
  try {
    const { journey, ...rest } = payload;
    if (journey === JourneyTypes[1]) {
      const response = yield call(api.putConsultorApprovalStartup, rest);
      if (response.data.success) {
        yield put(enterpriseDetailActions.enterpriseApprovalSuccess());
      } else {
        yield put(enterpriseDetailActions.enterpriseApprovalFailure());
      }
    } else {
      const response = yield call(api.putConsultorApprovalInvestor, rest);
      if (response.data.investor.success) {
        yield put(enterpriseDetailActions.enterpriseApprovalSuccess());
      } else {
        yield put(enterpriseDetailActions.enterpriseApprovalFailure());
      }
    }
  } catch (err) {
    toast.error('Erro, entre em contato com o suporte técnico!');
    yield put(enterpriseDetailActions.enterpriseApprovalFailure());
  }
}

export function* enterpriseRemoveRequest({ payload }) {
  try {
    const response = yield call(api.deleteRemoveStartups, payload);
    yield put(enterpriseDetailActions.enterpriseRemoveSuccess(response.data));
  } catch (err) {
    yield put(enterpriseDetailActions.enterpriseRemoveFailure());
  }
}

export default function* watchEnterpriseDetail() {
  yield takeLatest(
    enterpriseDetailTypes.enterpriseDetailRequest,
    enterpriseDetailRequest
  );
  yield takeLatest(
    enterpriseDetailTypes.enterpriseApprovalRequest,
    enterpriseApprovalRequest
  );
  yield takeLatest(
    enterpriseDetailTypes.enterpriseRemoveRequest,
    enterpriseRemoveRequest
  );
}
