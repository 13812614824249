import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { LogoEY } from '@src/constants/imgs/';

function Footer() {
  return (
    <Box id="contato" sx={{ bgcolor: '#2E2E38' }}>
      <Container
        maxWidth="xl"
        fixed
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mt: { xs: 5, sm: 10 },
          py: 1,
        }}
      >
        <img src={LogoEY} alt="Logo EY" width="40px" />
        <Typography sx={{ color: '#C4C4CD', fontSize: '.750rem' }}>
          Copyright 2021 © EY. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
}

export default Footer;
