import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { Typography } from '@mui/material';
import { store } from '@src/store';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#569F59',
  },
}));

const steps = { 0: 0, 1: 33, 2: 66, 3: 100 };

export default function CompleteRegistrationProgressBar() {
  const { step } = store.getState().startup;

  return (
    <Box
      sx={{
        width: '331px',
        ml: 'auto',
        position: 'relative',
        bottom: '50px',
        mr: '5px',
      }}
    >
      <br />
      <Typography
        sx={{
          fontSize: '14px',
          color: '#5B5B5B',
          display: 'flex',
          justifyContent: 'flex-end',
          position: 'relative',
          bottom: '7px',
        }}
      >
        {`${step}/3 etapas completas`}
      </Typography>
      <BorderLinearProgress variant="determinate" value={steps[step]} />
    </Box>
  );
}
