/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const SubHeaderContainer = styled(Box)`
  padding: 24px 42px;
  background: #fafafa;
  border: 1px solid #dbdbdb;
`;

export default function SubHeader(props) {
  return (
    <SubHeaderContainer>
      <Typography sx={{ fontSize: '1.250rem', fontWeight: 700, }}>
        {props.content}
      </Typography>
    </SubHeaderContainer>
  );
}
