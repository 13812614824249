/* eslint-disable react/prop-types */
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from 'react-router-dom';
import { CardContent, Card, Grid } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import StartupDynamicCardProgress from '../ProgressBar/StartupDynamicCard';


export default function DynamicCard({ type, numberNotifications }) {
  const navigate = useNavigate();
  const completeRegistration = () => {
    navigate('/complete-registration');
  };

  const renderCompleteProfile = () => (
    <>
      <Typography
        sx={{
          color: '#1A1A24',
          fontSize: '14px',
          fontWeight: 700,
        }}
      >
        Perfil da empresa
      </Typography>
      <Box>
        <Typography
          sx={{
            fontSize: '14px',
            color: '#2E2E38',
          }}
        >
          Complete seu perfil para poder visualizar o conteúdo
        </Typography>

        <StartupDynamicCardProgress />
      </Box>
      <Button
        onClick={completeRegistration}
        variant="outlined"
        sx={{
          border: '1px solid #1353A6',
          color: '#1353A6',
          fontSize: '14px',
          py: '6px',
          px: '16px',
          alignSelf: 'center',
        }}
      >
        Complete seu perfil
      </Button>
    </>
  );

  const renderVerifyEmail = () => (
    <>
      <Grid container alignItems="center" display="flex">
        <Grid item xs={6} sm={6} md={6}>
          <Typography
            sx={{
              color: '#1A1A24',
              fontSize: '14px',
              fontWeight: 700,
            }}
          >
            Aguarde a validação do consultor
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6} justifyContent="center" display="flex">
          <Box
            sx={{
              width: '102px',
              height: '78px',
              color: '#888888;',
              position: 'relative',
            }}
          >
            <AccessTimeIcon
              sx={{
                width: '102px',
                height: '78px',
                color: '#888888;',
              }}
            />
            <Box
              sx={{
                color: '#ffffff',
                width: '35px',
                height: '35px',
                borderRadius: '20px',
                background: '#888888',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontWeight: 'bold',
                position: 'absolute',
                right: '0',
                bottom: '0',
              }}
            >
              {numberNotifications}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Typography
        sx={{
          fontSize: '14px',
          color: '#575762',
        }}
      >
        Você ainda não foi aprovado no Startup Showcase
      </Typography>
    </>
  );

  const renderSignContract = () => (
    <>
      <Typography
        sx={{
          color: '#1A1A24',
          fontSize: '14px',
          fontWeight: 700,
        }}
      >
        Assine o contrato
      </Typography>

      <Grid container alignItems="center" display="flex">
        <Grid item xs={6} sm={6} md={6}>
          <Typography
            sx={{
              fontSize: '14px',
              color: '#575762',
              width: '100px',
            }}
          >
            Seu cadastro foi aprovado com sucesso!
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6} justifyContent="center" display="flex">
          <CheckCircleOutlineIcon
            sx={{
              width: '102px',
              height: '78px',
              color: '#569F59;',
            }}
          />
        </Grid>
      </Grid>
      <Button
        onClick={completeRegistration}
        variant="outlined"
        sx={{
          border: '1px solid #1353A6',
          color: '#1353A6',
          fontSize: '14px',
          py: '6px',
          px: '16px',
          alignSelf: 'center',
        }}
      >
        Assine o contrato
      </Button>
    </>
  );

  const renderEditProfile = () => (
    <>
      <Box>
        <Typography
          sx={{
            color: '#1A1A24',
            fontSize: '14px',
            fontWeight: 700,
            marginBottom: '20px',
          }}
        >
          Atualizar Perfil
        </Typography>

        <Typography
          sx={{
            fontSize: '14px',
            color: '#575762',
            width: '244px',
          }}
        >
          Mantenha seu perfil atualizado e aumente suas chances de ser visto.
        </Typography>
      </Box>

      <Button
        onClick={completeRegistration}
        variant="outlined"
        sx={{
          border: '1px solid #1353A6',
          color: '#1353A6',
          fontSize: '14px',
          py: '6px',
          px: '16px',
          alignSelf: 'center',
        }}
      >
        Editar perfil
      </Button>
    </>
  );

  const renderContent = () => {
    switch (type) {
      case 3:
        return renderVerifyEmail();
      case 4:
        return renderSignContract();
      case 5:
        return renderEditProfile();
      default:
        return renderCompleteProfile();
    }
  };

  return (
    <Card>
      <CardContent
        sx={{
          padding: '24px 29px',
          minHeight: '260px',
          justifyContent: 'space-around',
          flexDirection: 'column',
          display: 'flex',
        }}
      >
        {renderContent()}
      </CardContent>
    </Card>
  );
}

DynamicCard.propTypes = {
  type: PropTypes.number,
  numberNotifications: PropTypes.number,
};

DynamicCard.defaultProps = {
  type: 0,
  numberNotifications: 1,
};
