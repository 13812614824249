/* eslint-disable no-console */

import api from '@src/services/api/api';
import StartupDatailsActions from './actions';

const { call, put, takeLatest } = require('redux-saga/effects');

function* StartupDetailsSaga({ startupId }) {
    try {
        const responseDataStartuoDetails = yield call(
            api.getStartupDetailComplete, {
                startupId,
            }
        );

        if (responseDataStartuoDetails) {
            const { data } = responseDataStartuoDetails;

            yield put({
                type: StartupDatailsActions.findStartupDetails,
                startupDetails: data.startupdata[0],
                startupPartners: data.startupdata[1].partners,
                startupShareholders: data.startupdata[2].shareholders,
                startupDres: data.startupdata[3].dres,
                startupBalances: data.startupdata[4].balances,
            });
        } else {
            yield put({
                type: StartupDatailsActions.findStartupDetails,
                startupDetails: [],
                startupPartners: [],
                startupShareholders: [],
                startupDres: [],
                startupBalances: [],
            });
        }
    } catch (error) {
        console.log(error);

        yield put({
            type: StartupDatailsActions.findStartupDetails,
            startupDetails: [],
            startupPartners: [],
            startupShareholders: [],
            startupDres: [],
            startupBalances: [],
        });
    }
}

export default [
    takeLatest(StartupDatailsActions.asyncfindStartupDetails, StartupDetailsSaga),
];