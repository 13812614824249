import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { formatReal } from '@src/utils/functions';

function Proposal({ isAcceptable, data, onClick, onClickRefuse }) {
  return (
    <Paper sx={{ flexDirection: 'column' }}>
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          justifyContent: 'center',
          borderBottom: '1px solid #EBEBEB',
          py: 7,
          mb: 7,
        }}
      >
        <Grid container>
          <Grid
            xs={12}
            sm={6}
            md={6}
            item
            display="flex"
            justifyContent="center"
            sx={{
              borderRight: {
                sm: '1px solid #D6D6D6',
                md: '1px solid #D6D6D6',
                xs: 0,
              },
            }}
          >
            <Typography
              sx={{
                fontSize: '30px',
                color: 'text.secondary',
                px: 1,
              }}
            >
              <span
                style={{
                  display: 'block',
                  fontSize: '16px',
                  color: 'text.secondary',
                }}
              >
                Valor ofertado
              </span>
              {`R$ ${formatReal(data?.amount)}`}
            </Typography>
          </Grid>

          <Grid
            xs={12}
            item
            sm={6}
            md={6}
            display="flex"
            justifyContent="center"
          >
            <Typography
              sx={{
                fontSize: '30px',
                color: 'text.secondary',
                px: 1,
              }}
            >
              <span
                style={{
                  display: 'block',
                  fontSize: '16px',
                  color: 'text.secondary',
                }}
              >
                Quantidade de cotas
              </span>
              {data?.quotas}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          display: { sm: 'grid', md: 'grid', xs: 'block' },
          gridTemplateColumns: 'repeat(2, auto)',
          gap: 1,
          gridTemplateAreas: `
          "aceitar recusar"
          "negociar negociar"
          "consulte consulte"`,
          justifyItems: 'center',
          pb: 8,
          textAlign: 'center',
        }}
      >
        {isAcceptable ? (
          <Button
            onClick={onClick}
            variant="contained"
            sx={{
              gridArea: 'aceitar',
              justifySelf: 'end',
              mb: 2,
              mr: 1,
              ml: 1,
            }}
          >
            Concluir
          </Button>
        ) : (
          <Button
            onClick={onClick}
            variant="contained"
            sx={{
              gridArea: 'aceitar',
              justifySelf: 'end',
              mb: 2,
              mr: 1,
              ml: 1,
            }}
          >
            Aceitar
          </Button>
        )}
        <Button
          onClick={onClickRefuse}
          variant="outlined"
          sx={{
            gridArea: 'recusar',
            justifySelf: 'baseline',
            mb: 2,
            mr: 1,
            ml: 1,
          }}
        >
          Recusar
        </Button>
        <Button
          variant="text"
          sx={{ gridArea: 'negociar' }}
          href={`mailto:${data.email}`}
        >
          Negociar proposta
        </Button>
        <Button
          variant="text"
          sx={{ gridArea: 'consulte' }}
          href="mailto:contato@br.ey.com"
        >
          Consulte a ey
        </Button>
      </Box>
    </Paper>
  );
}

Proposal.propTypes = {
  isAcceptable: PropTypes.bool,
  data: PropTypes.shape({
    amount: PropTypes.number,
    quotas: PropTypes.number,
    email: PropTypes.string,
  }).isRequired,
  onClickRefuse: PropTypes.func,
  onClick: PropTypes.func,
};

Proposal.defaultProps = {
  isAcceptable: false,
  onClickRefuse: () => {},
  onClick: () => {},
};

export default Proposal;
