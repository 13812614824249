import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import DropFile from '@src/components/DropFile';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { store } from '@src/store';
import ApiClient from '@src/services/api/api';

const schema = yup.object({
  number_employees: yup.number().required('Campo obrigatório'),
});

function TeamForm() {
  const { user } = store.getState().auth;
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const onRemoveFile = (index) => {
    setFiles(files.filter((_, key) => key !== index));
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const { length } = files;
      const fileNames = [];
      if (length > 0) {
        for (let i = 0; i <= length - 1; i += 1) {
          const formData = new FormData();
          formData.append('file', files[i], files[i].name);
          // eslint-disable-next-line no-await-in-loop
          const response = await ApiClient.upload({
            data: formData,
            userType: 'startup',
            id: user.startup_id,
          });
          fileNames.push(response.data.file_name);
        }
      }
      Object.assign(data, {
        employees_resume_file: fileNames,
        id: user.startup_id,
      });

      const response = await ApiClient.updateStartupCompletRegistration(data);
      if (response.data.success === false) {
        alert(`Falha: ${response.response.data.message}`);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(`Falha: ${error.response.data.message}`);
    }
  };

  const handleAddFile = (file) => {
    if (file.type !== 'application/pdf') return;
    setFiles((oldArray) => [...oldArray, file]);
  };

  useEffect(() => {
    setLoading(true);
    ApiClient.fetchStartupGeneralData(user.startup_id)
      .then((response) => {
        reset({
          number_employees: response.data.startupdata[0].number_employees,
        });
      })
      .catch((error) => {
        alert(`Falha: ${error.response.data.message}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [reset, user.startup_id]);

  return (
    <Box
      sx={{ mt: 8, px: 4 }}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box>
        <Stack direction="row" spacing={3} sx={{ mt: 2 }}>
          <Controller
            name="number_employees"
            defaultValue=""
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                margin="none"
                sx={{ mb: 5 }}
                fullWidth
                label="Número de funcionários"
                autoFocus
                color="primary"
                type="number"
                error={!!errors.number_employees}
                helperText={errors?.number_employees?.message}
              />
            )}
          />
        </Stack>
        <Stack direction="row" spacing={3} sx={{ mt: 2 }}>
          <Typography
            id="transition-modal-title"
            variant="h6"
            component="h2"
            sx={{
              fontSize: '16px',
              fontWeight: '400',
              color: '#575762',
              position: 'relative',
              bottom: '20px',
            }}
          >
            Currículo dos Founders e Executivos da Empresa
          </Typography>
        </Stack>
        <Grid container>
          <Grid item xs={12} sm={8} md={4}>
            <DropFile
              files={files}
              onDelete={(index) => onRemoveFile(index)}
              title="Solte os documentos aqui"
              callback={handleAddFile}
              styleTitle={{ fontSize: '20px' }}
              backgroundColor="#F1F1F3"
              minHeight="191px"
              subtitle="Apenas arquivos PDF"
              multiple
              accept={{ 'text/*': ['.pdf'] }}
            />
          </Grid>
        </Grid>
      </Box>

      <Button
        variant="outlined"
        color="primary"
        sx={{
          m: 'auto',
          mt: 10,
          position: 'realtive',
          bottom: '20px',
          py: '15px',
          px: '16px',
          display: 'flex',
        }}
        disabled={loading}
        type="submit"
      >
        {loading ? 'Carregando' : 'Salvar Alterações'}
      </Button>
    </Box>
  );
}

export default TeamForm;
