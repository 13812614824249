import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#542EA6',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#1353A6',
      contrastText: '#ffffff',
    },
    error: {
      main: '#ED282E',
      contrastText: '#fff',
    },
    info: {
      main: '#FFE600',
      contrastText: '#fff',
    },
    neutral: {
      main: '#D6D6D6',
      contrastText: '#2E2E38',
    },
    text: {
      primary: '#575762',
      secondary: '#1A1A24',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
  },
  typography: {
    h5: {
      fontWeight: 600,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '40px',
          fontWeight: '400',
          lineHeight: '1.5rem',
          textTransform: 'uppercase',
          fontSize: '0.875rem',
        },
        sizeMedium: {
          padding: '14px 32px',
        },
        sizeSmall: {
          padding: '6px 16px',
        },
        outlined: {},
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: ' #ffffff',
          boxShadow: '0px 1px 3px 0px #0000001f',
        },
        rounded: {
          borderRadius: '8px',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: '"EYInterstate", sans-serif',
          fontWeightMedium: 400,
          fontStyle: 'normal',
        },
        h3: {
          fontSize: '1.875rem',
          fontWeight: '300',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'rgba(26, 26, 36, 0.78)',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: 'rgba(26, 26, 36, 0.98)',
          borderColor: 'rgba(0, 0, 0, 0.23)',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          borderColor: 'rgba(26, 26, 36, 0.78)',
        },
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 60,
    },
  },
  shadows: [
    'none',
    '0 4px 24px 0 rgba(34,41,47,.1)',
    '0 4px 24px 0 rgba(34,41,47,.1)',
    '0 4px 24px 0 rgba(34,41,47,.1)',
    '0 4px 24px 0 rgba(34,41,47,.1)',
    '0 4px 24px 0 rgba(34,41,47,.1)',
    '0 4px 24px 0 rgba(34,41,47,.1)',
    '0 4px 24px 0 rgba(34,41,47,.1)',
    '0 4px 24px 0 rgba(34,41,47,.1)',
    '0 4px 24px 0 rgba(34,41,47,.1)',
    '0 4px 24px 0 rgba(34,41,47,.1)',
    '0 4px 24px 0 rgba(34,41,47,.1)',
    '0 4px 24px 0 rgba(34,41,47,.1)',
    '0 4px 24px 0 rgba(34,41,47,.1)',
    '0 4px 24px 0 rgba(34,41,47,.1)',
    '0 4px 24px 0 rgba(34,41,47,.1)',
    '0 4px 24px 0 rgba(34,41,47,.1)',
    '0 4px 24px 0 rgba(34,41,47,.1)',
    '0 4px 24px 0 rgba(34,41,47,.1)',
    '0 4px 24px 0 rgba(34,41,47,.1)',
    '0 4px 24px 0 rgba(34,41,47,.1)',
    '0 4px 24px 0 rgba(34,41,47,.1)',
    '0 4px 24px 0 rgba(34,41,47,.1)',
    '0 4px 24px 0 rgba(34,41,47,.1)',
    '0 4px 24px 0 rgba(34,41,47,.1)',
  ],
});

export default theme;
