/* eslint-disable react/prop-types */
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import LogoutIcon from '@mui/icons-material/Logout';

import DashboardIcon from '@src/assets/images/dashboard-icon.svg';
import ImportExportIcon from '@src/assets/images/import_export-icon.svg';
import RelatorioIcon from '@src/assets/images/relatorio-icon.svg';
import { RoutesMap } from '@src/routes/maps';
import { useDispatch } from 'react-redux';
import * as startupApprovalActions from '@src/store/modules/consultorStartupApproval/actions';
import * as investorApprovalActions from '@src/store/modules/consultorInvestorApproval/actions';

import authActions from '@src/store/modules/auth/actions';

const IconButtonStyled = styled(IconButton)({
  width: '100%',
  minHeight: '60px',
  color: 'text.secondary',
  borderRadius: '0px',
});

export default function Sidebar({ isStartup, isInvestor, isConsultant }) {
  const dispatch = useDispatch();
  const handleSignOut = () => {
    dispatch(authActions.signOut());
  };
  const onClick = () => {
    dispatch(startupApprovalActions.startupApprovalReset());
    dispatch(investorApprovalActions.investorApprovalReset());
  };
  if (isStartup) {
    return (
      <Box
        sx={{
          position: 'fixed',
          left: '0',
          top: '60px',
          width: '60px',
          height: 'calc(100vh - 60px)',
          background: '#ffffff',
          boxShadow: '0px 6px 6px rgba(0, 0, 0, 0.15)',
          zIndex: '1000',
        }}
      >
        <List
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Tooltip title="Home">
            <IconButtonStyled
              component={Link}
              to={RoutesMap.HOME_STARTUP}
              aria-label="LogoutIcon"
            >
              <img src={DashboardIcon} alt="Icone Dashboard" />
            </IconButtonStyled>
          </Tooltip>

          <Tooltip title="Relatórios">
            <IconButtonStyled aria-label="LogoutIcon">
              <img src={RelatorioIcon} alt="Icone Import Export" />
            </IconButtonStyled>
          </Tooltip>

          <Tooltip title="Propostas">
            <IconButtonStyled
              component={Link}
              to="/Propostas"
              aria-label="LogoutIcon"
            >
              <img src={ImportExportIcon} alt="Icone Import Export" />
            </IconButtonStyled>
          </Tooltip>

          <Tooltip title="Sair">
            <IconButtonStyled
              aria-label="LogoutIcon"
              sx={{ mt: 'auto' }}
              onClick={handleSignOut}
            >
              <LogoutIcon sx={{ color: 'text.secondary' }} />
            </IconButtonStyled>
          </Tooltip>
        </List>
      </Box>
    );
  }
  if (isInvestor) {
    return (
      <Box
        sx={{
          position: 'fixed',
          left: '0',
          top: '60px',
          width: '60px',
          height: 'calc(100vh - 60px)',
          background: '#ffffff',
          boxShadow: '0px 6px 6px rgba(0, 0, 0, 0.15)',
          zIndex: '1000',
        }}
      >
        <List
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Tooltip title="Home">
            <IconButtonStyled
              component={Link}
              to={RoutesMap.HOME_INVESTOR}
              aria-label="LogoutIcon"
            >
              <img src={DashboardIcon} alt="Icone Dashboard" />
            </IconButtonStyled>
          </Tooltip>

          <Tooltip title="Transações">
            <IconButtonStyled
              component={Link}
              to={RoutesMap.TRANSACTIONS}
              aria-label="LogoutIcon"
            >
              <img src={ImportExportIcon} alt="Icone Import Export" />
            </IconButtonStyled>
          </Tooltip>

          <Tooltip title="Sair">
            <IconButtonStyled
              aria-label="LogoutIcon"
              sx={{ mt: 'auto' }}
              onClick={handleSignOut}
            >
              <LogoutIcon sx={{ color: 'text.secondary' }} />
            </IconButtonStyled>
          </Tooltip>
        </List>
      </Box>
    );
  }
  if (isConsultant) {
    return (
      <Box
        sx={{
          position: 'fixed',
          left: '0',
          top: '60px',
          width: '60px',
          height: 'calc(100vh - 60px)',
          background: '#ffffff',
          boxShadow: '0px 6px 6px rgba(0, 0, 0, 0.15)',
          zIndex: '1000',
        }}
      >
        <List
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Tooltip title="Home">
            <IconButtonStyled
              component={Link}
              onClick={onClick}
              to={RoutesMap.HOME_CONSULTOR}
              aria-label="LogoutIcon"
            >
              <img src={DashboardIcon} alt="Icone Dashboard" />
            </IconButtonStyled>
          </Tooltip>

          <Tooltip title="Sair">
            <IconButtonStyled
              aria-label="LogoutIcon"
              sx={{ mt: 'auto' }}
              onClick={handleSignOut}
            >
              <LogoutIcon sx={{ color: 'text.secondary' }} />
            </IconButtonStyled>
          </Tooltip>
        </List>
      </Box>
    );
  }
}
