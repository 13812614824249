/* eslint-disable default-param-last */

import StartupDatailsActions from './actions';

const INITIAL_STATE = {
    startupId: null,
    startupDetails: [],
    startupPartners: [],
    startupShareholders: [],
    startupDres: [],
    startupBalances: [],
};

const StartupDatailsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case StartupDatailsActions.findStartupDetails:
            return {
                ...state,
                startupDetails: action.startupDetails,
                startupPartners: action.startupPartners,
                startupShareholders: action.startupShareholders,
                startupDres: action.startupDres,
                startupBalances: action.startupBalances,
            };

        case StartupDatailsActions.setStartupId:
            return {
                ...state,
                startupId: action.startupId,
            };

        default:
            return {...state };
    }
};

export default StartupDatailsReducer;