import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import TitleSection from '@src/components/LandingPage/TitleSection';

import EyRole from '@src/assets/images/EY_role_img.svg';
import EyRoleMobile from '@src/assets/images/EY_role_img_mobile.svg';

const CustomTypography = styled(Typography)(({ theme }) => ({
  display: 'block',
  color: theme.palette.primary.contrastText,
  lineHeight: '1.75em',
  marginBottom: '16px',
}));

const BoxBg = styled(Box)`
  img {
    max-width: 100%;
  }
`;

export function NossoPapel() {
  return (
    <Box
      id="nosso-papel"
      sx={{
        background: 'linear-gradient(90deg, #fff 50%, #542EA6 50%)',
        my: { xs: 5, sm: 10 },
        position: 'relative',
      }}
    >
      <Container
        maxWidth="xl"
        fixed
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          py: 10,
          bgcolor: 'primary.main',
          borderRadius: { xs: '0', sm: '40px' },
          boxShadow: { xs: 'none', sm: '-6vw 0px #542ea6', md: 'none' },
          position: 'relative',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            justifyContent: 'space-evenly',
            ml: { xs: 'unset', lg: 'auto' },
            mr: { sm: 'auto', lg: 'unset' },
            width: { xs: '100%', sm: '90%', md: '80%', lg: '50%' },
          }}
        >
          <TitleSection
            Section="NossoPapel"
            TitleText="E qual o papel da EY?"
          />
          <CustomTypography>
            O papel da EY no Startup Showcase será garantir que as startups
            consigam, de forma simples e estruturada, divulgar sua necessidade
            de capital através da plataforma para centenas de investidores
            corporativos, fundos de investimento e family offices, assim como,
            garantir para os investidores que encontrarão startups inovadoras,
            de impacto e relevantes para serem investidas e nos ajudar a
            construir um mundo de negócios melhor!A EY também oferecerá serviços
            de validação do plano de negócios para as startups e estará
            disponível para consultoria pontuais.
          </CustomTypography>
          <CustomTypography>
            Para os investidores, a EY poderá ser acionada para realizar uma due
            diligence ou até mesmo ser o braço de M&#38;A da sua empresa!
          </CustomTypography>
        </Box>
        <BoxBg
          sx={{
            display: { xs: 'block', sm: 'none', lg: 'block' },
            position: { xs: 'relative', lg: 'absolute' },
            top: { xs: '0', lg: '-15px', xl: '-42px' },
            left: { xs: '0', lg: '50px', xl: '100px' },
            width: '100%',
          }}
        >
          <img src={EyRole} alt="Ilustração jovem no notebook" />
        </BoxBg>
      </Container>
      <Box
        sx={{
          display: { xs: 'none', sm: 'block', lg: 'none' },
          position: 'absolute',
          bottom: '-45px',
          right: '0',
          background: `url(${EyRoleMobile}) no-repeat center`,
          width: '353px',
          height: '466px',
        }}
      />
    </Box>
  );
}

export default NossoPapel;
