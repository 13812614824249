import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

function Hero() {
  return (
    <Container
      maxWidth="xl"
      fixed
      sx={{
        py: { xs: 10, sm: 15, lg: 40 },
        minHeight: { xs: '600px', sm: '965px' },
      }}
    >
      <Typography
        variant="h1"
        sx={{
          fontSize: { xs: '2.5rem', sm: '4.5rem', md: '5rem' },
          color: 'text.secondary',
        }}
      >
        Startup
        <b style={{ display: 'block' }}>Showcase</b>
      </Typography>
      <Typography
        sx={{
          maxWidth: { xs: '70%', sm: '430px' },
          fontSize: { xs: '1rem', sm: '1.375rem' },
          mt: { xs: 1, sm: 2, md: 3 },
        }}
      >
        Uma plataforma que conecta <b>Investidores</b> e <b>Startups</b> de
        forma simples, rápida e com toda a <b>expertise EY.</b>
      </Typography>
    </Container>
  );
}

export default Hero;
