/* eslint-disable react/prop-types */

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TabPanel from './Tabs';
import TabsBalance from './TabsBalance';

export default function SimpleAccordion({ dataDropDow }) {
  const { startupDres, startupBalances } = dataDropDow;

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <Accordion sx={{ boxShadow: 'none' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            style={{ fontSize: 16, fontWeight: 700, color: '#1A1A24' }}
          >
            DRE
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TabPanel dataDRE={startupDres} />
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ boxShadow: 'none' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 700,
              color: '#1A1A24',
            }}
          >
            Balanço
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TabsBalance data={startupBalances} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
