import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';

import { ToolBarStyled, BoxLogo } from './style';

import LogoEY from '../../../assets/images/logo-ey.svg';

export default function PrimarySearchAppBar() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <ToolBarStyled>
          <BoxLogo>
            <img src={LogoEY} alt="Logo EY" />
          </BoxLogo>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              display: {
                xs: 'none',
                sm: 'block',
                fontSize: 16,
                marginRight: 'auto',
              },
            }}
          >
            Startup <b>Showcase</b>
          </Typography>
        </ToolBarStyled>
      </AppBar>
    </Box>
  );
}
