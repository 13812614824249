/* eslint-disable react/prop-types */
import { Button } from '@mui/material';
import { useState } from 'react';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import InputMask from 'react-input-mask';
import { useDispatch } from 'react-redux';
import ApiClient from '@src/services/api/api';

import DropFile from '@src/components/DropFile';
import { store } from '@src/store';
import {
  preRegisterCompany,
  preRegisterUser,
} from '@src/store/modules/preRegister/actions';

const schema = yup
  .object({
    fantasy_name: yup.string().required('Campo obrigatório'),
    reason_social: yup.string().required('Campo obrigatório'),
    cnpj: yup.string().required('Campo obrigatório'),
    address: yup.string().required('Campo obrigatório'),
    website: yup
      .string()
      .url('Formato válido: http://website.com.br')
      .required('Campo obrigatório'),
    description: yup.string().required('Campo obrigatório'),
  })
  .required();

// eslint-disable-next-line react/prop-types
function CadastroEmpresa({ setActiveStep }) {
  const dispatch = useDispatch();
  const { company, user } = store.getState().preRegister;

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: company,
  });

  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState({});

  const onSubmit = async (data) => {
    setLoading(true);
    if (file.path) {
      const formData = new FormData();
      formData.append('file', file, file.name);
      const response = await ApiClient.upload({
        data: formData,
        userType: 'startup',
        id: 'logo',
      });
      Object.assign(data, {
        logo: response.data.file_name,
        user_id: user.user.id,
      });
    }
    Object.assign(data, { user_id: user.user.id });
    dispatch(preRegisterCompany(data));

    let response;
    try {
      if (!user.startup?.id) {
        response = await ApiClient.signUpStartup(data);
        if (response.data.success)
          dispatch(
            preRegisterUser({
              user,
              startup: response.data?.startup.newStartup,
            })
          );
      } else {
        const assignData = { ...data };
        Object.assign(assignData, { id: user.startup.id });
        response = await ApiClient.updateStartup(assignData);
        dispatch(preRegisterUser({ user, startup: response.data?.startup }));
      }
      setLoading(false);
      if (response.data.success) {
        setActiveStep(2);
      } else {
        alert(`Falha: ${response.data?.message}`);
      }
    } catch (error) {
      alert(`Falha: ${error}`);
      setLoading(false);
    }
  };

  return (
    <Box sx={{ mt: 8, px: 4 }}>
      <Typography variant="h5" sx={{ mb: 6, fontSize: 20, fontWeight: 400 }}>
        Cadastre sua Empresa
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container mb={5}>
          <Grid item xs={12} sm={8} md={4}>
            <FormLabel>Logo da empresa</FormLabel>
            <DropFile
              files={file}
              onDelete={() => setFile({})}
              title="Adicionar Logo"
              subtitle="Arraste e solte arquivos png ou jpeg"
              callback={(v) => setFile(v)}
            />
          </Grid>
        </Grid>
        <Stack direction="row" spacing={3} sx={{ mt: 2 }}>
          <Controller
            name="reason_social"
            defaultValue=""
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                sx={{ mb: 5 }}
                margin="none"
                fullWidth
                label="Razão Social"
                autoFocus
                color="primary"
                error={!!errors.reason_social}
                helperText={errors?.reason_social?.message}
              />
            )}
          />
          <Controller
            name="cnpj"
            defaultValue=""
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <InputMask
                mask="99.999.999/9999-99"
                value={field.value}
                onChange={field.onChange}
                ref={field.ref}
              >
                <TextField
                  sx={{ mb: 5 }}
                  margin="none"
                  fullWidth
                  label="Insira seu CNPJ"
                  autoFocus
                  color="primary"
                  error={!!errors.cnpj}
                  helperText={errors?.cnpj?.message}
                />
              </InputMask>
            )}
          />
          <Controller
            name="fantasy_name"
            defaultValue=""
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                sx={{ mb: 5 }}
                margin="none"
                fullWidth
                label="Nome Fantasia"
                autoFocus
                color="primary"
                error={!!errors.fantasy_name}
                helperText={errors?.fantasy_name?.message}
              />
            )}
          />
        </Stack>
        <Controller
          name="address"
          defaultValue=""
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              sx={{ mb: 5 }}
              margin="none"
              fullWidth
              label="Endereço"
              autoFocus
              color="primary"
              error={!!errors.address}
              helperText={errors?.address?.message}
            />
          )}
        />
        <Controller
          name="website"
          defaultValue=""
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              sx={{ mb: 5 }}
              margin="none"
              fullWidth
              label="Website"
              autoFocus
              color="primary"
              error={!!errors.website}
              helperText={errors?.website?.message}
            />
          )}
        />
        <Controller
          name="description"
          defaultValue=""
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              sx={{ mb: 5 }}
              margin="none"
              fullWidth
              multiline
              rows={5}
              label="Descreva sua empresa"
              autoFocus
              color="primary"
              error={!!errors.description}
              helperText={errors?.description?.message}
            />
          )}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            mt: 7,
            mb: 12,
          }}
        >
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
          >
            {loading ? 'Carregando' : 'Próximo'}
          </Button>
        </Box>
      </form>
    </Box>
  );
}

export default CadastroEmpresa;
