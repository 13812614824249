import Typography from '@mui/material/Typography';

// eslint-disable-next-line react/prop-types
export default function Label({ text }) {
  return (
    <Typography
      style={{
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '20px',
        color: '#888888',
        textTransform: 'uppercase',
        border: '1px solid #D6D6D6',
        borderRadius: '20px',
        padding: '0 12px',
        gridArea: 'label',
      }}
    >
      {text}
    </Typography>
  );
}
