import { combineReducers } from 'redux';

import startupDetails from '@src/pages/investor/Startup/reducer';
import investitorHome from '@src/pages/investor/Home/reducer';
import auth from './auth/reducer';
import user from './user/reducer';
import preRegister from './preRegister/reducer';
import investorApproval from './consultorInvestorApproval/reducer';
import startupApproval from './consultorStartupApproval/reducer';
import enterprise from './enterprise/reducer';
import transactionsProposals from './transactions/reducer';
import startup from './startup/reducer';

export default combineReducers({
  auth,
  user,
  preRegister,
  investorApproval,
  startupApproval,
  enterprise,
  startupDetails,
  investitorHome,
  transactionsProposals,
  startup,
});
