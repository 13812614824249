/* eslint-disable default-param-last */
import produce from 'immer';
import * as startupApprovalTypes from './types';

const startupsApprovalsFilter = (startups, type_id) =>
  startups?.filter((startup) => startup.startup_status_id === type_id);
const INITIAL_STATE = {
  loading: false,
  loadingStartupActive: false,
  completePreRegistrationStartups: [],
  pendingRegistrationStartups: [],
  completeRegistrationStartups: [],
  approvedStartups: [],
  signedContractStartups: [],
  removedStartups: [],
};
export default function consultorStartupApprovalReducer(
  state = INITIAL_STATE,
  action
) {
  return produce(state, (draft) => {
    switch (action.type) {
      case startupApprovalTypes.startupApprovalRequest: {
        draft.loading = true;
        break;
      }
      case startupApprovalTypes.startupApprovalSuccess: {
        draft.completePreRegistrationStartups = startupsApprovalsFilter(
          action.payload.data.startups,
          1
        );
        draft.pendingRegistrationStartups = startupsApprovalsFilter(
          action.payload.data.startups,
          2
        );
        draft.completeRegistrationStartups = startupsApprovalsFilter(
          action.payload.data.startups,
          3
        );
        draft.approvedStartups = startupsApprovalsFilter(
          action.payload.data.startups,
          4
        );
        draft.signedContractStartups = startupsApprovalsFilter(
          action.payload.data.startups,
          5
        );
        draft.removedStartups = startupsApprovalsFilter(
          action.payload.data.startups,
          6
        );
        draft.loading = false;
        break;
      }
      case startupApprovalTypes.startupApprovalFailure: {
        draft.loading = false;
        break;
      }
      case startupApprovalTypes.startupApprovalReset: {
        draft.loading = false;
        draft.completePreRegistrationStartups = [];
        draft.pendingRegistrationStartups = [];
        draft.completeRegistrationStartups = [];
        draft.approvedStartups = [];
        draft.signedContractStartups = [];
        draft.removedStartups = [];
        break;
      }
      case '@auth/SIGN_OUT': {
        draft.loading = false;
        draft.completePreRegistrationStartups = [];
        draft.pendingRegistrationStartups = [];
        draft.completeRegistrationStartups = [];
        draft.approvedStartups = [];
        draft.signedContractStartups = [];
        draft.removedStartups = [];
        break;
      }
      default:
    }
  });
}
