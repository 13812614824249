import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

export const Container = styled(Stack)`
  display: flex;
  flex-direction: column;
  padding: 32px 40px;
`;

export const CircleColor = styled(Box)`
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background-color: ${(props) => props.color};
`;
