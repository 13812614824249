import { useState } from 'react';
import { Link, useNavigate, generatePath } from 'react-router-dom';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tabs from '@mui/material/Tabs';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import Label from '@src/components/common/Label';
import { CircularProgress } from '@mui/material';
import { formatReal } from '@src/utils/functions';
import { RoutesMap } from '@src/routes/maps';
import { CardInfoRow } from './styles';

const TABS = [
  {
    index: 0,
    name: 'received',
  },
  {
    index: 1,
    name: 'accepted',
  },
  {
    index: 2,
    name: 'finish',
  },
  {
    index: 3,
    name: 'refused',
  },
];

function ItemList({ item, onClick, tab }) {
  const renderButton = () => {
    switch (tab) {
      case 2:
        return (
          <Stack direction="row" spacing={3} sx={{ ml: 'auto' }}>
            {item.termsheet ? (
              <Link
                target="_blank"
                to={item.url_docs + item.termsheet}
                download
              >
                <Button
                  variant="contained"
                  size="small"
                  color="neutral"
                  startIcon={<CloudDownloadIcon />}
                  sx={{
                    borderRadius: '9px',
                    textTransform: 'none',
                  }}
                >
                  Termsheet
                </Button>
              </Link>
            ) : null}
            {item.zip ? (
              <Link target="_blank" to={item.url_docs + item.zip} download>
                <Button
                  variant="contained"
                  size="small"
                  color="neutral"
                  startIcon={<CloudDownloadIcon />}
                  sx={{
                    borderRadius: '9px',
                    textTransform: 'none',
                  }}
                >
                  Documentos
                </Button>
              </Link>
            ) : null}
          </Stack>
        );
      case 3:
        return (
          <Button
            variant="outlined"
            size="small"
            sx={{ ml: 'auto' }}
            href={`mailto:${item.email}`}
          >
            Falar com a empresa
          </Button>
        );
      default:
        return (
          <Button
            onClick={() => onClick(item)}
            variant="outlined"
            size="small"
            endIcon={<AddIcon />}
            sx={{ ml: 'auto' }}
          >
            Ver mais
          </Button>
        );
    }
  };

  return (
    <CardInfoRow>
      <Grid container display="flex">
        <Grid item xs={12} sm={6} md={4} display="flex" alignItems="center">
          <img
            src={item.logo}
            alt="Logo"
            aria-hidden="true"
            width="44px"
            height="44px"
          />
          <Typography
            sx={{
              fontSize: 18,
              color: '#1A1A24',
              ml: 3,
              mr: 5,
            }}
          >
            {item.fantasy_name}
          </Typography>

          <Label text={item.sector} />
        </Grid>
        <Grid item xs={12} sm={6} md={5} display="flex" alignItems="center">
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              marginLeft: {
                xs: 0,
                sm: 2,
                md: 0,
              },
            }}
          />
          <Box sx={{ ml: 4 }}>
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: 300,
                textTransform: 'uppercase',
              }}
            >
              Cotas
            </Typography>
            <Typography sx={{ color: '#1A1A24' }}>{item.quotas}</Typography>
          </Box>
          <Box sx={{ ml: 10 }}>
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: 300,
                textTransform: 'uppercase',
              }}
            >
              Oferta
            </Typography>
            <Typography sx={{ color: '#1A1A24' }}>{`R$ ${formatReal(
              item.amount
            )}`}</Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          display="flex"
          alignItems="center"
          sx={{
            marginTop: {
              xs: 2,
              sm: 2,
              md: 0,
            },
          }}
        >
          {renderButton()}
        </Grid>
      </Grid>
    </CardInfoRow>
  );
}

ItemList.propTypes = {
  item: PropTypes.shape({
    fantasy_name: PropTypes.string,
    logo: PropTypes.string,
    amount: PropTypes.number,
    quotas: PropTypes.number,
    sector: PropTypes.string,
    termsheet: PropTypes.string,
    url_docs: PropTypes.string,
    zip: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  tab: PropTypes.number.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ px: 2, pb: 2 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function ListProposals({ data, loading }) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const navigate = useNavigate();

  const onClickHandle = (item) => {
    let route = '';
    if (value === 0) {
      route = RoutesMap.PROPOSALS_RECEIVED;
    } else {
      route = RoutesMap.ACCEPTED_PROPOSALS;
    }

    const path = generatePath(route, {
      id: item.id,
      investor: item.investor_id,
    });

    navigate(path);
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Detalhes propostas"
          variant="scrollable"
        >
          <Tab label="Recebidas" {...a11yProps(0)} />
          <Tab label="Aceitas" {...a11yProps(1)} />
          <Tab label="Investimento Concluído" {...a11yProps(2)} />
          <Tab label="Recusadas" {...a11yProps(3)} />
        </Tabs>
      </Box>
      {loading ? (
        <Box justifyContent="center" display="flex" p={3}>
          <CircularProgress size={30} />
        </Box>
      ) : (
        <>
          {TABS.map((tab) => (
            <TabPanel value={value} index={tab.index} key={tab.index}>
              {data[tab.name].length ? (
                <>
                  {data[tab.name].map((item) => (
                    <ItemList
                      key={item.id}
                      item={item}
                      onClick={onClickHandle}
                      tab={value}
                    />
                  ))}
                </>
              ) : (
                <Typography pt={3} fontWeight="300">
                  Sem itens para exibir.
                </Typography>
              )}
            </TabPanel>
          ))}
        </>
      )}
    </>
  );
}

ListProposals.propTypes = {
  data: PropTypes.shape({
    received: PropTypes.arrayOf(PropTypes.shape({})),
    accepted: PropTypes.arrayOf(PropTypes.shape({})),
    finish: PropTypes.arrayOf(PropTypes.shape({})),
    refused: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ListProposals;
