import { useState, useEffect, useCallback } from 'react';
import toast from 'react-hot-toast';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import ApiClient from '@src/services/api/api';
import { useSelector } from 'react-redux';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import ProposalStatusEnum from '@src/constants/enums/proposal-status.enum';
import Header from '../../../components/Header';
import Sidebar from '../../../components/Sidebar';
import SummaryProposals from '../../../components/SummaryProposals';
import ListProposals from '../../../components/ListProposals';

import {
  Container,
  SubHeaderBreadchrumbs,
  TitlePage,
  BoxContent,
} from './styles';

function Propostas() {
  const [data, setData] = useState({
    received: [],
    accepted: [],
    finish: [],
    refused: [],
  });
  const [summary, setSummary] = useState({});
  const [loadingList, setLoadingList] = useState(false);
  const [loadingSummary, setLoadingSummary] = useState(false);
  const profile = useSelector((state) => state.user.profile);

  const fetchProposals = useCallback(async () => {
    setLoadingList(true);
    ApiClient.fetchProposals({
      startup_id: profile.startup_id,
    })
      .then((response) => {
        if (response.data.proposals) {
          const modelData = {
            received: response.data.proposals.filter(
              (res) => res.proposal_status_id === ProposalStatusEnum.RECEBIDA
            ),
            accepted: response.data.proposals.filter(
              (res) => res.proposal_status_id === ProposalStatusEnum.ACEITA
            ),
            finish: response.data.proposals.filter(
              (res) =>
                res.proposal_status_id ===
                ProposalStatusEnum.INVESTIMENTO_CONCLUIDO
            ),
            refused: response.data.proposals.filter(
              (res) => res.proposal_status_id === ProposalStatusEnum.RECUSADA
            ),
          };
          setData(modelData);
        }
        setLoadingList(false);
      })
      .catch((error) => {
        toast.error(`Falha: ${error.response.data.message}`);
        setLoadingList(false);
      });
  }, [profile.startup_id]);

  const fetchSummary = useCallback(async () => {
    setLoadingSummary(true);
    ApiClient.fetchProposalsStartupDetails({
      id: profile.startup_id,
    })
      .then((response) => {
        if (response.data.startupDetails) {
          setSummary(response.data.startupDetails[0]);
        }
        setLoadingSummary(false);
      })
      .catch((error) => {
        toast.error(`Falha: ${error.response.data.message}`);
        setLoadingSummary(false);
      });
  }, [profile.startup_id]);

  useEffect(() => {
    fetchProposals();
    fetchSummary();
  }, [fetchProposals, fetchSummary]);

  return (
    <>
      <Header />
      <Sidebar isStartup />

      <Box
        sx={{
          width: 'calc(100% - 60px)',
          position: 'relative',
          top: '60px',
          left: '60px',
        }}
      >
        <SubHeaderBreadchrumbs>
          <Breadcrumbs
            aria-label="breadcrumb"
            sx={{ color: '#9999AB', fontSize: 12, mb: 1 }}
          >
            <Link underline="hover" color="inherit" href="/">
              Home
            </Link>
            <Link
              underline="hover"
              color="inherit"
              href="/propostas"
              aria-current="page"
              sx={{ pointerEvents: 'none' }}
            >
              Propostas
            </Link>
          </Breadcrumbs>

          <TitlePage>Propostas</TitlePage>
        </SubHeaderBreadchrumbs>

        <Container spacing={4}>
          <BoxContent>
            <SummaryProposals summary={summary} loading={loadingSummary} />
          </BoxContent>

          <BoxContent>
            <ListProposals data={data} loading={loadingList} />
          </BoxContent>
        </Container>
      </Box>
    </>
  );
}

export default Propostas;
