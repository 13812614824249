const InvestitorHomeActions = {
  findLastSeenStartupsAction: 'FIND_LAST_SEEN_STARTUPS_ACTION',
  findAllStartupsAction: 'FIND_ALL_STARTUPS_ACTION',

  asyncFindLastSeenStartupsAction: 'ASYNC_FIND_LAST_SEEN_STARTUPS_ACTION',
  asyncFindAllStartupsAction: 'ASYNC_FIND_ALL_STARTUPS_ACTION',
  asyncSaveHistoryStartupLastSeen: 'ASYNC_SAVE_HISTORY_STARTUP_LAST_SEEN',
};

export default InvestitorHomeActions;
