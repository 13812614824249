/* eslint-disable no-console */

import api from '@src/services/api/api';
import InvestitorHomeActions from './actions';

const { call, put, takeLatest } = require('redux-saga/effects');

function* InvestitorHomeLastSeenStartupsSaga({ userId }) {
  try {
    const responseDataLastSeenStartups = yield call(api.getInvestorLastViews, {
      userId,
    });

    if (responseDataLastSeenStartups) {
      const { data } = responseDataLastSeenStartups;

      yield put({
        type: InvestitorHomeActions.findLastSeenStartupsAction,
        lastSeenStartups: data.lastStartupViews,
      });
    } else {
      yield put({
        type: InvestitorHomeActions.findLastSeenStartupsAction,
        lastSeenStartups: [],
      });
    }
  } catch (error) {
    console.log(error);

    yield put({
      type: InvestitorHomeActions.findLastSeenStartupsAction,
      lastSeenStartups: [],
    });
  }
}

function* InvestitorHomeFindAllStartupsSaga() {
  try {
    const responseDataFindAllStartups = yield call(api.getStartups);

    if (responseDataFindAllStartups) {
      const { data } = responseDataFindAllStartups;

      yield put({
        type: InvestitorHomeActions.findAllStartupsAction,
        startups: data.startups,
      });
    } else {
      yield put({
        type: InvestitorHomeActions.findAllStartupsAction,
        startups: [],
      });
    }
  } catch (error) {
    console.log(error);

    yield put({
      type: InvestitorHomeActions.findAllStartupsAction,
      startups: [],
    });
  }
}

function* InvestitorSaveHistoryStartupLastSeenSaga({ investorId, startupId }) {
  try {
    yield call(api.postInvestorSaveHistoryStartupLastSeen, {
      investorId,
      startupId,
    });
  } catch (error) {
    console.log(error);
  }
}

export default [
  takeLatest(
    InvestitorHomeActions.asyncFindLastSeenStartupsAction,
    InvestitorHomeLastSeenStartupsSaga
  ),
  takeLatest(
    InvestitorHomeActions.asyncFindAllStartupsAction,
    InvestitorHomeFindAllStartupsSaga
  ),
  takeLatest(
    InvestitorHomeActions.asyncSaveHistoryStartupLastSeen,
    InvestitorSaveHistoryStartupLastSeenSaga
  ),
];
