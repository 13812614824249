import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import Check from '@src/assets/images/check.svg';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #1A1A2424',
  borderRadius: '6px',
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '376px',
};

function ModalConfirm({
  open,
  handleClose,
  onSubmit,
  loading,
  success,
  actionSuccess,
  msgSuccess,
  txtBtnSuccess,
  message,
}) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          ...style,
          width: {
            sm: '550px',
            md: '670px',
            xs: '340px',
          },
        }}
      >
        {success ? (
          <>
            <img src={Check} alt="check" />
            <Typography
              sx={{
                fontSize: '22px',
                color: 'text.secondary',
                mb: 7,
                mt: 3,
                px: 2,
              }}
              textAlign="center"
            >
              {msgSuccess}
            </Typography>
            <Button variant="outlined" onClick={actionSuccess}>
              {txtBtnSuccess}
            </Button>
          </>
        ) : (
          <>
            <Typography
              sx={{ fontSize: '22px', color: 'text.secondary', mb: 7, px: 2 }}
              textAlign="center"
            >
              {message}
            </Typography>
            <Stack direction="row" spacing={4}>
              <Button
                variant="contained"
                onClick={onSubmit}
                sx={{ width: '129px' }}
              >
                {loading ? (
                  <CircularProgress
                    size={15}
                    sx={{
                      color: '#fff',
                    }}
                  />
                ) : (
                  'Aceitar'
                )}
              </Button>
              <Button variant="outlined" onClick={handleClose}>
                Cancelar
              </Button>
            </Stack>
          </>
        )}
      </Box>
    </Modal>
  );
}

ModalConfirm.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  success: PropTypes.bool,
  message: PropTypes.string.isRequired,
  msgSuccess: PropTypes.string,
  actionSuccess: PropTypes.func,
  txtBtnSuccess: PropTypes.string,
};

ModalConfirm.defaultProps = {
  loading: false,
  success: false,
  msgSuccess: '',
  actionSuccess: () => {},
  txtBtnSuccess: '',
};

export default ModalConfirm;
