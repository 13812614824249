import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// import Button from '@mui/material/Button';
// import AddIcon from '@mui/icons-material/Add';
import Header from '@src/components/Header';
import SubHeader from '@src/components/SubHeader/sub-header-consultant';
import Sidebar from '@src/components/Sidebar';
import EnterpriseList from '@src/components/EnterpriseList';
import { ApprovalStartupsTypes, JourneyTypes } from '@src/libs/models/types';
import TabPanel from '@src/components/TabPanel';
import { a11yProps } from '@src/utils/functions';
import { useDispatch, useSelector } from 'react-redux';
import * as investorApprovalActions from '@src/store/modules/consultorInvestorApproval/actions';

import investorApprovalSelectors from '@src/store/modules/consultorInvestorApproval/selectors';
import { Container } from './styles';

export default function InvestorApproval() {
  const [value, setValue] = useState(0);
  const loading = useSelector(investorApprovalSelectors.getLoading);
  const preRegistration = useSelector(
    investorApprovalSelectors.getCompeltePreRegistration
  );
  const approved = useSelector(investorApprovalSelectors.getApproved);

  const removed = useSelector(investorApprovalSelectors.getRemoved);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(investorApprovalActions.investorApprovalRequest());
  }, [dispatch]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Header />
      <Sidebar isConsultant />
      <Box
        sx={{
          width: 'calc(100% - 60px)',
          position: 'relative',
          top: '60px',
          left: '60px',
        }}
      >
        <SubHeader content="Aprovação de Investidores" />
        <Container spacing={5}>
          <Box
            sx={{
              borderBottom: 1,

              borderColor: 'divider',
              position: 'relative',
              bottom: '30px',
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="aprovação-de-startups"
            >
              <Tab label="Pré-cadastro Completo" {...a11yProps(0)} />
              <Tab label="Aprovadas" {...a11yProps(1)} />
              <Tab label="Removidas" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <EnterpriseList
              loading={loading}
              registrationType={ApprovalStartupsTypes[1]}
              enterprises={preRegistration}
              journey={JourneyTypes[2]}
            />
          </TabPanel>

          <TabPanel value={value} index={1}>
            <EnterpriseList
              loading={loading}
              registrationType={ApprovalStartupsTypes[4]}
              enterprises={approved}
              journey={JourneyTypes[2]}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <EnterpriseList
              loading={loading}
              registrationType={ApprovalStartupsTypes[6]}
              enterprises={removed}
              journey={JourneyTypes[2]}
            />
          </TabPanel>

          {/* <Box sx={{ display: 'flex' }}>
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              sx={{
                display: 'flex',
                position: 'relative',
                top: '15px',
                mx: 'auto',
                fontWeight: 700,
                fontSize: '14px',
              }}
            >
              <AddIcon />
              ver mais
            </Button>
          </Box> */}
        </Container>
      </Box>
    </>
  );
}
