import styled from 'styled-components';
import Toolbar from '@mui/material/Toolbar';

const ToolBarStyled = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between',
});

const BoxLogo = styled.div`
  display: flex;
  margin-right: 24px;
`;

export { ToolBarStyled, BoxLogo };
