/* eslint-disable default-param-last */
import produce from 'immer';

const INITIAL_STATE = {
  step: 0,
  startups: null,
};

export default function startup(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@startup/SET_STEP': {
        draft.step = action.payload;
        break;
      }
      case '@startup/SET_STARTUPS': {
        draft.startups = action.payload;
        break;
      }
      case '@auth/SIGN_OUT': {
        draft.step = 0;
        break;
      }
      default:
    }
  });
}
