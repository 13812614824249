/* eslint-disable react/no-array-index-key */
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import Header from '@src/components/Header';
import Sidebar from '@src/components/Sidebar';
import SubHeader from '@src/components/SubHeader/sub-header-consultant';
import StartupApprovalCard from '@src/components/common/Cards/Consultant/StartupApproval';
import InvestorApprovalCard from '@src/components/common/Cards/Consultant/InvestorApproval';
import { useMediaQuery } from '@mui/material';

const Container = styled(Stack)`
  display: flex;
  flex-direction: column;
  padding: 32px 40px;
`;

function Home() {
  const md = useMediaQuery('(max-width: 900px)');
  return (
    <>
      <Header />

      <Sidebar isConsultant />

      <Box
        sx={{
          width: 'calc(100% - 60px)',
          position: 'relative',
          top: '60px',
          left: '60px',
        }}
      >
        <SubHeader content="Olá, Consultor EY" />

        <Container spacing={5}>
          <Box>
            <Typography
              sx={{
                fontSize: '1.250rem',
                color: 'text.secondary',
                mb: 3,
                fontWeight: 700,
              }}
            >
              Análise de Cadastros
            </Typography>

            <Grid container>
              <Grid
                item
                xs={12}
                xd={6}
                sm={6}
                md={4}
                lg={3}
                marginRight={md ? 0 : 2}
                paddingLeft={md && 0}
                mt={md && 1.5}
              >
                <StartupApprovalCard />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} mt={md && 1.5}>
                <InvestorApprovalCard />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default Home;
