import { useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { CircularProgress } from '@mui/material';
import format from 'date-fns/format';
import ptBr from 'date-fns/locale/pt-BR';
import { formatReal } from '@src/utils/functions';
import { CardInfo } from './styles';

import IconMetaCapt from '../../assets/images/fi_trending-up.svg';
import IconParticipacao from '../../assets/images/fi_percent.svg';
import IconTerminoCapt from '../../assets/images/fi_calendar.svg';
import IconValorInvest from '../../assets/images/fi_dollar-sign.svg';
import IconValuation from '../../assets/images/fi_star.svg';
import IconValorReserv from '../../assets/images/fi_lock.svg';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function SummaryProposals({ summary, loading }) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Detalhes propostas"
          scrollButtons="auto"
          variant="scrollable"
        >
          <Tab label="DETALHES" {...a11yProps(0)} />
          <Tab label="PACOTE DE DOCUMENTOS JURÍDICOS" {...a11yProps(1)} />
        </Tabs>
      </Box>
      {loading ? (
        <Box justifyContent="center" display="flex" p={3}>
          <CircularProgress size={30} />
        </Box>
      ) : (
        <>
          <TabPanel value={value} index={0}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={2}>
                <CardInfo>
                  <img
                    src={IconMetaCapt}
                    alt="Icone Meta Captação"
                    aria-hidden="true"
                    height="65"
                  />
                  <Typography
                    sx={{
                      fontWeight: 300,
                      fontSize: 12,
                      textTransform: 'uppercase',
                      mb: 0.5,
                    }}
                  >
                    Meta de Captação
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      color: 'primary.main',
                      fontSize: '1rem',
                    }}
                  >
                    {`R$ ${formatReal(summary?.target_capture)}`}
                  </Typography>
                </CardInfo>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <CardInfo>
                  <img
                    src={IconParticipacao}
                    alt="Icone Meta Captação"
                    aria-hidden="true"
                    height="65"
                  />
                  <Typography
                    sx={{
                      fontWeight: 300,
                      fontSize: 12,
                      textTransform: 'uppercase',
                      mb: 0.5,
                    }}
                  >
                    Participação
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      color: 'primary.main',
                      fontSize: '1rem',
                    }}
                  >
                    {`${summary.sales_percentage}%`}
                  </Typography>
                </CardInfo>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <CardInfo>
                  <img
                    src={IconTerminoCapt}
                    alt="Icone Meta Captação"
                    aria-hidden="true"
                    height="65"
                  />
                  <Typography
                    sx={{
                      fontWeight: 300,
                      fontSize: 12,
                      textTransform: 'uppercase',
                      mb: 0.5,
                    }}
                  >
                    Término de Captação
                  </Typography>
                  {summary.capture_end_date ? (
                    <Typography
                      sx={{
                        fontWeight: 700,
                        color: 'primary.main',
                        fontSize: '1rem',
                      }}
                    >
                      {format(new Date(summary.capture_end_date), 'PPP', {
                        locale: ptBr,
                      })}
                    </Typography>
                  ) : null}
                </CardInfo>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <CardInfo>
                  <img
                    src={IconValorInvest}
                    alt="Icone Meta Captação"
                    aria-hidden="true"
                    height="65"
                  />
                  <Typography
                    sx={{
                      fontWeight: 300,
                      fontSize: 12,
                      textTransform: 'uppercase',
                      mb: 0.5,
                    }}
                  >
                    Valor investido
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      color: 'primary.main',
                      fontSize: '1rem',
                    }}
                  >
                    {`R$ ${formatReal(summary.invested_value)}`}
                  </Typography>
                </CardInfo>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <CardInfo>
                  <img
                    src={IconValuation}
                    alt="Icone Meta Captação"
                    aria-hidden="true"
                    height="65"
                  />
                  <Typography
                    sx={{
                      fontWeight: 300,
                      fontSize: 12,
                      textTransform: 'uppercase',
                      mb: 0.5,
                    }}
                  >
                    Valuation
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      color: 'primary.main',
                      fontSize: '1rem',
                    }}
                  >
                    {`R$ ${formatReal(summary.evaluation)}`}
                  </Typography>
                </CardInfo>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <CardInfo>
                  <img
                    src={IconValorReserv}
                    alt="Icone Meta Captação"
                    aria-hidden="true"
                    height="65"
                  />
                  <Typography
                    sx={{
                      fontWeight: 300,
                      fontSize: 12,
                      textTransform: 'uppercase',
                      mb: 0.5,
                    }}
                  >
                    Valor reservado
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      color: 'primary.main',
                      fontSize: '1rem',
                    }}
                  >
                    {`R$ ${formatReal(summary.reserved_value)}`}
                  </Typography>
                </CardInfo>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            Pacotes de documentos jurídicos
          </TabPanel>
        </>
      )}
    </>
  );
}

SummaryProposals.propTypes = {
  summary: PropTypes.shape({
    target_capture: PropTypes.number,
    sales_percentage: PropTypes.number,
    capture_end_date: PropTypes.string,
    invested_value: PropTypes.number,
    evaluation: PropTypes.number,
    reserved_value: PropTypes.number,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default SummaryProposals;
