import styled, { css } from 'styled-components';

import Box from '@mui/material/Box';

const Container = styled.main`
  display: flex;
  flex-direction: column;
  padding: 32px 40px;
`;

const SubHeaderBreadchrumbs = styled.div`
  padding: 24px 42px;
  background: #fafafa;
  border: 1px solid #dbdbdb;
  height: 100px;
`;

const TitlePage = styled.h1`
  color: #1a1a24;
  font-size: 20px;
  font-weight: 700;
`;

const BoxContent = styled(Box)`
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
  border-radius: 9px;
  display: flex;
  align-items: center;
  padding: 22px;

  ${(props) =>
    props.isLocked &&
    css`
      background: none;
      box-shadow: none;
      border: 1px solid #d6d6d6;
    `};
`;

export { Container, SubHeaderBreadchrumbs, TitlePage, BoxContent };
