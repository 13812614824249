import { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Tab,
  Tabs,
  Box,
  Grid,
  Button,
  Divider,
} from '@mui/material';
import PropTypes from 'prop-types';
import ProposalStatusEnum from '@src/constants/enums/proposal-status.enum';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import TabPanel from '@src/components/TabPanel';
import Label from '@src/components/common/Label';
import { a11yProps, formatReal } from '@src/utils/functions';
import Receipt from '@src/assets/images/receipt.svg';
import PointOfSale from '@src/assets/images/point-of-sale.svg';
import { CardInfoRow } from './styles';

const TABS = [
  {
    index: 0,
    name: 'received',
  },
  {
    index: 1,
    name: 'accepted',
  },
  {
    index: 2,
    name: 'finish',
  },
  {
    index: 3,
    name: 'refused',
  },
];

function ItemList({ item, showLabel }) {
  return (
    <CardInfoRow>
      <Grid container display="flex">
        <Grid item xs={12} sm={6} md={5} display="flex">
          <img
            src={item.logo}
            alt="Logo"
            aria-hidden="true"
            width="82px"
            height="82px"
            style={{ borderRadius: '6px' }}
          />
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            sx={{
              pl: 2,
            }}
          >
            <Typography
              sx={{
                fontSize: 18,
                color: '#1A1A24',
              }}
            >
              {item.fantasy_name}
            </Typography>
            {showLabel ? (
              <Box display="flex">
                <Label text={item.sector} />
              </Box>
            ) : null}
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          display="flex"
          alignItems="center"
          justifyContent="space-around"
        >
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              marginLeft: {
                xs: 0,
                sm: 2,
                md: 0,
              },
            }}
          />
          <Box textAlign="center">
            <img src={Receipt} alt="Receipt" aria-hidden="true" />
            <Typography
              sx={{
                fontSize: '14px',
                color: '#515162',
                fontWeight: '700',
              }}
              textAlign="center"
            >
              {item.quotas}
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',
                color: '#515162',
                fontWeight: '700',
              }}
            >
              cotas
            </Typography>
          </Box>
          <Box textAlign="center">
            <img src={PointOfSale} alt="PointOfSale" aria-hidden="true" />
            <Typography
              sx={{
                fontSize: '14px',
                color: '#515162',
                fontWeight: '700',
              }}
              textAlign="center"
            >
              Total
            </Typography>
            <Typography
              sx={{ fontSize: '14px', color: '#515162', fontWeight: '700' }}
            >{`R$ ${formatReal(item.amount)}`}</Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          sx={{
            marginTop: {
              xs: 2,
              sm: 2,
              md: 0,
            },
          }}
        >
          <a target="_blank" href={item.url_docs + item.termsheet} download rel="noreferrer">
            <Button
              variant="contained"
              size="small"
              color="neutral"
              startIcon={<CloudDownloadIcon />}
              sx={{
                borderRadius: '9px',
                textTransform: 'none',
              }}
            >
              Termsheet
            </Button>
          </a>
        </Grid>
      </Grid>
    </CardInfoRow>
  );
}

ItemList.propTypes = {
  item: PropTypes.shape({
    fantasy_name: PropTypes.string,
    logo: PropTypes.string,
    amount: PropTypes.number,
    quotas: PropTypes.number,
    sector: PropTypes.string,
    termsheet: PropTypes.string,
    url_docs: PropTypes.string,
    zip: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  showLabel: PropTypes.bool.isRequired,
};

function ListProposalsInvestor({ data, showLabel }) {
  const [value, setValue] = useState(0);
  const [proposals, setProposals] = useState({
    received: [],
    accepted: [],
    finish: [],
    refused: [],
  });
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const modelData = {
      received: data.filter(
        (res) => res.proposal_status_id === ProposalStatusEnum.RECEBIDA
      ),
      accepted: data.filter(
        (res) => res.proposal_status_id === ProposalStatusEnum.ACEITA
      ),
      finish: data.filter(
        (res) =>
          res.proposal_status_id === ProposalStatusEnum.INVESTIMENTO_CONCLUIDO
      ),
      refused: data.filter(
        (res) => res.proposal_status_id === ProposalStatusEnum.RECUSADA
      ),
    };
    setProposals(modelData);
  }, [data]);

  return (
    <Card>
      <CardContent sx={{ padding: 0 }}>
        <Typography p="22px" fontSize="18px" sx={{ color: 'text.secondary' }}>
          Propostas
        </Typography>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
          }}
        >
          <Tabs
            sx={{ px: 3 }}
            value={value}
            onChange={handleChange}
            aria-label="aprovação-de-startups"
            variant="scrollable"
          >
            <Tab label="Pendentes" {...a11yProps(0)} />
            <Tab label="Aceitas" {...a11yProps(1)} />
            <Tab label="investimento concluído" {...a11yProps(2)} />
            <Tab label="recusadas" {...a11yProps(3)} />
          </Tabs>
        </Box>
        <Box
          sx={{
            mr: 2,
            height: '55vh',
            px: 3,
            overflow: 'hidden',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: '5px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#575762',
              borderRadius: '9px',
            },
          }}
        >
          {TABS.map((tab) => (
            <TabPanel value={value} index={tab.index} key={tab.index}>
              {proposals[tab.name].length ? (
                <>
                  {proposals[tab.name].map((item) => (
                    <ItemList item={item} key={item.id} showLabel={showLabel} />
                  ))}
                </>
              ) : (
                <Typography pt={3} fontWeight="300">
                  Sem itens para exibir.
                </Typography>
              )}
            </TabPanel>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
}

ListProposalsInvestor.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showLabel: PropTypes.bool,
};

ListProposalsInvestor.defaultProps = {
  showLabel: false,
};

export default ListProposalsInvestor;
