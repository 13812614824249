/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable react/destructuring-assignment */

import { Component } from 'react';
import Chart from 'react-apexcharts';

class RadialBar extends Component {
  constructor(props) {
    super(props);

    const { capturedPercent } = props;

    this.state = {
      options: {
        plotOptions: {
          radialBar: {
            size: undefined,
            inverseOrder: false,
            startAngle: 0,
            endAngle: 360,
            offsetX: 0,
            offsetY: 0,
            background: '#8999',

            track: {
              show: true,
              startAngle: undefined,
              endAngle: undefined,
              background: '#EEEEEE',
              strokeWidth: '60%',

              opacity: 1,
              margin: 5,
              dropShadow: {
                enabled: false,
                top: 0,
                left: 0,
                blur: 3,
                opacity: 0.5,
              },
            },
            dataLabels: {
              show: true,
              name: {
                show: true,
                fontSize: '22px',
                fontFamily: undefined,
                color: undefined,
                offsetY: -10,
              },
              value: {
                show: true,
                fontSize: '16px',
                fontFamily: undefined,
                color: undefined,
                offsetY: 16,
                formatter(val) {
                  return `${val}%`;
                },
              },

              total: {
                show: true,
                label: '',
                color: '#373d3f',
                formatter(w) {
                  return `${
                    w.globals.seriesTotals.reduce((a, b) => a + b, 0) /
                    w.globals.series.length
                  }% captado`;
                },
              },
            },
          },
        },
      },

      series: [capturedPercent || 0,],
    };
  }

  render() {
    return (
      <div>
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="radialBar"
          width={380}
        />
      </div>
    );
  }
}

export default RadialBar;
