import PropTypes from 'prop-types';
import { Box, Grid, Stack, CircularProgress } from '@mui/material';
import Filter from '@src/components/common/Buttons/Filter';
import Sector from '@src/components/common/Select/sector';
// import Stage from '@src/components/common/Select/stage';
import Invoicing from '@src/components/common/Select/invoicing';
import SelectOrderBy from '@src/components/common/Select/order-by';
import CardEnterprise from '@src/components/common/Cards/Consultant/CardEnterprise';

function EnterpriseList({ loading, enterprises, registrationType, journey }) {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mb: 4,
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          sx={{ flexWrap: 'wrap', position: 'relative', bottom: '30px' }}
        >
          <Filter />

          {/* <Stage /> */}
          <Sector />
          <Invoicing />
        </Stack>
        <Box sx={{ position: 'relative', bottom: '20px' }}>
          <SelectOrderBy />
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {loading && <CircularProgress />}
      </Box>

      <Grid
        container
        sx={{ position: 'relative', bottom: '30px' }}
        spacing={{ xs: '20px' }}
      >
        {!loading &&
          enterprises?.map((enterprise) => (
            <Grid
              key={Math.random() * 10}
              item
              xs={12}
              sm={6}
              md={3}
              lg={3}
              xl={3}
            >
              <CardEnterprise
                enterprise={enterprise}
                type={registrationType}
                journey={journey}
              />
            </Grid>
          ))}
      </Grid>
    </>
  );
}

EnterpriseList.propTypes = {
  loading: PropTypes.bool.isRequired,
  registrationType: PropTypes.string.isRequired,
  enterprises: PropTypes.oneOfType([PropTypes.array]),
  journey: PropTypes.string.isRequired,
};

EnterpriseList.defaultProps = {
  enterprises: [],
};

export default EnterpriseList;
