import Button from '@mui/material/Button';
import FilterListIcon from '@mui/icons-material/FilterList';

function Filter() {
  return (
    <Button
      variant="contained"
      size="small"
      color="neutral"
      startIcon={<FilterListIcon />}
      sx={{ textTransform: 'none' }}
    >
      Todos os filtros
    </Button>
  );
}

export default Filter;
