import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import LanguageIcon from '@mui/icons-material/Language';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import Divider from '@mui/material/Divider';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import CircularProgress from '@mui/material/CircularProgress';

import Header from '@src/components/Header';
import Sidebar from '@src/components/Sidebar';
import SubHeader from '@src/components/SubHeader/sub-header-startup';
import DropDown from '@src/components/DropDown';
import RadialChart from '@src/components/RadialChart';
import FoundersCard from '@src/components/FoundersCard';
import authSelectors from '@src/store/modules/auth/selectors';
import { ROLES } from '@src/libs/models/roles';
import { RoutesMap } from '@src/routes/maps';
import { numberFormat } from '@src/utils/functions';
import StartupDatailsActions from './actions';

import {
  CardInformation,
  Container,
  ContainerItemBussinesData,
  ContainerItemFounders,
  ContainerItemInformation,
  ContentItemInformation,
  ItemDivider,
  ItemDividerInvestments,
  ItemHeaderTitle,
  ItemLeft,
  ItemRight,
} from './style';

const customer_retention = {
  'average_retention': 'Retenção média',
  'low': 'Retenção baixa',
  'high': 'Retenção alta',
  'not_tested': 'Retenção não testada',
}
const partners_relationship_options = {
  'no_partners': 'Sem parceiros',
  'we_identify': 'Nós identificamos e contatamos',
  'informal_partners': 'Temos parcerias informais',
  'assigned_contracts': 'Temos contrato assinado',
};

function Startup() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { state } = useLocation();
  const roleActive = useSelector(authSelectors.getRoleActive);

  useEffect(() => {
    dispatch({
      type: StartupDatailsActions.setStartupId,
      startupId: id,
    });

    dispatch({
      type: StartupDatailsActions.asyncfindStartupDetails,
      startupId: id,
    });
  }, [dispatch, id]);

  const {
    startupDetails,
    startupPartners,
    startupShareholders,
    startupDres,
    startupBalances,
  } = useSelector((stateSelector) => stateSelector.startupDetails);

  const textOne = () => {
    if (roleActive === ROLES[3]) {
      return 'Aprovação de Startups';
    }

    if (roleActive === ROLES[2] && state.enterprise) {
      return state.enterprise.fantasy_name;
    }

    return '';
  };

  const textTwo = () => {
    if (roleActive === ROLES[3] && state.enterprise) {
      return state.enterprise.fantasy_name;
    }

    return '';
  };

  const linktOne = () => {
    if (roleActive === ROLES[3]) {
      return RoutesMap.CONSULTOR_STARTUP_APPROVAL;
    }

    return '';
  };

  const handleDownloadFIle = (file) => {
      const url = `${startupDetails.url_docs}${file}`
      window.open(url, '_blank')
  }

  return startupDetails.length !== 0 ? (
    <>
      <Header />
      <Sidebar
        isInvestor={ROLES[2] === roleActive}
        isConsultant={ROLES[3] === roleActive}
        role={roleActive}
      />

      <SubHeader
        enterprise={state.enterprise}
        type={state.type}
        role={roleActive}
        showBreadcrumbs
        crumbText1={textOne()}
        crumbRoute1={linktOne()}
        crumbText2={textTwo()}
      />

      <Container container spacing={2}>
        <ItemLeft item xs={4}>
          <Box
            style={{
              marginLeft: '-2.3vh',
              marginTop: '-2.3vh',
            }}
          >
            <CardInformation>
              <ItemHeaderTitle>Informações Gerais</ItemHeaderTitle>
              <ItemDivider />
              <ContainerItemInformation>
                <ContentItemInformation color="#2E2E38" textDecoration>
                  <LanguageIcon sx={{ marginRight: 2 }} />
                  <button
                    type="button"
                    style={{
                      marginTop: -3,
                      cursor: 'pointer',
                      border: 0,
                      background: 'none',
                      fontSize: '14px',
                      color: '#2E2E38',
                      fontWeight: 300,
                    }}
                    onClick={() => {
                      const url = startupDetails.website
                        .replace('http://', '')
                        .replace('https://', '');
                      window.open(`http://${url}`, '_blank');
                    }}
                  >
                    {startupDetails.website}
                  </button>
                </ContentItemInformation>
                <ContentItemInformation color="#2E2E38">
                  <LocationOnOutlinedIcon sx={{ marginRight: 2 }} />
                  <p style={{ marginTop: -3 }}>{startupDetails.address}</p>
                </ContentItemInformation>
                <ContentItemInformation color="#2E2E38">
                  <GroupsOutlinedIcon sx={{ marginRight: 2 }} />
                  <p
                    style={{ marginTop: -3 }}
                  >{`${startupDetails.number_employees} funcionários`}</p>
                </ContentItemInformation>
                <ContentItemInformation color="##888888">
                  <CorporateFareOutlinedIcon sx={{ marginRight: 2 }} />
                  <p style={{ marginTop: -3 }}>
                    {startupDetails.business_model}
                  </p>
                </ContentItemInformation>
              </ContainerItemInformation>
            </CardInformation>
            <CardInformation>
              <ItemHeaderTitle>Dados da Empresa</ItemHeaderTitle>
              <ItemDivider />
              <ContainerItemBussinesData>
                <Box>
                  <Typography
                    style={{ fontWeight: 400, color: '##888888', fontSize: 14 }}
                  >
                    <b>Razão Social: </b>
                    {startupDetails.reason_social}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    style={{ fontWeight: 400, color: '##888888', fontSize: 14 }}
                  >
                    <b>Nome Fantasia: </b>
                    {startupDetails.fantasy_name}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    style={{ fontWeight: 400, color: '##888888', fontSize: 14 }}
                  >
                    <b>CNPJ: </b>
                    {startupDetails.cnpj}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    style={{ fontWeight: 400, color: '##888888', fontSize: 14 }}
                  >
                    <b>Setor: </b>
                    {startupDetails.sector}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    style={{ fontWeight: 400, color: '##888888', fontSize: 14 }}
                  >
                    <b>Subsetor: </b>
                    {startupDetails.sub_sector}
                  </Typography>
                </Box>
              </ContainerItemBussinesData>
            </CardInformation>
            <CardInformation>
              <ItemHeaderTitle>Sobre</ItemHeaderTitle>
              <ItemDivider />
              <ContainerItemBussinesData>
                <Typography
                  sx={{
                    fontWeight: 300,
                    color: '#515162;',
                    fontSize: 14,
                    paddingRight: 2,
                  }}
                >
                  {startupDetails.overview}
                </Typography>
              </ContainerItemBussinesData>
            </CardInformation>
            <CardInformation>
              <ItemHeaderTitle>Missão</ItemHeaderTitle>
              <ItemDivider />
              <ContainerItemBussinesData>
                <Typography
                  sx={{
                    fontWeight: 300,
                    color: '#515162;',
                    fontSize: 14,
                    paddingRight: 2,
                  }}
                >
                  {startupDetails.company_mission}
                </Typography>
              </ContainerItemBussinesData>
            </CardInformation>
            <CardInformation>
              <ItemHeaderTitle>Visão</ItemHeaderTitle>
              <ItemDivider />
              <ContainerItemBussinesData>
                <Typography
                  sx={{
                    fontWeight: 300,
                    color: '#515162;',
                    fontSize: 14,
                    paddingRight: 2,
                  }}
                >
                  {startupDetails.company_vision}
                </Typography>
              </ContainerItemBussinesData>
            </CardInformation>
            <CardInformation>
              <ItemHeaderTitle>Founders</ItemHeaderTitle>
              <ItemDivider />
              <ContainerItemFounders>
                <FoundersCard dataFounders={startupPartners} />
              </ContainerItemFounders>
            </CardInformation>
            <CardInformation>
              <ItemHeaderTitle>Shareholders</ItemHeaderTitle>
              <ItemDivider />
              <ContainerItemBussinesData>
                {startupShareholders.map((shareholder) => (
                  <Box
                    key={shareholder.id}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      flexDirection: 'row',
                      marginBottom: 5,
                      width: '100%',
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: 14,
                        width: 'auto',
                        color: '#1A1A24',
                      }}
                    >
                      {shareholder.desc_shareholder_id}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 800,
                        color: '#1A1A24',
                        fontSize: 14,
                      }}
                    >
                      {`${shareholder.percentage}%`}
                    </Typography>
                  </Box>
                ))}
              </ContainerItemBussinesData>
            </CardInformation>
          </Box>
        </ItemLeft>
        <ItemRight item xs={7.6}>
          <Box>
            <Typography
              sx={{
                fontWeight: '400',
                color: '#1A1A24',
                fontSize: '20px',
              }}
            >
              Investimentos
            </Typography>
            <ItemDividerInvestments />
          </Box>
          <Grid container spacing={2} style={{ marginTop: '0.5%' }}>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                flexDirection: 'row',
              }}
            >
              <Box>
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexDirection: 'row',
                    position: 'relative',
                  }}
                >
                  <Box
                    sx={{
                      position: 'relative',
                      left: '-80px',
                    }}
                  >
                    <RadialChart
                      roundValue={startupDetails.round_value}
                      capturedPercent={startupDetails.captured_percent}
                    />
                  </Box>
                  <Box style={{ marginLeft: '-10vh' }}>
                    <Typography
                      sx={{
                        fontWeight: 700,
                        color: '#000000',
                        fontSize: 20,
                      }}
                    >
                      {`R$${numberFormat(startupDetails.round_value) || 0}`}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        color: '#888888',
                        fontSize: 14,
                        marginTop: -1,
                        marginBottom: 1,
                      }}
                    >
                      valor da rodada
                    </Typography>
                    <Divider
                      sx={{
                        width: '107px',
                        border: '1px solid #D6D6D6',
                      }}
                    />
                    <Typography
                      sx={{
                        fontWeight: 700,
                        color: '#000000',
                        fontSize: 20,
                        marginTop: 2,
                      }}
                    >
                       {`${startupDetails.sales_percentage || 0}%`}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        color: '#888888',
                        fontSize: 14,
                        marginTop: -1,
                        marginBottom: 1,
                      }}
                    >
                      de venda 
                    </Typography>
                    <Divider
                      sx={{
                        width: '107px',
                        border: '1px solid #D6D6D6',
                      }}
                    />
                    <Typography
                      sx={{
                        fontWeight: 700,
                        color: '#000000',
                        fontSize: 20,
                        marginTop: 2,
                      }}
                    >
                      {startupDetails.quantities_of_quotas || 0}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        color: '#888888',
                        fontSize: 14,
                        marginTop: -1,
                        marginBottom: 1,
                      }}
                    >
                      cotas
                    </Typography>
                    <Divider
                      sx={{
                        width: '107px',
                        border: '1px solid #D6D6D6',
                      }}
                    />
                    <Typography
                      sx={{
                        fontWeight: 700,
                        color: '#000000',
                        fontSize: 18,
                        marginTop: 2,
                      }}
                    >
                      {`R$ ${startupDetails.quota_value || '0,00'}`}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        color: '#888888',
                        fontSize: 14,
                        marginTop: -1,
                        marginBottom: 1,
                      }}
                    >
                      por cota
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      fontWeight: 500,
                      color: '#1A1A24',
                      fontSize: 16,
                    }}
                  >
                    Mercado e Concorrência
                  </Typography>

                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-evenly',
                      flexDirection: 'row',
                      marginTop: 20,
                    }}
                  >
                    <Box
                      style={{
                        width: 110,
                        height: 95,
                        background: '#FFFFFF',
                        border: '1px solid #D6D6D6',
                        borderRadius: '9px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <Typography style={{ textAlign: 'center' }}>
                        {startupDetails.number_customers || 0}
                      </Typography>
                      <Typography style={{ textAlign: 'center' }}>
                        clientes
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        width: 110,
                        height: 95,
                        background: '#FFFFFF',
                        border: '1px solid #D6D6D6',
                        borderRadius: '9px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <Typography style={{ textAlign: 'center' }}>
                        {customer_retention[startupDetails.customer_retention]}
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        width: 110,
                        height: 95,
                        background: '#FFFFFF',
                        border: '1px solid #D6D6D6',
                        borderRadius: '9px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <Typography style={{ textAlign: 'center' }}>
                        {`${startupDetails.growth_expectation || 0}%`}
                      </Typography>
                      <Typography style={{ textAlign: 'center' }}>
                        crescimento de mercado
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      fontWeight: 500,
                      color: '#515162',
                      fontSize: 16,
                      marginTop: 3,
                      marginBottom: 1,
                    }}
                  >
                    Mercado Endereçável:
                  </Typography>
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'space-evenly',
                      alignContent: 'center',
                      flexDirection: 'row',
                    }}
                  >
                    <Typography
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center',
                        fontWeight: '400',
                        color: '#888888',
                        fontSize: '14px',
                        border: '1px solid #C4C4C4',
                        borderRadius: '100px',
                        padding: '0 20px 0 20px',
                      }}
                    >
                      {startupDetails.addressable_market}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      fontWeight: 500,
                      color: '#515162',
                      fontSize: 16,
                      marginTop: 3,
                      marginBottom: 1,
                    }}
                  >
                    Nível de competição:
                  </Typography>
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'space-evenly',
                      alignContent: 'center',
                      flexDirection: 'row',
                    }}
                  >
                    <Typography
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center',
                        fontWeight: '400',
                        color: '#888888',
                        fontSize: '14px',
                        border: '1px solid #C4C4C4',
                        borderRadius: '100px',
                        padding: '0 20px 0 20px',
                      }}
                    >
                      {startupDetails.competition_level}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      fontWeight: 500,
                      color: '#515162',
                      fontSize: 16,
                      marginTop: 3,
                      marginBottom: 1,
                    }}
                  >
                    Principais concorrentes:
                  </Typography>
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'space-evenly',
                      alignContent: 'center',
                      flexDirection: 'row',
                    }}
                  >
                    <Typography
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center',
                        fontWeight: '400',
                        color: '#888888',
                        fontSize: '14px',
                        border: '1px solid #C4C4C4',
                        borderRadius: '100px',
                        padding: '0 20px 0 20px',
                      }}
                    >
                      {startupDetails.mainly_competitors}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      fontWeight: 500,
                      color: '#515162',
                      fontSize: 16,
                      marginTop: 3,
                      marginBottom: 1,
                    }}
                  >
                    Qualidade da concorrência:
                  </Typography>
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'space-evenly',
                      alignContent: 'center',
                      flexDirection: 'row',
                    }}
                  >
                    <Typography
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center',
                        fontWeight: '400',
                        color: '#888888',
                        fontSize: '14px',
                        border: '1px solid #C4C4C4',
                        borderRadius: '100px',
                        padding: '0 20px 0 20px',
                      }}
                    >
                      {startupDetails.quality_competition}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      fontWeight: 500,
                      color: '#515162',
                      fontSize: 16,
                      marginTop: 3,
                      marginBottom: 1,
                    }}
                  >
                    Geografia de Atuação:
                  </Typography>
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'space-evenly',
                      alignContent: 'center',
                      flexDirection: 'row',
                    }}
                  >
                    <Typography
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center',
                        fontWeight: '400',
                        color: '#888888',
                        fontSize: '14px',
                        border: '1px solid #C4C4C4',
                        borderRadius: '100px',
                        padding: '0 20px 0 20px',
                      }}
                    >
                      {startupDetails.geography_actuation}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      fontWeight: 500,
                      color: '#515162',
                      fontSize: 16,
                      marginTop: 3,
                      marginBottom: 1,
                    }}
                  >
                    Relacionamento com parceiros estratégicos:
                  </Typography>
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'space-evenly',
                      alignContent: 'center',
                      flexDirection: 'row',
                    }}
                  >
                    <Typography
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center',
                        fontWeight: '400',
                        color: '#888888',
                        fontSize: '14px',
                        border: '1px solid #C4C4C4',
                        borderRadius: '100px',
                        padding: '0 20px 0 20px',
                      }}
                    >
                      {partners_relationship_options[startupDetails.partners_relationship]}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box style={{ marginLeft: 40, paddingRight: 8 }}>
                <Typography
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    fontWeight: 400,
                    color: '#1A1A24',
                    fontSize: 16,
                  }}
                >
                  Diferenciais do investimento
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    color: '#515162',
                    fontSize: 14,
                    width: 'auto',
                    marginTop: 2,
                  }}
                >
                  {startupDetails.investment_differentials}
                </Typography>
                <Typography
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    fontWeight: 400,
                    color: '#1A1A24',
                    fontSize: 16,
                    marginTop: 6,
                  }}
                >
                  Tese de Investimento
                </Typography>
                <Typography
                  sx={{
                    fontWeight: '400',
                    color: '#515162',
                    fontSize: 14,
                    width: 'auto',
                    marginTop: 2,
                  }}
                >
                  {startupDetails.investment_thesis}
                </Typography>
                <Typography
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    fontWeight: 400,
                    color: '#1A1A24',
                    fontSize: 16,
                    marginTop: 6,
                  }}
                >
                  Uso de Recursos
                </Typography>
                <Typography
                  sx={{
                    fontWeight: '400',
                    color: '#515162',
                    fontSize: 14,
                    width: 'auto',
                    marginTop: 2,
                  }}
                >
                  {startupDetails.use_of_resources}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Divider
            sx={{
              width: '98%',
              background: 'rgba(26, 26, 36, 0.14)',
              marginTop: 4,
              marginBottom: 2,
            }}
          />
          <DropDown dataDropDow={{ startupDres, startupBalances }} />
          <Divider
            sx={{
              width: '98%',
              background: 'rgba(26, 26, 36, 0.14)',
              marginTop: 4,
              marginBottom: 2,
            }}
          />
          <Typography
            sx={{
              fontSize: 16,
              color: '#1A1A24',
              marginBottom: 3,
            }}
          >
            Documentos
          </Typography>
          <Box style={{ marginBottom: 20 }}>
            {startupDetails.documents.map((document, index) => (
              <Button
                key={document}
                sx={{
                  height: 33,
                  borderRadius: 2,
                  background: '#F1F1F3',
                  marginRight: 3,
                }}
                onClick={() => handleDownloadFIle(document)}
              >
                <CloudDownloadOutlinedIcon
                  sx={{
                    color: '#000000',
                    marginRight: 1,
                  }}
                />
                <Typography
                  sx={{
                    fontWeight: 400,
                    color: '#515162',
                    fontSize: 14,
                  }}
                >
                  {index + 1} - BALANÇO/DRE
                </Typography>
              </Button>
            ))}
            {startupDetails.employees_resume_file.map((document, index) => (
              <Button
                key={document}
                sx={{
                  height: 33,
                  borderRadius: 2,
                  background: '#F1F1F3',
                }}
                onClick={() => handleDownloadFIle(document)}
              >
                <CloudDownloadOutlinedIcon
                  sx={{
                    color: '#000000',
                    marginRight: 1,
                  }}
                />
                <Typography
                  sx={{
                    fontWeight: 400,
                    color: '#515162',
                    fontSize: 14,
                  }}
                >
                  {index + 1} - Currículo dos Founders
                </Typography>
              </Button>
            ))}

            
          </Box>
        </ItemRight>
      </Container>
    </>
  ) : (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        marginTop: '22%',
      }}
    >
      <CircularProgress />
    </Box>
  );
}

export default Startup;
