import { Routes, Route } from 'react-router-dom';
import routeWrapper from './Route';
import routes from './routes';

export function AppRoutes() {
  return (
    <Routes>
      {routes.map((route) => (
        <Route key={route.path} {...routeWrapper(route)} />
      ))}
    </Routes>
  );
}
