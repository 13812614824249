/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

import Typography from '@mui/material/Typography';

import Header from '../../../../components/Header';
import Sidebar from '../../../../components/Sidebar';
import ProgressBar from '../../../../components/ProgressBar/CompleteRegistrationProgress';

import UploadContract from './UploadContract';

import { Container, SubHeaderBreadchrumbs, TitlePage } from './styles';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function CompleteRegistration() {
  return (
    <>
      <Header />

      <Sidebar isStartup />

      <Box
        sx={{
          width: 'calc(100% - 60px)',
          position: 'relative',
          top: '60px',
          left: '60px',
        }}
      >
        <SubHeaderBreadchrumbs>
          <Breadcrumbs
            aria-label="breadcrumb"
            sx={{ color: '#9999AB', fontSize: 12, mb: 1 }}
          >
            <Link underline="hover" color="inherit" href="/">
              Home
            </Link>
            <Link
              underline="hover"
              color="inherit"
              href="/complete-registration"
              aria-current="page"
            >
              Cadastro Completo
            </Link>

            <Link
              underline="hover"
              color="inherit"
              href="#"
              aria-current="page"
            >
              Contrato
            </Link>
          </Breadcrumbs>

          <TitlePage>Contrato</TitlePage>

          <ProgressBar />
        </SubHeaderBreadchrumbs>
        <Container>
          <UploadContract />
        </Container>
      </Box>
    </>
  );
}

export default CompleteRegistration;
