/* eslint-disable react/no-array-index-key */
import React, { useRef, useCallback } from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import { uploadIcon } from '@src/constants/imgs';
import Logo from '../Logo';
import { Container, DropzoneContainer, Wrapper, FileContainer } from './styles';

const renderIcon = (type) => {
  switch (type) {
    case 'upload':
      return (
        <Logo size={51} src={uploadIcon} alt="upload-icon" loading="lazy" />
      );
    default:
      return <BackupOutlinedIcon fontSize="large" sx={{ fill: '#98989C' }} />;
  }
};

function DropFile({
  callback,
  files,
  accept,
  onDelete,
  multiple,
  title,
  minHeight,
  minWidth,
  backgroundColor,
  subtitle,
  icon,
  styleTitle,
  styleSubtitle,
  borderColor,
  styleContainer,
}) {
  const dropzoneRef = useRef();

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        const blob = new Blob([file], { type: file.type });
        const localUrl = window.URL.createObjectURL(blob);
        reader.onload = () => {
          callback(file, localUrl);
        };
        reader.readAsText(blob);
      });
    },
    [callback]
  );

  const renderMessage = (isDragReject) => {
    if (isDragReject) {
      return (
        <Typography component="p" color="secondary">
          Arquivo não suportado.
        </Typography>
      );
    }

    return null;
  };

  return (
    <Container sx={styleContainer}>
      <Wrapper
        minHeight={minHeight}
        minWidth={minWidth}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
      >
        <Dropzone
          ref={dropzoneRef}
          accept={accept}
          onDropAccepted={onDrop}
          multiple={multiple}
        >
          {({ getRootProps, getInputProps, isDragReject }) => (
            <DropzoneContainer
              {...getRootProps()}
              minHeight={minHeight}
              minWidth={minWidth}
            >
              <Box
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                sx={{ width: 'auto' }}
              >
                <Grid item>{renderIcon(icon)}</Grid>
                <Grid item mt={1.5}>
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: 400,
                      fontSize: '16px',
                      color: '#767676',
                      ...styleTitle,
                    }}
                  >
                    {title}
                  </Typography>
                </Grid>
                {subtitle !== '' ? (
                  <Grid item mt={1}>
                    <Typography
                      variant="h6"
                      color="secondary"
                      sx={{
                        fontWeight: 400,
                        fontSize: '12px',
                        color: '#767676',
                        ...styleSubtitle,
                      }}
                    >
                      {subtitle}
                    </Typography>
                  </Grid>
                ) : null}
              </Box>

              <input {...getInputProps()} />
              {renderMessage(isDragReject)}
            </DropzoneContainer>
          )}
        </Dropzone>
      </Wrapper>
      {!multiple ? (
        <Box>
          {files && files.name ? (
            <FileContainer>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <Typography
                    component="span"
                    sx={{ fontWeight: 300, fontSize: '1rem' }}
                  >
                    {files.name}
                  </Typography>
                </Grid>
                <Grid item>
                  <HighlightOffIcon
                    sx={{
                      color: '#F85959',
                      marginTop: '5px',
                      cursor: 'pointer',
                    }}
                    onClick={() => onDelete()}
                  />
                </Grid>
              </Grid>
            </FileContainer>
          ) : null}
        </Box>
      ) : (
        <Box>
          {files.length ? (
            <Box>
              {files.map((item, index) => (
                <FileContainer key={index}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item>
                      <Typography
                        component="span"
                        sx={{ fontWeight: 300, fontSize: '1rem' }}
                      >
                        {item.name}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <HighlightOffIcon
                        sx={{
                          color: '#F85959',
                          marginTop: '5px',
                          cursor: 'pointer',
                        }}
                        onClick={() => onDelete(index)}
                      />
                    </Grid>
                  </Grid>
                </FileContainer>
              ))}
            </Box>
          ) : null}
        </Box>
      )}
    </Container>
  );
}

DropFile.propTypes = {
  callback: PropTypes.func,
  files: PropTypes.oneOfType([
    (PropTypes.shape({}), PropTypes.arrayOf(PropTypes.shape({}))),
  ]).isRequired,
  accept: PropTypes.shape({}),
  onDelete: PropTypes.func,
  multiple: PropTypes.bool,
  title: PropTypes.string,
  minHeight: PropTypes.string,
  minWidth: PropTypes.string,
  backgroundColor: PropTypes.string,
  subtitle: PropTypes.string,
  icon: PropTypes.string,
  styleTitle: PropTypes.shape({}),
  styleSubtitle: PropTypes.shape({}),
  borderColor: PropTypes.string,
  styleContainer: PropTypes.shape({}),
};

DropFile.defaultProps = {
  callback: () => {},
  accept: { 'image/*': ['.png', '.jpeg'] },
  onDelete: () => {},
  multiple: false,
  title: '',
  minHeight: '100px',
  minWidth: '180px',
  backgroundColor: '#F1F1F3',
  subtitle: '',
  icon: '',
  styleTitle: {},
  styleSubtitle: {},
  borderColor: 'rgba(46, 46, 56, 0.4)',
  styleContainer: {},
};

export default React.memo(DropFile);
