/* eslint-disable react/prop-types */
import { generatePath, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import FormatMoneyBRL from '@src/helpers/FormatMoneyBRL';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

import Label from '@src/components/common/Label';
import ThumbCompany from '@src/components/common/ThumbCompany';
import ProgressBar from '@src/components/common/ProgressBar';
import { RoutesMap } from '@src/routes/maps';

import InvestitorHomeActions from '@src/pages/investor/Home/actions';

function CardStartup({ dataStartup }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);

  const handleStartupDetails = (startup) => {
    dispatch({
      type: InvestitorHomeActions.asyncSaveHistoryStartupLastSeen,
      investorId: user.investor_id,
      startupId: startup.id,
    });

    const path = generatePath(RoutesMap.INVESTOR_STARTUP, {
      id: startup.id,
    });
    navigate(path, { state: { enterprise: startup } });
  };

  const hasSector = dataStartup.sector;

  return (
    <Paper sx={{ p: 3 }}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '74px auto',
          gap: '0 12px',
          gridTemplateAreas: `
        "thumb title"
        "thumb label"`,
          justifyItems: 'flex-start',
          alignItems: 'flex-start',
        }}
      >
        <ThumbCompany src={dataStartup.logo} />

        <Typography
          sx={{
            fontSize: '1.250rem',
            lineHeight: '1.2em',
            color: 'text.secondary',
            gridArea: 'title',
          }}
        >
          {dataStartup.fantasy_name}
        </Typography>

        {hasSector && <Label text={dataStartup.sector} />}
      </Box>

      <Box sx={{ my: 4 }}>
        <ProgressBar
          initialValue={dataStartup.capited_value}
          maxValue={dataStartup.evaluation}
        />

        <Typography
          sx={{
            fontSize: '.750rem',
            color: '#888',
            mt: 1,
          }}
        >
          Meta: R$
          <b> {FormatMoneyBRL(dataStartup.evaluation)}</b>
        </Typography>

        <Typography
          sx={{
            fontSize: '.750rem',
            color: '#888',
          }}
        >
          Valor Captado: R$
          <b> {FormatMoneyBRL(dataStartup.capited_value)}</b>
        </Typography>
      </Box>

      <Button
        variant="outlined"
        size="small"
        color="secondary"
        onClick={() => handleStartupDetails(dataStartup)}
        sx={{
          display: 'block',
          mt: 2,
          mx: 'auto',
        }}
      >
        SAIBA MAIS
      </Button>
    </Paper>
  );
}

export default CardStartup;
