/* eslint-disable react/prop-types */
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const TitleSection = styled(Typography)`
  color: ${(props) => (props.Section === 'NossoPapel' ? '#fff' : '#542EA6')};
  font-size: 2.5rem;
  margin-bottom: 60px;
  &:after {
    content: '';
    display: block;
    width: 97px;
    height: 5px;
    background-color: currentColor;
    margin: ${(props) =>
      props.Section === 'NossoPapel' ? '20px 0 0 0' : '20px auto 0px'};
    @media (max-width: 600px) {
      margin-top: 12px;
      transform: scale(0.8);
    }
  }
  @media (max-width: 600px) {
    font-size: 2rem;
    margin-bottom: 40px;
  }
`;

const SubtitleSection = styled(Typography)`
  display: block;
  color: currentColor;
  font-size: 1.75rem;
  text-align: center;
  position: relative;
  bottom: -8px;
  @media (max-width: 600px) {
    font-size: 1.4rem;
    bottom: -3px;
  }
`;

function Title({ Section, TitleText, SubtitleText }) {
  return (
    <TitleSection Section={Section} variant="h2">
      {TitleText}
      <SubtitleSection>{SubtitleText}</SubtitleSection>
    </TitleSection>
  );
}

export default Title;
