/* eslint-disable react/prop-types */

import { styled } from '@mui/material/styles';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 4,
  borderRadius: 4,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#F1F1F3',
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: theme.palette.info.main,
  },
}));

export default function ProgressBar({ initialValue = 0, maxValue = 100 }) {
  const percentage = (initialValue / maxValue) * 100;

  return <BorderLinearProgress variant="determinate" value={percentage} />;
}
