export const investorApprovalRequest = '@consultor/INVESTOR_APPROVAL_REQUEST';
export const investorApprovalSuccess = '@consultor/INVESTOR_APPROVAL_SUCCESS';
export const investorApprovalFailure = '@consultor/INVESTOR_APPROVAL_FAILURE';
export const investorApprovalReset = '@consultor/INVESTOR_APPROVAL_RESET';

const consultorStartupApprovalTypes = {
  investorApprovalRequest,
  investorApprovalSuccess,
  investorApprovalFailure,
  investorApprovalReset,
};

export default consultorStartupApprovalTypes;
