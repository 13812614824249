/* eslint-disable react/prop-types */

import MenuItem from '@mui/material/MenuItem';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';

export default function GroupedSelect({ value, quotas, handleChange }) {
  let count = 1;
  const quotasMenuItem = [];

  while (count <= quotas) {
    quotasMenuItem.push(count);
    count += 1;
  }

  return (
    <div>
      <Box>
        <FormControl sx={{ minWidth: 120 }}>
          <Select
            value={value}
            onChange={handleChange}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            sx={{
              width: '485px',
              height: '54px',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <MenuItem value="">Selecione o número de cotas...</MenuItem>
            {quotasMenuItem.map((quota) => (
              <MenuItem key={quota} value={quota}>
                {quota}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </div>
  );
}
