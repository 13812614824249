import PropTypes from 'prop-types';
import { Grid, Typography, Box } from '@mui/material';
import EyeIcon from '../EyeIcon';
import CardContent from '../CardContent';

function CardViews({ values, locked }) {
  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      {locked ? <EyeIcon /> : null}
      <CardContent locked={locked} padding="24px 12px">
        <Box mb={2}>
          <Typography
            sx={{
              color: '#1A1A24',
              fontSize: '14px',
              fontWeight: 800,
              paddingLeft: '17px',
            }}
          >
            Visualizações
          </Typography>
        </Box>
        <Grid container>
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            justifyContent="center"
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <Typography
              sx={{
                fontSize: '52px',
                fontWeight: 700,
                color: '#2E2E38',
              }}
            >
              {values.total}
            </Typography>
            <Typography
              sx={{
                fontSize: '1.125rem',
                color: '#2E2E38',
                opacity: '50%',
                textAlign: 'center',
              }}
            >
              Visualizações
            </Typography>
            <Typography
              sx={{
                fontSize: '1.125rem',
                color: '#2E2E38',
                opacity: '50%',
                textAlign: 'center',
              }}
            >
              no total
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <Typography
              sx={{
                fontSize: '52px',
                fontWeight: 700,
                color: '#2E2E38',
              }}
            >
              {values.investor}
            </Typography>
            <Typography
              sx={{
                fontSize: '1.125rem',
                color: '#2E2E38',
                opacity: '50%',
                textAlign: 'center',
              }}
            >
              Visualizações
            </Typography>
            <Typography
              sx={{
                fontSize: '1.125rem',
                color: '#2E2E38',
                opacity: '50%',
                textAlign: 'center',
              }}
            >
              por investidor
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Box>
  );
}

CardViews.propTypes = {
  locked: PropTypes.bool,
  values: PropTypes.shape({
    total: PropTypes.number,
    investor: PropTypes.number,
  }),
};

CardViews.defaultProps = {
  locked: false,
  values: {
    total: 452,
    investor: 176,
  },
};

export default CardViews;
