/* eslint-disable react/prop-types */

import { generatePath, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ButtonBase from '@mui/material/ButtonBase';
import { styled } from '@mui/material/styles';

import Label from '@src/components/common/Label';

import { RoutesMap } from '@src/routes/maps';

import InvestitorHomeActions from '@src/pages/investor/Home/actions';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  position: 'absolute',
  right: '90px',
  bottom: '25px',
  maxWidth: '100%',
  maxHeight: '100%',
});

function LastSeen({ dataLastSeen }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);

  const handleStartupDetails = (startup) => {
    dispatch({
      type: InvestitorHomeActions.asyncSaveHistoryStartupLastSeen,
      investorId: user.id,
      startupId: startup.id,
    });

    const path = generatePath(RoutesMap.INVESTOR_STARTUP, {
      id: startup.id,
    });
    navigate(path, { state: { enterprise: startup } });
  };

  const hasSector = dataLastSeen.sector;

  return (
    <Box>
      <Typography
        sx={{
          fontSize: '1.250rem',
          color: 'text.secondary',
          mb: 3,
        }}
      >
        Visto por último
      </Typography>

      <Box>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item>
            <Grid container spacing={2}>
              {dataLastSeen.map((startup) => (
                <Grid item key={startup.id}>
                  <ButtonBase onClick={() => handleStartupDetails(startup)}>
                    <Item
                      sx={{
                        width: '310px',
                        height: '119px',
                        background: '#FFFFFF',
                        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12)',
                        borderRadius: '9px',
                      }}
                    >
                      <Typography
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          fontWeight: '400',
                          color: '#1A1A24',
                          fontSize: '20px',
                          marginLeft: 6,
                        }}
                      >
                        {startup.fantasy_name}
                      </Typography>
                      <ButtonBase sx={{ width: 72, height: 72 }}>
                        <Img alt="venture" src={startup.logo} />
                      </ButtonBase>
                      {hasSector && <Label text={startup.sector} />}
                    </Item>
                  </ButtonBase>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default LastSeen;
