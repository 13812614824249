/* eslint-disable react/require-default-props */
import { useState, useEffect, useCallback } from 'react';
import toast from 'react-hot-toast';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContentMaterial from '@mui/material/CardContent';
import { format, subDays } from 'date-fns/esm';

import ApiClient from '@src/services/api/api';
import ProposalStatusEnum from '@src/constants/enums/proposal-status.enum';
import { useDispatch, useSelector } from 'react-redux';
import { setStartup, setStep } from '@src/store/modules/startup/actions';
import SubHeader from '../../../components/SubHeader/sub-header-home-startup';
import Header from '../../../components/Header';
import Sidebar from '../../../components/Sidebar';
import DynamicCard from '../../../components/DynamicCard';

import ListProposals from '../../../components/ListProposals';
import CardTotal from './components/CardTotal';
import CardViews from './components/CardViews';
import CardProposals from './components/CardProposals';
import EyeIcon from './components/EyeIcon';
import CardContent from './components/CardContent';

function Home() {
  const { startup_id } = useSelector((store) => store.auth.user);
  const dispatch = useDispatch();
  const [capitedValue, setCapitedValue] = useState({ total: 0, captured: 0 });
  const [viewsTotal, setViewsTotal] = useState({ total: 0, investor: 0 });
  const [proposalsQuantity, setProposalsQuantity] = useState({
    pending: 0,
    accepted: 0,
    completed: 0,
    refused: 0,
  });
  const [lastViews, setLastViews] = useState([]);
  const [isLocked, setLocked] = useState(false);
  const [initialDate, setInitialDate] = useState(
    format(subDays(new Date(), 30), 'dd-MM-yyyy')
  );
  const [finalDate, setFinalDate] = useState(format(new Date(), 'dd-MM-yyyy'));

  const [registrationStep, setRegistrationStep] = useState(0);

  const getStartupById = useCallback(async () => {
    ApiClient.fetchStartup(startup_id)
      .then((response) => {
        if (response.data.startups.startup_status_id === 5) {
          setLocked(false);
        } else {
          setLocked(true);
        }
        dispatch(setStartup(response.data.startups));
        dispatch(setStep(response.data.startups.registration_step_id))
        setRegistrationStep(response.data.startups.startup_status_id);
      })
      .catch((error) => {
        console.log(`Falha: ${error.response.data.message}`);
      });
  }, [startup_id, dispatch]);

  const fetchCapitedValues = useCallback(async () => {
    ApiClient.fetchStartupCapitedProposalValues({
      startup_id,
      initial_date: initialDate,
      final_date: finalDate,
    })
      .then((response) => {
        setCapitedValue({
          captured: response.data.capitated_value,
          total: response.data.total,
        });
      })
      .catch((error) => {
        console.log(`Falha: ${error.response.data.message}`);
      });
  }, [finalDate, initialDate, startup_id]);

  const fetchViewsTotal = useCallback(async () => {
    ApiClient.fetchStartupViewsTotal({
      startup_id,
      initial_date: initialDate,
      final_date: finalDate,
    })
      .then((response) => {
        setViewsTotal({
          investor: response.data.quantity_views_investors,
          total: response.data.quantity_views,
        });
      })
      .catch((error) => {
        console.log(`Falha: ${error.response.data.message}`);
      });
  }, [startup_id, initialDate, finalDate]);

  const fetchProposalsQuantity = useCallback(async () => {
    ApiClient.fetchProposalsQuantity({
      startup_id,
      initial_date: initialDate,
      final_date: finalDate,
    })
      .then((response) => {
        const accepted = response.data[0].find(
          (res) => res.proposal_status_id === ProposalStatusEnum.ACEITA
        );
        const pending = response.data[0].find(
          (res) => res.proposal_status_id === ProposalStatusEnum.RECEBIDA
        );
        const completed = response.data[0].find(
          (res) =>
            res.proposal_status_id ===
            ProposalStatusEnum.INVESTIMENTO_CONCLUIDO
        );
        const refused = response.data[0].find(
          (res) => res.proposal_status_id === ProposalStatusEnum.RECUSADA
        );
        setProposalsQuantity({
          accepted: accepted?.quantity || 0,
          pending: pending?.quantity || 0,
          completed: completed?.quantity || 0,
          refused: refused?.quantity || 0,
        });
      })
      .catch((error) => {
        console.log(`Falha: ${error.response.data.message}`);
      });
  }, [startup_id, initialDate, finalDate]);

  const fetchLastViews = useCallback(async () => {
    ApiClient.fetchLastViews({
      startup_id,
      initial_date: initialDate,
      final_date: finalDate,
    })
      .then((response) => {
        setLastViews(
          response.data.startupLastViews.map((res) => ({
            id: res.id,
            name: res.fantasy_name,
            logo: res.logo,
          }))
        );
      })
      .catch((error) => {
        console.log(`Falha: ${error.response.data.message}`);
      });
  }, [startup_id, initialDate, finalDate]);

  useEffect(() => {
    getStartupById();
  }, [getStartupById]);

  useEffect(() => {
    if(startup_id) {
      fetchCapitedValues();
      fetchViewsTotal();
      fetchProposalsQuantity();
      fetchLastViews();
    }
  }, [
    startup_id,
    fetchCapitedValues,
    fetchViewsTotal,
    fetchProposalsQuantity,
    fetchLastViews,
  ]);

  const handlePickerDateChange = (data) => {
    if (data[1]) {
      setInitialDate(format(data[0], 'dd-MM-yyyy'));
      setFinalDate(format(data[1], 'dd-MM-yyyy'));
    }
  };

  const [data, setData] = useState({
    received: [],
    accepted: [],
    finish: [],
    refused: [],
  });
  const [loadingList, setLoadingList] = useState(false);
  // const profile = useSelector((state) => state.user.profile);

  const fetchProposals = useCallback(async () => {
    setLoadingList(true);
    ApiClient.fetchProposals({
      startup_id,
    })
      .then((response) => {
        if (response.data.proposals) {
          const modelData = {
            received: response.data.proposals.filter(
              (res) => res.proposal_status_id === ProposalStatusEnum.RECEBIDA
            ),
            accepted: response.data.proposals.filter(
              (res) => res.proposal_status_id === ProposalStatusEnum.ACEITA
            ),
            finish: response.data.proposals.filter(
              (res) =>
                res.proposal_status_id ===
                ProposalStatusEnum.INVESTIMENTO_CONCLUIDO
            ),
            refused: response.data.proposals.filter(
              (res) => res.proposal_status_id === ProposalStatusEnum.RECUSADA
            ),
          };
          setData(modelData);
        }
        setLoadingList(false);
      })
      .catch((error) => {
        toast.error(`Falha: ${error.response.data.message}`);
        setLoadingList(false);
      });
  }, [startup_id]);

  useEffect(() => {
  if (startup_id) fetchProposals();
  }, [fetchProposals, startup_id]);

  return (
    <>
      <Header />
      <Sidebar isStartup />
      <Box sx={{ padding: '50px 0px 30px 50px' }}>
        <SubHeader onChange={handlePickerDateChange} />
      </Box>
      <Box sx={{ padding: '0px 30px 50px 80px' }}>
        <Typography
          sx={{
            color: '#1A1A24',
            fontSize: '20px',
            fontWeight: 'bold',
          }}
        >
          Informações Gerais
        </Typography>

        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={6} md={3} mt={2}>
            <DynamicCard type={registrationStep} />
          </Grid>
          <Grid item xs={12} sm={6} md={3} mt={2}>
            <CardTotal locked={isLocked} values={capitedValue} />
          </Grid>
          <Grid item xs={12} sm={6} md={3} mt={2}>
            <CardViews locked={isLocked} values={viewsTotal} />
          </Grid>
          <Grid item xs={12} sm={6} md={3} mt={2}>
            <CardProposals locked={isLocked} values={proposalsQuantity} />
          </Grid>
        </Grid>

        <Typography
          sx={{
            color: '#1A1A24',
            fontSize: '20px',
            fontWeight: 'bold',
            mt: 6,
          }}
        >
          Propostas
        </Typography>

        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={12} md={9} mt={2}>
            <Box sx={{ position: 'relative' }}>
              {isLocked ? <EyeIcon /> : null}
              <CardContent padding="0" height={408} locked={isLocked}>
                <ListProposals data={data} loading={loadingList} />
              </CardContent>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={3} mt={2}>
            <Box sx={{ position: 'relative' }}>
              {isLocked ? <EyeIcon /> : null}
              <CardContent height={408} locked={isLocked}>
                <Typography
                  sx={{
                    mb: 2,
                    color: '#1A1A24',
                    fontSize: '14px',
                  }}
                >
                  Últimos visitantes no perfil
                </Typography>
                <Box
                  pr="16px"
                  sx={{
                    overflow: 'hidden',
                    overflowY: 'auto',
                    height: '345px',
                    '&::-webkit-scrollbar': {
                      width: '5px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: '#9D9DAE',
                      borderRadius: '9px',
                    },
                  }}
                >
                  {lastViews.length ? (
                    <>
                      {lastViews.map((item) => (
                        <Card key={item.id} sx={{ marginBottom: 2 }}>
                          <CardContentMaterial
                            sx={{
                              padding: '8px',
                              display: 'flex',
                              paddingBottom: '8px !important',
                            }}
                          >
                            <img
                              src={item.logo}
                              alt="Logo"
                              aria-hidden="true"
                              style={{
                                width: '43px',
                                height: '43px',
                                borderRadius: '6px',
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: 14,
                                color: '#1A1A24',
                                ml: 3,
                              }}
                            >
                              {item.name}
                            </Typography>
                          </CardContentMaterial>
                        </Card>
                      ))}
                    </>
                  ) : null}
                </Box>
              </CardContent>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Home;
