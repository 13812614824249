export const ApprovalStartupsTypes = {
  1: 'COMPLETE_PRE_REGISTRATION',
  2: 'PENDING_REGISTRATION',
  3: 'COMPLETE_REGISTRATION',
  4: 'APPROVED',
  5: 'SIGNED_CONTRACT',
  6: 'REMOVED',
  7: 'PAGE_INVESTOR',
};
export const ApprovalStartupsInverseTypes = {
  COMPLETE_PRE_REGISTRATION: 1,
  PENDING_REGISTRATION: 2,
  COMPLETE_REGISTRATION: 3,
  APPROVED: 4,
  SIGNED_CONTRACT: 5,
  REMOVED: 6,
};

export const ApprovalInvestorTypes = {
  1: 'COMPLETE_PRE_REGISTRATION',
  2: 'APPROVED',
  3: 'REMOVED',
};

export const ApprovalInvestorsInverseTypes = {
  COMPLETE_PRE_REGISTRATION: 1,
  APPROVED: 2,
  REMOVED: 3,
};
export const JourneyTypes = {
  1: 'Startup',
  2: 'Investitor',
};
